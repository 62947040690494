import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
} from 'rxjs'
import {
	DbIdChangesImmutable,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	ValidationErrors,
} from '../../database/validation'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	OfflineCapable,
	OfflineEntity,
	StaticOffline,
	createOfflineCapable,
	getActiveState,
	offlineCapableSchema,
} from '../../Offline'
import {
	toJsonObject,
} from '../../utils/RxDbUtils'
import {
	deleteStudyingSessionSetGql,
	insertStudyingSessionSetGql,
	loadStudyingSessionSetById,
	loadStudyingSessionSets,
} from './studyingsessionsetsGql'

type StudyingSessionSetsChangeType = DbIdChangesImmutable<'studyingsessionsets'>

export type ChangeStudyingSessionSets = StudyingSessionSetsChangeType['All']

export interface StudyingSessionSet extends OfflineCapable {
	studyingsessionid: string
	setid: string
}

interface StaticMethods extends StaticOffline<StudyingSessionSet> {
	getById(id: string): Promise<StudyingSessionSet | null>
	getActive(): Observable<StudyingSessionSet[]>
}

export type StudyingSessionSetCollection = RxCollection<
	StudyingSessionSet,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: StudyingSessionSetCollection,
		id: string,
	): Promise<StudyingSessionSet | null> {
		const studyingSessionSet = await this.findOne(id).exec()
		return studyingSessionSet?.toJSON() ?? null
	},
	getActive(this: StudyingSessionSetCollection): Observable<StudyingSessionSet[]> {
		return getActiveState(this)
	},
	async handleOffline({ inserted, deleted }) {
		// TODO: probably we can skip insert and update for deleted entities?
		await Promise.allSettled(inserted.map(async studyingSessionSet => {
			try {
				const result = await insertStudyingSessionSetGql(toJsonObject(studyingSessionSet))
				await studyingSessionSet.atomicPatch({
					...result,
					offlinestate: 'insync',
				})
			} catch (e) {
				if (e instanceof ValidationErrors) {
					// happens, if studyingsession was removed
					// through another (online) client
					const studyingSetDoesntExists = !!e.errors.find(
						error => error.code === 'foreignKeyConstraint')
					if (studyingSetDoesntExists) {
						await studyingSessionSet.remove()
						return
					}
				}
				throw e
			}
		}))

		await Promise.allSettled(deleted.map(async studyingSessionSet => {
			await deleteStudyingSessionSetGql(studyingSessionSet.id)
			await studyingSessionSet.remove()
		}))
	},
}

const studyingSessionSetsSchema = offlineCapableSchema<RxJsonSchema<StudyingSessionSet>>({
	version: 0,
	type: 'object',
	required: [
		'studyingsessionid',
		'setid',
	],
	properties: {
		studyingsessionid: {
			type: 'string',
			maxLength: 36,
		},
		setid: {
			type: 'string',
		},
	},
	indexes: ['studyingsessionid'],
})

export const studyingSessionSetCollection: Record<'studyingsessionsets', RxCollectionCreator<StaticMethods>> = {
	studyingsessionsets: {
		schema: studyingSessionSetsSchema,
		statics,
	},
}

export async function pullStudyingSessionSets(): Promise<void> {
	const studyingSessionSets = await loadStudyingSessionSets()
	const db = await databaseCurrent()
	await db.studyingsessionsets.bulkInsert(studyingSessionSets)
}

export async function pullStudyingSessionSet(id: string): Promise<void> {
	const studyingSessionSet = await loadStudyingSessionSetById(id)
	if (studyingSessionSet) {
		const db = await databaseCurrent()
		await db.studyingsessionsets.atomicUpsert(studyingSessionSet)
	} else {
		await logError(`pullStudyingSessionSet(${id}) returns ${studyingSessionSet}`)
	}
}

export async function handleInsertStudyingSessionSet(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const studyingSessionSetUnknown = await db.collections.studyingsessionsets.getById(id) === null
	if (studyingSessionSetUnknown) {
		await pullStudyingSessionSet(id)
	}
}

export async function handleInsertStudyingSessionSetsChange(
	change: StudyingSessionSetsChangeType['Insert'],
): Promise<void> {
	await handleInsertStudyingSessionSet(change.payload.id)
}

export async function handleDeleteStudyingSessionSetsChange(
	change: StudyingSessionSetsChangeType['Delete'],
): Promise<void> {
	const studyingSessionSetId = change.payload.id
	const db = await databaseCurrent()
	const studyingSessionSet = await db.collections.studyingsessionsets
		.findOne(studyingSessionSetId).exec()
	await studyingSessionSet?.remove()
}

export async function insertStudyingSessionSet(
	studyingSessionSet: OfflineEntity<StudyingSessionSet>,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.studyingsessionsets.insert(createOfflineCapable(studyingSessionSet))
}

export async function deleteStudyingSessionSet(id: string): Promise<void> {
	const db = await databaseCurrent()
	const studyingSessionSet = await db.collections.studyingsessionsets.findOne(id).exec()
	if (studyingSessionSet?.offlinestate !== 'deleted') {
		await studyingSessionSet?.atomicPatch({
			offlinestate: 'deleted',
		})
	}
}

export async function deleteStudyingSessionSetByStudyingSessionId(
	studyingsessionid: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.studyingsessionsets.find({
		selector: {
			studyingsessionid,
		},
	}).remove()
}

import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { Navigate } from 'react-router-dom'

export function UnauthenticatedRedirect({
	to,
	children,
}: React.PropsWithChildren<{ to: string }>): React.ReactElement {
	const { keycloak } = useKeycloak()
	return keycloak.authenticated ? <Navigate to={to} /> : <>{children}</>
}

// https://stackoverflow.com/questions/316781/how-to-build-query-string-with-javascript/49701878#49701878
export function createUrl(params: {
	url: string
	query?: Record<string, string | string[]>
}): string {
	const searchParams = new URLSearchParams()
	Object.entries(params.query ?? {}).forEach(([key, values]) => {
		(Array.isArray(values) ? values : [values])
			.forEach(value => searchParams.append(key, value))
	})
	return [
		params.url,
		searchParams.toString(),
	].filter(Boolean).join('?')
}

import React from 'react'
import {
	ReactKeycloakProvider,
	useKeycloak,
} from '@react-keycloak/web'
import {
	BehaviorSubject,
	debounce,
	map,
	mergeMap,
	timer,
} from 'rxjs'
import {
	keycloak,
} from './keycloak'
import {
	logError,
} from '../monitoring/Monitoring'

export const accessTokenObserver = new BehaviorSubject<string | undefined>(undefined)

function WaitForInitialization({
	children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
	const { initialized } = useKeycloak()
	if (initialized) {
		return <>{children}</>
	}
	return <div>Loading...</div>
}

// keycloak client is crappy and doesn't support premature renewal
accessTokenObserver
	.pipe(
		map(() => {
			// try to determine the token expiration time and
			// renew token 20 seconds before expiration
			if (keycloak.tokenParsed?.exp) {
				const expiration = new Date(keycloak.tokenParsed.exp * 1_000)
				const timeout = expiration.valueOf() - new Date().valueOf()
				// if something goes wrong, we ignore it here ...
				if (timeout > 45_000) {
					return timeout - 20_000
				}
			}
			// ... but default is 5 minutes, so we
			// try to refresh after 4,5 minutes
			return 270_000
		}),
		debounce(timer),
		mergeMap(() => keycloak.updateToken(-1)),
	)
	.subscribe()

function onTokens({ token }: { token?: string }) {
	accessTokenObserver.next(token)
}

function onAuthRefreshError() {
	// retry on error
	keycloak.updateToken(-1)
		.catch(e => logError(e))
}

function onEvent(eventType: string): void {
	switch (eventType) {
		case 'onAuthRefreshError':
			onAuthRefreshError()
			break
		default:
	}
}

const initOptions = {
	pkceMethod: 'S256',
}

export function AuthProvider({
	children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
	return (
		<ReactKeycloakProvider
			authClient={keycloak}
			onTokens={onTokens}
			onEvent={onEvent}
			initOptions={initOptions}
		>
			<WaitForInitialization>
				{children}
			</WaitForInitialization>
		</ReactKeycloakProvider>
	)
}

import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect } from 'react'
import { resetDatabase } from '../database/Database'
import { logError } from '../monitoring/Monitoring'

export function Logout(): React.ReactElement {
	const { keycloak } = useKeycloak()

	useEffect(() => {
		// avoid strict mode re-render
		// https://stackoverflow.com/questions/68291908/why-promise-then-is-called-twice-in-a-react-component-but-not-the-console-log
		resetDatabase()
			.catch(e => logError(e))
			.finally(() => keycloak.logout({
				redirectUri: window.location.origin,
			}).catch(e => logError(e)))
	}, [keycloak])

	return <></>
}

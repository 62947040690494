import React, { InputHTMLAttributes, forwardRef } from 'react'
import { InputError } from './InputError'

type InputProps = {
	type?: 'text' | 'email' | 'password'
	name: string
	label: string
	error?: string
} & Pick<InputHTMLAttributes<unknown>,
	| 'onChange'
	| 'onBlur'
	| 'value'
	| 'defaultValue'
>

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input({
	type = 'text',
	name,
	label,
	value,
	defaultValue,
	error,
	onChange,
	onBlur,
}: InputProps, ref): React.ReactElement {
	return (
		<div className="relative z-0 w-full mb-5">
			<input
				ref={ref}
				type={type}
				name={name}
				value={value}
				defaultValue={defaultValue}
				onChange={onChange}
				onBlur={onBlur}
				placeholder=" "
				className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200 caret-green"
			/>
			<label
				htmlFor={name}
				className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
			>
				{label}
			</label>

			<InputError error={error} />
		</div>
	)
})

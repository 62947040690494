import { RxCollection, RxJsonSchema } from 'rxdb'
import { Observable } from 'rxjs'
import { DbIdChanges, databaseCurrent } from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { toJson } from '../../utils/RxDbUtils'
import {
	deleteQuestion,
} from '../question/questionsDb'
import { loadSetById, loadSets } from './setsGql'

type SetsChangeType = DbIdChanges<'sets'>
export type ChangeSets = SetsChangeType['All']

export interface Set {
	id: string
	packageid: string
	name: string
}

interface StaticMethods {
	getById(id: string): Promise<Set | null>
	getByIds(ids: string[]): Observable<Set[]>
}

export type SetCollection = RxCollection<Set, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: SetCollection, id: string): Promise<Set | null> {
		const set = await this.findOne(id).exec()
		return set?.toJSON() ?? null
	},
	getByIds(
		this: SetCollection,
		ids: string[],
	): Observable<Set[]> {
		return toJson(this.find({
			selector: {
				id: {
					$in: ids,
				},
			},
		}).$)
	},
}

export const setsSchema: RxJsonSchema<Set> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'packageid',
		'name',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		packageid: {
			type: 'string',
		},
		name: {
			type: 'string',
			maxLength: 200,
		},
	},
	indexes: ['name'],
}

export const setCollection: Record<'sets', RxCollectionCreator<StaticMethods>> = {
	sets: {
		schema: setsSchema,
		statics,
	},
}

export async function pullSets(): Promise<void> {
	const sets = await loadSets()
	const db = await databaseCurrent()
	await db.sets.bulkInsert(sets)
}

export async function pullSet(id: string): Promise<void> {
	const set = await loadSetById(id)
	if (set) {
		const db = await databaseCurrent()
		await db.sets.atomicUpsert(set)
	} else {
		await logError(`pullSet(${id}) returns ${set}`)
	}
}

export async function handleInsertSet(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const setUnknown = await db.collections.sets.getById(id) === null
	if (setUnknown) {
		await pullSet(id)
	}
}

export async function handleInsertSetsChange(
	change: SetsChangeType['Insert'],
): Promise<void> {
	await handleInsertSet(change.payload.id)
}

export async function handleUpdateSetsChange(
	change: SetsChangeType['Update'],
): Promise<void> {
	await pullSet(change.payload.id)
}

export async function deleteSet(
	setid: string,
): Promise<void> {
	const db = await databaseCurrent()
	// remove set from ui faster
	await db.collections.sets.find({
		selector: {
			id: setid,
		},
	}).remove()
	const questions = await db.collections.questions.find({
		selector: {
			setid,
		},
	}).exec()
	for (const question of questions) {
		await deleteQuestion(question.id)
	}
}

export async function handleDeleteSetsChange(
	change: SetsChangeType['Delete'],
): Promise<void> {
	await deleteSet(change.payload.id)
}

export async function deleteSetIfNotExists(id: string): Promise<void> {
	const setRemote = await loadSetById(id)
	if (!setRemote) {
		const db = await databaseCurrent()
		await db.collections.sets.findOne(id).remove()
	}
}

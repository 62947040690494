import {
	ensureDefined,
} from '@hirn.app/shared'
import React, {
	useEffect,
} from 'react'
import {
	useForm,
} from 'react-hook-form'
import {
	useNavigate,
} from 'react-router-dom'
import {
	map,
} from 'rxjs'
import {
	Button,
} from '../../components/Button'
import {
	Content,
} from '../../components/Content'
import {
	Frame,
} from '../../components/Frame'
import {
	H1,
} from '../../components/H1'
import {
	Input,
} from '../../components/inputs/Input'
import {
	Option,
} from '../../components/inputs/Option'
import {
	handleFormValidation,
} from '../../database/validation'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	createPackage,
} from './packagesGql'

export function CreatePackage(): React.ReactElement {
	const organizations = useObserveDb(db => db.organizations
		.getWithMembers([getUserIdEnsured()])
		.pipe(map(orgs => orgs.map(org => ({
			label: org.name,
			value: org.id,
		})))), [])

	const formConfig = {
		defaultValues: {
			name: '',
			organizationid: organizations[0]?.value,
		},
	}

	const navigate = useNavigate()
	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		reset(formConfig.defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations.length > 0])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success, id] = await handleFormValidation(
			() => createPackage(data.name, ensureDefined(data.organizationid)),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.package.view.url(ensureDefined(id)), {
				replace: true,
			})
		}
	}
	return (
		<Content variant="narrow">
			<Frame>
				<H1>Paket anlegen</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Option
						label="Organisation"
						register={register('organizationid')}
						options={organizations}
						error={errors.organizationid?.message}
					/>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<div className="text-gray-600 mb-2">Ein Paket stellt eine Klammer um eine Wissenssammlung zu einem bestimmten Thema. Ein paar Beispiele: &quot;Theoretische Führerscheinprüfung&quot;, &quot;Spanisch in 21 Tagen lernen&quot; oder &quot;Urlaub in Singapur {new Date().getFullYear() + 1}&quot;.</div>
					<div className="w-full text-right">
						<Button
							to={AppRoutes.package.list.url()}
							color="secondary"
							addClassName="mr-2"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

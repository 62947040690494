import {
	omit,
} from 'remeda'
import {
	DeepReadonlyObject,
	RxDocument,
	RxDocumentBase,
} from 'rxdb'
import {
	Observable,
	map,
} from 'rxjs'

type Undeep<T> = T extends DeepReadonlyObject<infer U> ? U : T

/**
 * Remove RxDB's DeepReadonlyObject
 */
export function undeep<T>(obj: T): Undeep<T> {
	return obj as Undeep<T>
}

type UnRxDocument<T> = T extends RxDocumentBase<infer U, unknown> ? U : T

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toJsonObject<T extends RxDocumentBase<any, unknown>>(
	entry: T,
): UnRxDocument<T> {
	// lokijs storage leaks _deleted, see
	// https://github.com/pubkey/rxdb/pull/3645
	return omit(entry.toJSON(), ['_deleted']) as UnRxDocument<T>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toJson<T extends Observable<RxDocument<RxDocument<any>, unknown>[]>>(
	observable$: T,
): T {
	return observable$.pipe(map(entries => entries.map(toJsonObject))) as T
}

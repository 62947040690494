import {
	createUrl,
} from '../utils/UrlUtils.js'

type StudySystemParams = {
	setid: string[]
} | {
	setid?: string[]
	system: 'leitner'
} | {
	setid?: string[]
	system: 'hirn'
	maxdifficulty: string
}

export const AppRoutes = {
	common: {
		logout: {
			path: '/logout',
			url: (): string => AppRoutes.common.logout.path,
		},
		terms: {
			path: '/terms',
			url: (): string => AppRoutes.common.terms.path,
		},
		imprint: {
			path: '/imprint',
			url: (): string => AppRoutes.common.imprint.path,
		},
	},
	package: {
		list: {
			path: '/packages',
			url: (): string => `/backoffice${AppRoutes.package.list.path}`,
		},
		create: {
			path: '/packages/create',
			url: (): string => `/backoffice${AppRoutes.package.create.path}`,
		},
		view: {
			path: '/packages/view/:id',
			url: (id: string): string => `/backoffice/packages/view/${id}`,
		},
		edit: {
			path: '/packages/edit/:id',
			url: (id: string): string => `/backoffice/packages/edit/${id}`,
		},
	},
	set: {
		create: {
			path: '/sets/create/package/:packageid',
			url: (packageid: string): string => `/backoffice/sets/create/package/${packageid}`,
		},
		edit: {
			path: '/sets/edit/:id',
			url: (id: string): string => `/backoffice/sets/edit/${id}`,
		},
	},
	question: {
		list: {
			path: '/set/:setid/questions',
			url: (setid: string): string => `/backoffice/set/${setid}/questions`,
		},
		create: {
			path: '/set/:setid/questions/create',
			url: (setid: string): string => `/backoffice/set/${setid}/questions/create`,
		},
		edit: {
			path: '/questions/edit/:id',
			url: (id: string): string => `/backoffice/questions/edit/${id}`,
		},
	},
	team: {
		list: {
			path: '/teams',
			url: (): string => `/backoffice${AppRoutes.team.list.path}`,
		},
		create: {
			path: '/teams/create',
			url: (): string => `/backoffice${AppRoutes.team.create.path}`,
		},
		edit: {
			path: '/teams/edit/:id',
			url: (id: string): string => `/backoffice/teams/edit/${id}`,
		},
	},
	teammember: {
		list: {
			path: '/team/:teamid/teammembers',
			url: (teamid: string): string => `/backoffice/team/${teamid}/teammembers`,
		},
		invitation: {
			path: '/member/invitation/:teamid',
			url: (teamid: string): string => `/backoffice/member/invitation/${teamid}`,
		},
	},
	organization: {
		list: {
			path: '/accounts',
			url: (): string => `/backoffice${AppRoutes.organization.list.path}`,
		},
		create: {
			path: '/organizations/create',
			url: (): string => `/backoffice${AppRoutes.organization.create.path}`,
		},
		edit: {
			path: '/organizations/edit/:id',
			url: (id: string): string => `/backoffice/organizations/edit/${id}`,
		},
	},
	organizationmember: {
		list: {
			path: '/organizationmembers/list/:organizationid',
			url: (organizationid: string): string => `/backoffice/organizationmembers/list/${organizationid}`,
		},
		invitation: {
			path: '/organizationmembers/invitation/:organizationid',
			url: (organizationid: string): string => `/backoffice/organizationmembers/invitation/${organizationid}`,
		},
	},
	studyingSession: {
		list: {
			path: '/studyingsessions',
			url: (): string => `/backoffice${AppRoutes.studyingSession.list.path}`,
		},
		study: {
			path: '/studyingsession/study/:id',
			url: (id: string): string => `/backoffice/studyingsession/study/${id}`,
		},
		create: {
			path: '/studyingsession/create',
			url: (params?: StudySystemParams): string => createUrl({
				url: `/backoffice${AppRoutes.studyingSession.create.path}`,
				query: params,
			}),
		},
	},
	billing: {
		list: {
			path: '/billings',
			url: (): string => `/backoffice${AppRoutes.billing.list.path}`,
		},
	},
	incominginvitation: {
		list: {
			path: '/incominginvitations',
			url: (): string => `/backoffice${AppRoutes.incominginvitation.list.path}`,
		},
		details: {
			path: '/incominginvitation/:invitationid/details',
			url: (invitationid: string): string => `/backoffice/invitation/${invitationid}/details`,
		},
		accept: {
			path: '/incominginvitation/:invitationid/accept',
			url: (invitationid: string): string => `/backoffice/incominginvitation/${invitationid}/accept`,
		},
	},
	outgoinginvitation: {
		list: {
			path: '/outgoinginvitations',
			url: (): string => `/backoffice${AppRoutes.outgoinginvitation.list.path}`,
		},
	},
} as const

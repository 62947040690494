import { ensureDefined } from '@hirn.app/shared'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { map } from 'rxjs'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import { Input } from '../../components/inputs/Input'
import { handleFormValidation } from '../../database/validation'
import { getUserIdEnsured } from '../../security/keycloak'
import { useObserveDb } from '../../utils/useObserveDb'
import { useParamsDefined } from '../../utils/useParamsDefined'
import { AppRoutes } from '../AppRoutes'
import { createSet } from './setsGql'

export function CreateSet(): React.ReactElement {
	const { packageid } = useParamsDefined<'packageid'>()
	const organizations = useObserveDb(db => db.organizations
		.getWithMembers([getUserIdEnsured()])
		.pipe(map(orgs => orgs.map(org => ({
			label: org.name,
			value: org.id,
		})))), [])

	const formConfig = {
		defaultValues: {
			name: '',
		},
	}

	const navigate = useNavigate()
	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		reset(formConfig.defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations.length > 0])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success, id] = await handleFormValidation(
			() => createSet(
				packageid,
				data.name,
			),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.question.list.url(ensureDefined(id)), {
				replace: true,
			})
		}
	}
	return (
		<Content variant="narrow">
			<Frame>
				<H1>Lernsatz anlegen</H1>
				<form onSubmit={handleSubmit(onSubmit)}>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<div className="w-full text-right">
						<Button
							to={AppRoutes.package.view.url(packageid)}
							color="secondary"
							addClassName="mr-2"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

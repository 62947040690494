import React from 'react'
import {
	Route,
	BrowserRouter as Router,
	Routes,
} from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { Backoffice } from './backoffice/Backoffice'
import { Logout } from './backoffice/Logout'
import { AppRoutes } from './backoffice/AppRoutes'
import { AuthenticatedView } from './security/AuthenticatedView'
import { UnauthenticatedRedirect } from './security/UnauthenticatedRedirect'
import { Startpage } from './startpage/Startpage'
import { Imprint } from './startpage/Imprint'
import { Terms } from './startpage/Terms'
import { Menu } from './backoffice/Menu'
import { PublicMenu } from './startpage/PublicMenu'
import { DatabaseProvider } from './database/DatabaseProvider'
import { useIsVisibleOnScroll } from './utils/useIsVisibleOnScroll'

export function App(): React.ReactElement {
	const { keycloak } = useKeycloak()

	const {
		atTop,
		observationRef,
		scrollRef,
	} = useIsVisibleOnScroll()
	return (
		<div className="grid grid-rows-2-second h-full">
			<Router>
				{
					keycloak.authenticated
						? <Menu />
						: <PublicMenu state={atTop ? 'normal' : 'shrinked'} />
				}
				<div ref={scrollRef} className="overflow-y-auto narrow-scrollbar">
					<Routes>
						<Route path="/" element={
							<UnauthenticatedRedirect to={AppRoutes.package.list.url()}>
								<Startpage observationRef={observationRef} />
							</UnauthenticatedRedirect>
						} />
						<Route path="/backoffice/*" element={
							<AuthenticatedView>
								<DatabaseProvider>
									<Backoffice />
								</DatabaseProvider>
							</AuthenticatedView>
						} />
						<Route path={AppRoutes.common.logout.path} element={
							<DatabaseProvider>
								<Logout />
							</DatabaseProvider>
						} />
						<Route path={AppRoutes.common.terms.path} element={
							<Terms observationRef={observationRef} />
						} />
						<Route path={AppRoutes.common.imprint.path} element={
							<Imprint observationRef={observationRef} />
						} />
					</Routes>
				</div>
			</Router>
		</div>
	)
}

import React, { useRef } from 'react'
import {
	useNavigate,
} from 'react-router-dom'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import { acceptInvitation } from './memberInvitationsGql'
import { useEffectAsync } from '../../utils/useEffectAsync'

export function AcceptIncomingInvitation(): React.ReactElement {
	const { invitationid } = useParamsDefined<'invitationid'>()
	const navigate = useNavigate()
	const usedInvitation = useRef<string>()

	useEffectAsync(`AcceptIncomingInvitation.useEffectAsync(${invitationid})`, async () => {
		// prevent second call in strict mode
		if (usedInvitation.current === invitationid) {
			return
		}
		usedInvitation.current = invitationid

		try {
			const invitation = await acceptInvitation(invitationid)
			if (invitation.teamid) {
				navigate(AppRoutes.team.list.url())
				return
			}
			navigate(AppRoutes.organization.list.url())
		} catch (error) {
			navigate(AppRoutes.organization.list.url())
		}
	}, [invitationid])

	return <></>
}

import React from 'react'
import { zip } from 'remeda'
import { TableProps } from './Table'

function getItemClasses(index: number, length:number): string {
	if (index === 0) {
		return 'rounded-tl-lg'
	}
	if (index === length - 1) {
		return 'rounded-bl-lg'
	}
	return ''
}

export function SmallTable({ headers, rows }: TableProps): React.ReactElement {
	return (
		<div className="ha-table flex items-center justify-center">
			<div className="container">
				<table className="w-full my-5">
					<tbody>
						{
							rows.length === 0
								? headers.map((header, headeri) => <tr key={headeri}>
									<td className={`text-white font-bold bg-green/75 ${getItemClasses(headeri, headers.length)}`}>{header}</td>
									{
										headeri === 0 && <td rowSpan={headers.length} className="text-center">Keine Einträge vorhanden</td>
									}
								</tr>)
								: rows.map((row, rowi) => <React.Fragment key={`block-${rowi}`}>{
									zip(headers, row).map(([header, cell], celli) => <tr key={`row-${rowi}-${celli}`}>
										<td className={`text-white font-bold bg-green/75 ${getItemClasses(celli, headers.length)}`}>{header}</td>
										<td className="text-center">{cell}</td>
									</tr>)
								}
									<tr><td className="p-2"></td></tr>
								</React.Fragment>)
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

import {
	DependencyList,
	useEffect,
	useState,
} from 'react'
import { Observable } from 'rxjs'

export function useObserve<S>(
	effect: Observable<S>,
	initialState: S | (() => S),
	deps: DependencyList = [],
): S {
	const [state, setState] = useState<S>(initialState)

	useEffect(() => {
		const subscription = effect.subscribe(result => {
			setState(result)
		})
		return () => subscription.unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps])

	return state
}

import {
	leftJoinObservable,
} from '@hirn.app/shared'
import React, {
	useMemo,
} from 'react'
import {
	prop,
	uniqBy,
} from 'remeda'
import {
	map,
} from 'rxjs'
import {
	Button,
} from '../../components/Button'
import {
	Content,
} from '../../components/Content'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	organizationPackageManagerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'
import {
	AppRoutes,
} from '../AppRoutes'

export function PackageList(): React.ReactElement {
	const packages = useObserveDb(
		db => {
			const packages$ = toJson(db.collections.packages.find().sort({ name: 'asc' }).$)
			const organizations$ = db.organizations
				.getWithMembers([getUserIdEnsured()])
			const packageWithOrganization$ = leftJoinObservable(
				'organization',
				packages$, 'organizationid',
				organizations$, 'id',
			)
			const organizationPackageLicenses$ = db.organizationpackagelicenses
				.getByUserIds([getUserIdEnsured()])
				.pipe(map(licenses => uniqBy(licenses, prop('packageid'))))
			const packageWithOrganizationAndLicense$ = leftJoinObservable(
				'license',
				packageWithOrganization$, 'id',
				organizationPackageLicenses$, 'packageid',
			)
			return packageWithOrganizationAndLicense$
		},
		[],
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Paket</TableHeader>,
		<TableHeader addClassName="sm:text-center">Anbieter</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => packages.map(pakage => {
		// to check zero index is enough, because it can be only current user
		const canManage = organizationPackageManagerFilter(
			pakage.organization?.organizationmembers[0]?.roles ?? [],
		).length > 0
		return [
			<TableItem>{pakage.name}</TableItem>,
			<TableItem addClassName="sm:text-center">{pakage.organizationname}</TableItem>,
			<TableItem addClassName="cursor-pointer flex flex-wrap justify-center">
				{
					canManage
					&& <Button color="secondary" addClassName="m-1">TODO: Lizenzen</Button>
				}
				{
					(canManage || pakage.license)
					&& <Button color="primary" addClassName="m-1" to={AppRoutes.package.view.url(pakage.id)}>
						Anzeigen
					</Button>
				}
			</TableItem>,
		]
	}), [packages])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="text-right">
				<Button
					color={packages.length === 0 ? 'primary' : 'secondary'}
					to={AppRoutes.package.create.url()}
				>
					Paket anlegen
				</Button>
			</div>
		</Content>
	)
}

import {
	outdent,
} from 'outdent'
import {
	query,
} from '../../database/GraphQl'
import {
	Package,
} from './packagesDb'

interface Response {
	packages: Package[]
}

export async function loadPackages(): Promise<Package[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadPackages {
				packages {
					id
					organizationid
					organizationname
					name
				}
			}`,
	})
	return result.packages
}

export async function loadPackageById(id: string): Promise<Package | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadPackage($ids: [uuid!]!) {
				packages(where: {id: {_in: $ids}}) {
					id
					organizationid
					organizationname
					name
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.packages[0] ?? null
}

export async function createPackage(
	name: string,
	organizationid: string,
): Promise<string> {
	const response = await query<{ insert_package: { id: string } }>({
		query: outdent`
			mutation CreateOwnPackage($name: String!, $availability: String!, $organizationid: uuid!) {
				insert_package(object: {name: $name, availability: $availability, organizationid: $organizationid}) {
					id
				}
			}
			`,
		variables: {
			name,
			availability: 'disabled',
			organizationid,
		},
	})
	return response.insert_package.id
}

export async function updatePackage(
	id: string,
	name: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdatePackage($id: uuid!, $name: String!) {
				update_package(pk_columns: {id: $id}, _set: {name: $name}) {
					name
				}
			}`,
		variables: {
			id,
			name,
		},
	})
}

export async function deletePackage(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeletePackage($packageid: uuid!) {
				delete_package(id: $packageid) {
					id
				}
			}
			`,
		variables: {
			packageid: id,
		},
	})
}

import React from 'react'
import { map } from 'rxjs'
import { useObserveDb } from '../../utils/useObserveDb'

interface OrganizationLabelProps {
	organizationid: string
}

export function OrganizationLabel({ organizationid }: OrganizationLabelProps): React.ReactElement {
	const organizationName = useObserveDb<string | undefined>(
		db => db.collections.organizations.findOne(organizationid)
			.$
			.pipe(map(q => q?.name)),
		undefined,
		[organizationid],
	)

 return <span>{organizationName}</span>
}

import React, { useState } from 'react'
import { groupBy } from 'remeda'
import { EditIcon, RemoveIcon } from '../icon'

interface ItemProps<T> {
	key: T
	label: string
	selected: boolean
	removeable: boolean
}

interface MultiselectProps<T> {
	items: readonly ItemProps<T>[]
	addItem: (item: ItemProps<T>) => void
	removeItem: (item: ItemProps<T>) => void
	editable: boolean
}

// https://dev.to/hunterjsbit/build-multiselect-component-in-react-in-few-lines-4m1c
export function Multiselect<T>({
	items, addItem, removeItem, editable,
}: MultiselectProps<T>): React.ReactElement {
	// state showing if dropdown is open or closed
	const [isOpen, setIsOpen] = useState(false)

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const handleAddItem = (item: ItemProps<T>) => {
		addItem(item)
		setIsOpen(false)
	}

	const handleRemoveItem = (item: ItemProps<T>) => {
		removeItem(item)
		setIsOpen(false)
	}

	const { selected, unselected } = groupBy(
		items,
		item => (item.selected ? 'selected' : 'unselected'),
	)

	return (
		<div className="flex justify-center">
			<div className="flex-col">
				<div className="flex items-center justify-center flex-inline">
					<div className="flex border">
						<div className="flex flex-auto flex-wrap">
							{
								selected?.map(selectedItem => (
									<div
										key={selectedItem.label}
										className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full border border-green "
									>
										<div className="text-xs font-normal leading-none max-w-full flex-initial">
											{selectedItem.label}
										</div>
										{selectedItem.removeable
											&& editable
											&& <div
												className="flex flex-auto lex-row-reverse"
											>
												<div
													data-test-id="remove-item"
													onClick={() => handleRemoveItem(selectedItem)}
												>
													<RemoveIcon className="pl-1 w-3 h-3"/>
												</div>
											</div>
										}
									</div>
								))
							}

						</div>
						{editable
							&& <div className="p-1"
								onClick={toggleDropdown}
								data-test-id="toggle-dropdown"
							>
								<EditIcon className="w-5 h-5"/>
							</div>
						}
					</div>
				</div>

				{
					isOpen
						&& <div className="flex-1 bg-white absolute border rounded-b z-40 overflow-y-auto" data-test-id="dropdown">
							{unselected?.map(item => (
								<div
									key={item.label}
									className="cursor-pointer hover:text-green block"
									onClick={() => handleAddItem(item)}
								>
									<div className="mx-2 py-2">
										{item.label}
									</div>
								</div>
								))}
						</div>
				}
			</div>
		</div>
	)
}

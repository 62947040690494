import {
	leftJoinAggObservable,
} from '@hirn.app/shared'
import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
 Observable, switchMap,
} from 'rxjs'
import {
	DbIdChanges,
	database,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	OrganizationMemberRoleNames,
	handleDeleteOrganizationMembersByOrganization,
} from '../organizationmember/organizationMembersDb'
import {
	loadOrganizationById,
	loadOrganizations,
} from './organizationsGql'

type OrganizationsChangeType = DbIdChanges<'organizations'>

export type ChangeOrganizations = OrganizationsChangeType['All']

export interface Organization {
	id: string
	name: string
}

export interface OrganizationWithMemberRoleNames extends Organization {
	organizationmembers: OrganizationMemberRoleNames[]
}

interface StaticMethods {
	getById(id: string): Promise<Organization | null>
	getWithMembers(userids?: string[]): Observable<OrganizationWithMemberRoleNames[]>
}

export type OrganizationCollection = RxCollection<Organization, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: OrganizationCollection, id: string): Promise<Organization | null> {
		const organization = await this.findOne(id).exec()
		return organization?.toJSON() ?? null
	},
	getWithMembers(
		this: OrganizationCollection,
		userids?: string[],
	): Observable<OrganizationWithMemberRoleNames[]> {
		const organizationMembersObserver = database()
			.pipe(switchMap(db => db.collections.organizationmembers.getWithRoles({ userids })))
		const organizationsObserver = toJson(this
			.find().sort({ name: 'asc' }).$)
		const subscription = leftJoinAggObservable(
			'organizationmembers',
			organizationsObserver, 'id',
			organizationMembersObserver, 'organizationid',
		)
		return subscription
	},
}

export const organizationsSchema: RxJsonSchema<Organization> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'name',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		name: {
			type: 'string',
			maxLength: 200,
		},
	},
	indexes: ['name'],
}

export const organizationCollection: Record<'organizations', RxCollectionCreator<StaticMethods>> = {
	organizations: {
		schema: organizationsSchema,
		statics,
	},
}

export async function pullOrganizations(): Promise<void> {
	const organizations = await loadOrganizations()
	const db = await databaseCurrent()
	await db.organizations.bulkInsert(organizations)
}

export async function pullOrganization(id: string): Promise<void> {
	const organization = await loadOrganizationById(id)
	if (organization) {
		const db = await databaseCurrent()
		await db.organizations.atomicUpsert(organization)
	} else {
		await logError(`pullOrganization(${id}) returns ${organization}`)
	}
}

export async function handleInsertOrganization(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const organizationUnknown = await db.collections.organizations.getById(id) === null
	if (organizationUnknown) {
		await pullOrganization(id)
	}
}

export async function handleInsertOrganizationsChange(
	change: OrganizationsChangeType['Insert'],
): Promise<void> {
	await handleInsertOrganization(change.payload.id)
}

export async function handleUpdateOrganizationsChange(
	change: OrganizationsChangeType['Update'],
): Promise<void> {
	await pullOrganization(change.payload.id)
}

export async function handleDeleteOrganizationsChange(
	change: OrganizationsChangeType['Delete'],
): Promise<void> {
	const organizationid = change.payload.id
	const db = await databaseCurrent()
	await handleDeleteOrganizationMembersByOrganization(organizationid)
	const organization = await db.collections.organizations
		.findOne(organizationid).exec()
	await organization?.remove()
}

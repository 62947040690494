import React, {
	useMemo,
} from 'react'
import { map } from 'rxjs'
import {
	Button,
} from '../../components/Button'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	Content,
} from '../../components/Content'
import { stateMemberLabel } from '../../components/table/MemberInvitationTable'

export function OutgoingInvitationList(): React.ReactElement {
	const outgoinginvitations = useObserveDb(
		db => db.collections.memberinvitations.getMemberInvitations()
			.pipe(map(invitations => invitations
				.filter(invitation => invitation.inviteduserid !== getUserIdEnsured()))),
		[])

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Eingeladene</TableHeader>,
		<TableHeader>Ausgehende Einladung</TableHeader>,
		<TableHeader addClassName="sm:text-center">Status</TableHeader>,
	], [])

	const rows = useMemo(() => outgoinginvitations.map(invitation => [
		<TableItem>{invitation.email}</TableItem>,
		<TableItem>
			<span data-test-id="outgoinginvitation">
				{[invitation.teamname, invitation.organizationname].filter(Boolean).join(', ')}
			</span>
		</TableItem>,
		<TableItem addClassName="flex flex-wrap justify-center">
			{stateMemberLabel(invitation.state)}
		</TableItem>,
	]), [outgoinginvitations])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="flex justify-end">
				<Button
					color="secondary"
					to={AppRoutes.team.list.url()}
				>
					Abbrechen
				</Button>
			</div>
		</Content>
	)
}

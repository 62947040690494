import React, {
	Key,
	useCallback,
	useEffect,
	useState,
} from 'react'
import {
	useForm,
} from 'react-hook-form'
import {
	useNavigate,
} from 'react-router-dom'
import {
	Editor,
} from '@tiptap/react'
import {
	difference,
	prop,
} from 'remeda'
import {
	diff,
} from 'deep-object-diff'
import {
	MakeOptional,
	assertDefined,
	compare,
	ensureDefined,
} from '@hirn.app/shared'
import {
	filter,
	firstValueFrom,
	map,
} from 'rxjs'
import {
	Button,
} from '../../components/Button'
import {
	EditorInput,
} from '../../components/editor/EditorInput'
import {
	ValidationErrors,
	handleFormValidation,
} from '../../database/validation'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	createQuestion,
	deleteQuestion,
	updateQuestionContent,
} from './questionsGql'
import {
	Question,
	getQuestionTypeLabel,
	guessQuestionType,
} from './questionsDb'
import {
	Answer,
} from '../answer/answersDb'
import {
	Content,
} from '../../components/Content'
import {
	Frame,
} from '../../components/Frame'
import {
	H1,
} from '../../components/H1'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	DeleteIcon,
} from '../../icon'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	databaseCurrent,
} from '../../database/Database'
import {
	useEffectAsync,
} from '../../utils/useEffectAsync'
import {
	useDb,
} from '../../database/DatabaseProvider'
import {
	usePromiseResult,
} from '../../utils/usePromiseResult'

const placeholders = {
	free: {
		question: 'Hier kannst du Frage eingeben, die gelernt werden soll. Diese kannst du auch hübsch aufbereiten :o) In der Premium-Version hast du noch mehr Formatierungsmöglichkeiten wie z.B. Latex-Formeln für Mathe oder Physik',
		answer: 'Hier wird es spannend! Du kannst eine oder mehrere Antworten eingeben. Die richtige Antwort kannst du mit einem Häkchen unten markieren. In der Premium-Version kann die Lösung aus mehreren richtigen Antworten bestehen. Zudem kannst du zu Antworten ein Feedback hinterlegen. Sehr nützlich zum Beispiel für Erläuterungen und Quellen!',
	},
}

const formConfig = {
	defaultValues: {
		content: '',
	},
}

type EditableQuestionContent = Partial<Pick<Question, 'id' | 'content'>>

type EditableAnswerContent = MakeOptional<
	Pick<Answer, 'id' | 'content' | 'type'>,
	'id' | 'content'
>

function ManageQuestionContent({
	setid,
	question,
	answers: originalAnswers,
}: {
	setid: string
	question?: EditableQuestionContent
	answers: EditableAnswerContent[]
}): React.ReactElement {
	const variant = 'free'
	const navigate = useNavigate()
	const database = useDb()

	const set = usePromiseResult(
		'EditSet.set',
		async () => (await database.collections.sets.findOne(setid).exec())?.toJSON() ?? null,
		undefined,
		[setid, database],
	)

	const [questionEditor, setQuestionEditor] = useState<Editor>()
	const [answersHolder, setAnswersHolder] = useState(() => originalAnswers.map(answer => ({
		answer,
		editor: undefined as Editor | undefined,
	})))

	const questionLabel = getQuestionTypeLabel(guessQuestionType(
		answersHolder
			.filter(({ editor }) => (editor?.isEmpty ?? true) === false)
			.map(prop('answer'))))

	const questionsExists = useObserveDb<boolean | undefined>(
		db => db.collections.questions.getBySetId$(setid)
			.pipe(map(questions => questions.length > 0)),
		undefined,
		[setid],
	)

	const {
		handleSubmit,
		setError,
		formState: { errors },
		clearErrors,
	} = useForm(formConfig)

	const questionChange = useCallback((editor: Editor) => {
		if (questionEditor !== editor) {
			setQuestionEditor(editor)
		}
		if (errors.content?.message) {
			clearErrors()
		}
	}, [questionEditor, setQuestionEditor, clearErrors, errors])

	const updateAnswer = useCallback((editor: Editor, keyId?: Key) => {
		setAnswersHolder(currentAnswerHolders => currentAnswerHolders.map((answerHolder, i) => {
			if (i === keyId) {
				return {
					editor,
					answer: answerHolder.answer,
				}
			}
			return answerHolder
		}))
	}, [])

	useEffect(() => {
		// if new instance added, then on this call there is no instance, because
		// the new editor instance is mounted and passed after this cycle
		const editorsUninitialized = !!answersHolder.find(({ editor }) => !editor)
		if (editorsUninitialized) {
			return
		}

		const emptyEditors = answersHolder
			.filter(({ editor }) => editor?.isEmpty ?? true)

		// ensure that there is always an empty editor ...
		if (emptyEditors.length === 0) {
			setAnswersHolder([...answersHolder, {
				answer: {
					type: 'wrong',
				},
				editor: undefined,
			}])
		}

		// ... but when to many editors are empty, remove all unfocused
		if (emptyEditors.length > 1) {
			// treat not mounted yet editors as focused to avoid removement. Happens on initial load
			const toRemove = emptyEditors.filter(({ editor }) => (editor?.isFocused ?? true) === false)
			setAnswersHolder(difference(answersHolder, toRemove))
		}
	}, [answersHolder, setAnswersHolder])

	const deleteQuestionCallback = useCallback(async () => {
		try {
			await deleteQuestion(ensureDefined(question?.id))
			navigate(AppRoutes.question.list.url(setid), { replace: true })
		} catch (e) {
			if (e instanceof ValidationErrors) {
				const handled = e.errors.find(error => {
					if (error.code === 'foreignKeyConstraint') {
						setError('content', {
							message: error.translatedMessage,
						})
						return true
					}
					return false
				})
				if (handled) {
					return
				}
			}
			throw e
		}
	}, [setid, question, navigate, setError])

	async function onSubmit(): Promise<void> {
		const [success] = await handleFormValidation(
			() => {
				assertDefined(questionEditor)
				const modifiedAnswers = answersHolder
					// it looks like there is a race condition with react 18,
					// therefore we remove answers with undefined editor
					.filter(({ editor }) => editor && editor.isEmpty === false)
				if (question?.id) {
					const {
						added,
						changed,
						removed,
					} = compare(
						originalAnswers,
						modifiedAnswers,
						answer => ensureDefined(answer.id),
						editor => editor.answer.id,
						(oldAnswer, newAnswer) => oldAnswer.type === newAnswer.answer.type
							&& Object.keys(diff(
								oldAnswer.content ?? {},
								ensureDefined(newAnswer.editor).getJSON(),
							)).length === 0,
					)
					const questionContent = questionEditor.getJSON()
					const questionChanged = Object.keys(diff(
						ensureDefined(question.content),
						questionContent,
					)).length > 0

					return updateQuestionContent({
						questionid: question.id,
						question: questionChanged ? questionContent : undefined,
						addedAnswers: added.map(answer => ({
							content: ensureDefined(answer.editor).getJSON(),
							type: answer.answer.type,
						})),
						changedAnswers: changed.map(({ editor, answer }) => ({
							id: ensureDefined(answer.id),
							content: ensureDefined(editor).getJSON(),
							type: answer.type,
						})),
						removedAnswers: removed.map(answer => ensureDefined(answer.id)),
					})
				}
				return createQuestion(
					setid,
					questionEditor.getJSON(),
					modifiedAnswers
						.map(({ editor, answer }) => ({
							content: ensureDefined(editor).getJSON(),
							type: answer.type,
						})),
				)
			},
			['content'],
			setError,
		)
		if (success) {
			// TODO: currently, we don't store new question
			// in db directly and because of a race condition
			// we think that there is no question and go to the
			// question creation again. To avoid that we wait
			// for the first question appears before navigation
			if (!question && questionsExists === false) {
				const db = await databaseCurrent()
				await firstValueFrom(db.collections.questions.getBySetId$(setid)
					.pipe(filter(questions => questions.length > 0)))
			}
			navigate(AppRoutes.question.list.url(setid), {
				replace: true,
			})
		}
	}

	if (set === null) {
		// TODO: add proper 404
		return <div>404</div>
	}

	if (set === undefined) {
		return <div></div>
	}

	return (
		<Content variant="wide">
			<Frame>
				<H1>Frage anlegen</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<div>
							<p>Frage ({questionLabel})</p>
							<EditorInput
								variant="free"
								content={question?.content}
								placeholder={placeholders[variant].question}
								onChange={questionChange}
							/>
						</div>
						<div>
							<p>Antworten</p>
							{
								answersHolder.map(({ answer }, key) => (
									<div key={key}>
										<div>
											<EditorInput
												variant="free"
												content={answer.content}
												placeholder={key === 0 ? placeholders[variant].answer : undefined}
												onChange={updateAnswer}
												keyId={key}
											/>
										</div>
										<div>
											<label>
												<input
													type="checkbox"
													defaultChecked={answer.type === 'right'}
													onChange={e => {
														const type = e.target.checked ? 'right' : 'wrong'
														setAnswersHolder(
															answersHolder.map(answerHolder => (answerHolder.answer === answer ? {
																...answerHolder,
																answer: {
																	...answerHolder.answer,
																	type,
																},
															} : answerHolder)),
														)
													}}
												/> Richtige Antwort
											</label>
										</div>
									</div>
								))
							}
						</div>
					</div>
					{question?.id && (
						<Button
							onClick={deleteQuestionCallback}
							color="secondary"
							addClassName="float-left"
						>
							<DeleteIcon className="w-5 h-5" />
						</Button>
					)}
					<div className="w-full text-right">
						{
							errors.content
							&& <div className="text-sm text-red-600 pb-2">{errors.content.message}</div>
						}
						<div>
							<Button
								to={
									questionsExists
										? AppRoutes.question.list.url(setid)
										: AppRoutes.package.view.url(set.packageid)
								}
								color="secondary"
								addClassName="mr-2"
							>
								Abbrechen
							</Button>

							<Button
								type="submit"
								color="primary"
							>
								Speichern
							</Button>
						</div>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

export function CreateQuestion(): React.ReactElement {
	const { setid } = useParamsDefined<'setid'>()
	const [answers] = useState<Answer[]>([])
	return (
		<ManageQuestionContent
			setid={setid}
			answers={answers}
		/>
	)
}

export function EditQuestion(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()
	const [state, setState] = useState<{
		question: Question
		answers: Answer[]
	}>()

	useEffectAsync(
		`EditQuestion.useEffectAsync(${id})`,
		async () => {
			const db = await databaseCurrent()
			const [question, answers] = await Promise.all([
				db.collections.questions.getById(id),
				db.collections.answers.getByQuestionId(id),
			])
			setState({
				question: ensureDefined(question),
				answers,
			})
		},
		[id],
	)

	if (state) {
		return (
			<ManageQuestionContent
				setid={state.question.setid}
				question={state.question}
				answers={state.answers}
			/>
		)
	}
	// TODO: show not found if question is null?
	return <div>loading</div>
}

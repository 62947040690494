import { config } from '@fortawesome/fontawesome-svg-core'
import {
	faAngleDown,
	faBalanceScale,
	faBars,
	faCheck,
	faFileInvoice,
	faGraduationCap,
	faLandmark,
	faPen,
	faPlaneSlash,
	faSignOutAlt,
	faTimes,
	faTrashAlt,
	faUserCircle,
	faUserGraduate,
	faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'

// used css are conflicting with tailwindcss
// https://github.com/FortAwesome/react-fontawesome/issues/136#issuecomment-393569217
config.autoAddCss = false

export function AccountIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faUserCircle} />
}

export function BillingIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faFileInvoice} />
}

export function CheckIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faCheck} />
}

export function DeleteIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faTrashAlt} />
}

export function EditIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faPen} />
}

export function ImprintIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faLandmark} />
}

export function LerningIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faUserGraduate} />
}

export function LernSetIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faGraduationCap} />
}

export function LogoutIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faSignOutAlt} />
}

export function MenuClosedIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faBars} />
}

export function MenuLinkClosedIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faAngleDown} />
}

export function MenuOpenedIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faTimes} />
}

export function OfflineIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faPlaneSlash} />
}

export function TermsIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faBalanceScale} />
}

export function TeamIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faUsers} />
}

export function RemoveIcon(props?: Partial<FontAwesomeIconProps>): React.ReactElement {
	return <FontAwesomeIcon {...props} icon={faTimes} />
}

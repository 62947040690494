import {
	assertDefined,
} from '@hirn.app/shared'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import { Input } from '../../components/inputs/Input'
import { useDb } from '../../database/DatabaseProvider'
import { ValidationErrors, handleFormValidation } from '../../database/validation'
import { DeleteIcon } from '../../icon'
import { useParamsDefined } from '../../utils/useParamsDefined'
import {
	usePromiseResult,
} from '../../utils/usePromiseResult'
import { AppRoutes } from '../AppRoutes'
import { deleteSet, updateSet } from './setsGql'

export function EditSet(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()
	const navigate = useNavigate()
	const db = useDb()

	const set = usePromiseResult(
		'EditSet.set',
		async () => (await db.collections.sets.findOne(id).exec())?.toJSON() ?? null,
		undefined,
		[id, db],
	)

	const formConfig = {
		defaultValues: {
			name: '',
		},
	}

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => reset(set ?? undefined), [set, reset])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		assertDefined(set)
		const [success] = await handleFormValidation(
			() => updateSet(set.id, data.name),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.package.view.url(set.packageid))
		}
	}

	const deleteSetCallback = useCallback(async () => {
		try {
			assertDefined(set)
			await deleteSet(set.id)
			navigate(AppRoutes.package.view.url(set.packageid), { replace: true })
		} catch (e) {
			if (e instanceof ValidationErrors) {
				const handled = e.errors.find(error => {
					if (error.code === 'foreignKeyConstraint') {
						setError('name', {
							message: error.translatedMessage,
						})
						return true
					}
					return false
				})
				if (handled) {
					return
				}
			}
			throw e
		}
	}, [set, navigate, setError])

	if (set === null) {
		// TODO: add proper 404
		return <div>404</div>
	}

	return (
		<Content variant="narrow">
			<Frame>
				<H1>Lernsatz bearbeiten</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<Button
						onClick={deleteSetCallback}
						color="secondary"
						addClassName="float-left"
						dataTestId="delete"
					>
						<DeleteIcon className="w-5 h-5" />
					</Button>
					<div className="w-full text-right">
						<Button
							to={AppRoutes.package.view.url(set?.packageid ?? '')}
							color="secondary"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
							addClassName="ml-2"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

import {
	Remove,
	isObjectEmpty,
	leftJoinAggObservable,
} from '@hirn.app/shared'
import {
	MangoQuerySelector,
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
} from 'rxjs'
import {
	map,
	switchMap,
} from 'rxjs/operators'
import {
	DbIdChangesImmutable,
	database,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	handleInsertOrganization,
} from '../organization/organizationsDb'
import {
	OrganizationMemberRole,
	OrganizationMemberRoleName,
	handleDeleteOrganizationMemberRolesByOrganizationMemberId,
} from '../organizationmemberrole/organizationmemberrolesDb'
import {
	User,
	handleInsertUser,
} from '../user/usersDb'
import {
	loadOrganizationMemberById,
	loadOrganizationMembers,
} from './organizationMembersGql'

type OrganizationMembersChangeType = DbIdChangesImmutable<'organizationmembers'>

export type ChangeOrganizationMembers = OrganizationMembersChangeType['All']

export interface OrganizationMemberRoleNames {
	id: string
	userid: string
	organizationid: string
	roles: OrganizationMemberRoleName[]
	user?: User
}

export interface OrganizationMember {
	id: string
	userid: string
	user?: User
	organizationid: string
	roles?: OrganizationMemberRole[]
}

interface SearchProps {
	userids?: string[]
	organizationids?: string[]
}

interface StaticMethods {
	getById(id: string): Promise<OrganizationMember | null>
	getWithRoles(search: SearchProps): Observable<OrganizationMemberRoleNames[]>
	getByOrganizationIdWithMemberRoles(organizationid: string): Observable<OrganizationMember[]>
}

export type OrganizationMemberCollection = RxCollection<
	OrganizationMember,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: OrganizationMemberCollection,
		id: string,
	): Promise<OrganizationMember | null> {
		const organizationMember = await this.findOne(id).exec()
		return organizationMember?.toJSON() as OrganizationMember ?? null
	},
	getWithRoles(
		this: OrganizationMemberCollection,
		search: SearchProps,
	): Observable<OrganizationMemberRoleNames[]> {
		const { userids, organizationids } = search
		const selector: MangoQuerySelector<OrganizationMemberCollection> = {}
		if (userids) {
			selector.userid = {
				$in: userids,
			}
		}
		if (organizationids) {
			selector.organizationid = {
				$in: organizationids,
			}
		}

		const organizationMemberSelector = isObjectEmpty(selector)
			? undefined : { selector }

		const organizationMemberRolesObserver = database()
			.pipe(switchMap(db => toJson(db.collections.organizationmemberroles.find().$)))

		const organizationMembersObserver: Observable<OrganizationMember[]> = this
			.find(organizationMemberSelector).$
			.pipe(
				map(organizationMembers => organizationMembers
					.map(organizationMember => organizationMember.toJSON() as OrganizationMember)),
			)

		const membersWithRoles = leftJoinAggObservable(
			'roles',
			organizationMembersObserver, 'id',
			organizationMemberRolesObserver, 'organizationmemberid',
		).pipe(map(members => members.map(({ roles, ...member }) => ({
			...member,
			roles: roles.map(role => role.name),
		}))))
		return membersWithRoles
	},
	getByOrganizationIdWithMemberRoles(
		this: OrganizationMemberCollection,
		organizationid: string,
	): Observable<OrganizationMember[]> {
		const organizationMemberRolesObserver = database()
			.pipe(switchMap(db => {
				const organizationMemberRoles = toJson(db.collections.organizationmemberroles
					.find().sort({ id: 'asc' }).$)
				return organizationMemberRoles
			}))

		const organizationMembersObserver: Observable<OrganizationMember[]> = toJson(
			this.find({
				selector: {
					organizationid: {
						$eq: organizationid,
					},
				},
			}).sort({ organizationid: 'asc' }).$,
		)

		return leftJoinAggObservable(
			'roles',
			organizationMembersObserver, 'id',
			organizationMemberRolesObserver, 'organizationmemberid',
		)
	},
}

export const organizationMembersSchema: RxJsonSchema<
	Remove<OrganizationMember, 'user' | 'roles'>
> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'userid',
		'organizationid',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		userid: {
			type: 'string',
			maxLength: 36,
		},
		organizationid: {
			type: 'string',
			maxLength: 36,
		},
	},
	indexes: ['userid', 'organizationid'],
}

export const organizationMemberCollection: Record<string, RxCollectionCreator<StaticMethods>> = {
	organizationmembers: {
		schema: organizationMembersSchema,
		statics,
	},
}

export async function pullOrganizationMembers(): Promise<void> {
	const organizationMembers = await loadOrganizationMembers()
	const db = await databaseCurrent()
	await db.organizationmembers.bulkInsert(organizationMembers)
}

export async function pullOrganizationMember(id: string): Promise<OrganizationMember | null> {
	const organizationMember = await loadOrganizationMemberById(id)
	if (organizationMember) {
		const db = await databaseCurrent()
		await db.organizationmembers.atomicUpsert(organizationMember)
	} else {
		await logError(`pullOrganizationMember(${id}) returns ${organizationMember}`)
	}
	return organizationMember
}

export async function handleInsertOrganizationMember(id: string): Promise<void> {
	const db = await databaseCurrent()
	const organizationMemberUnknown = await db.collections.organizationmembers.getById(id) === null
	if (organizationMemberUnknown) {
		const organizationMember = await pullOrganizationMember(id)
		if (organizationMember) {
			// if organization isn't found, then the current user
			// gained permission to acces the company
			await handleInsertUser(organizationMember.userid)
			await handleInsertOrganization(organizationMember.organizationid)
		}
	}
}

export async function handleInsertOrganizationMembersChange(
	change: OrganizationMembersChangeType['Insert'],
): Promise<void> {
	await handleInsertOrganizationMember(change.payload.id)
}

export async function handleDeleteOrganizationMembersByOrganization(
	organizationid: string,
): Promise<void> {
	const db = await databaseCurrent()
	const organizationMembers = await db.collections.organizationmembers.find({
		selector: {
			organizationid,
		},
	}).exec()

	await Promise.all(organizationMembers.map(async organizationMember => {
		await handleDeleteOrganizationMemberRolesByOrganizationMemberId(organizationMember.id)
		await organizationMember.remove()
	}))
}

export async function handleDeleteOrganizationMembersChange(
	change: OrganizationMembersChangeType['Delete'],
): Promise<void> {
	const db = await databaseCurrent()
	const organizationMember = await db.collections.organizationmembers
		.findOne(change.payload.id).exec()
	if (organizationMember) {
		await handleDeleteOrganizationMemberRolesByOrganizationMemberId(organizationMember.id)
		await organizationMember.remove()
	}
}

import { ensureDefined } from '@hirn.app/shared'
import Keycloak from 'keycloak-js'

declare module 'keycloak-js' {
	interface KeycloakTokenParsed {
		preferred_username: string
	}
}

export const keycloak = new Keycloak({
	url: ensureDefined(process.env.SSO_WEB_ENDPOINT),
	realm: 'superlative',
	clientId: 'hirn!app',
})

export function getUserIdEnsured(): string {
	return ensureDefined(keycloak.tokenParsed?.sub)
}

import {
	leftJoin,
} from '@hirn.app/shared'
import {
	JSONContent,
} from '@tiptap/react'
import {
	groupBy,
	prop,
} from 'remeda'
import {
	MangoQuerySelector,
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
	combineLatest,
	map,
	of,
	switchMap,
} from 'rxjs'
import {
	DbIdChanges,
	database,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	Answer,
	deleteAnswersByQuestionId,
} from '../answer/answersDb'
import {
	deleteUserAnswersByQuestionId,
} from '../useranswer/useranswersDb'
import {
	UserQuestion,
	deleteUserQuestionByQuestionId,
} from '../userquestion/userquestionsDb'
import {
	loadQuestionById,
	loadQuestions,
} from './questionsGql'

type QuestionsChangeType = DbIdChanges<'questions'>

export type ChangeQuestions = QuestionsChangeType['All']

export interface Question {
	id: string
	setid: string
	createdat: string
	updatedat: string
	content: JSONContent
	// TODO: add to db
	order: number
}

export type QuestionWithUserQuestion = Question & {
	userquestion:
	| UserQuestion
	| Pick<UserQuestion,
		| 'level'
		| 'sessionlevel'
		| 'difficulty'
		| 'lastansweredat'>
}
const neverAnswered = new Date('2000-01-01').toISOString()

interface SearchProps {
	ids?: string[]
	setids?: string[]
	levels?: number[]
}

type StaticMethods = {
	getById(id: string): Promise<Question | null>
	getBySetId$(setid: string): Observable<Question[]>
	getWithUserQuestions$(search?: SearchProps): Observable<QuestionWithUserQuestion[]>
}

export type QuestionCollection = RxCollection<Question, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: QuestionCollection, id: string): Promise<Question | null> {
		const question = await this.findOne(id).exec()
		return question?.toJSON() as Question ?? null
	},
	getBySetId$(this: QuestionCollection, setid: string): Observable<Question[]> {
		// TODO: .sort({ order: 'asc' })?
		return this.find({
			selector: {
				setid: {
					$eq: setid,
				},
			},
			sort: [
				{
					createdat: 'asc',
				},
			],
		}).$
	},
	getWithUserQuestions$(
		this: QuestionCollection,
		{ ids, setids, levels }: SearchProps,
	): Observable<QuestionWithUserQuestion[]> {
		const selector: MangoQuerySelector<QuestionCollection> = {}
		if (ids) {
			selector.id = {
				$in: ids,
			}
		}
		if (setids) {
			selector.setid = {
				$in: setids,
			}
		}
		const questions$: Observable<Question[]> = toJson(this.find({
			selector,
		}).$)

		// https://stackoverflow.com/questions/62141648/rxjs-how-do-i-use-the-result-of-one-observable-in-another-and-then-process-thos/62141649#62141649
		return combineLatest([database(), questions$])
			.pipe(switchMap(([db, questionsFromDb]) => {
				// TODO: add setid to userquestions for better performance?
				const userquestions$: Observable<UserQuestion[]> = toJson(db.userquestions.find({
					selector: {
						questionid: {
							$in: questionsFromDb.map(prop('id')),
						},
					},
				}).$)
				return combineLatest([
					of(questionsFromDb),
					userquestions$,
				]).pipe(map(([questions, userquestions]) => {
					const questionsWithUserQuestions = leftJoin(
						'userquestion',
						questions, 'id',
						userquestions, 'questionid',
					)
						.map(question => ({
							...question,
							userquestion: question.userquestion ?? {
								difficulty: 30,
								level: 1,
								sessionlevel: 0,
								lastansweredat: neverAnswered,
							},
						}))
					if (levels) {
						return questionsWithUserQuestions
							.filter(question => levels.includes(question.userquestion.level))
					}
					return questionsWithUserQuestions
				}))
			}))
	},
}

export const questionsSchema: RxJsonSchema<Question> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'setid',
		'createdat',
		'content',
		// TODO: 'order',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		setid: {
			type: 'string',
			maxLength: 36,
		},
		createdat: {
			// only string, number and integer are sortable
			type: 'string',
			format: 'date-time',
			maxLength: 36,
		},
		updatedat: {
			type: 'string',
			format: 'date-time',
		},
		content: {
			type: 'object',
		},
		order: {
			type: 'number',
		},
	},
	indexes: ['setid', 'createdat'],
}

export const questionCollection: Record<'questions', RxCollectionCreator<StaticMethods>> = {
	questions: {
		schema: questionsSchema,
		statics,
	},
}

export type QuestionType =
	/**
	 * Informal question is a question without answers. Used
	 * to learn non-interactive things like anecdotes.
	 * For progress tracking we add 'right'/'wrong' buttons
	 * automatically
	 */
	| 'info'
	/**
	 * There is only one answer provided and means that user
	 * is in the charge to assess own knowledge. Initially,
	 * the answer is hidden and the user can display it.
	 * We add 'right'/'wrong' buttons automatically
	 */
	| 'self assessment'
	/**
	 * Multiple choice question with only one right answer
	 */
	| 'simple'
	/**
	 * Multiple choice question with multiple right answers
	 */
	| 'multiple'

export function guessQuestionType(
	answers: Pick<Answer, 'type'>[],
): QuestionType {
	const { right, wrong } = groupBy(
		answers,
		answer => answer.type,
	)
	const countRight = right?.length ?? 0
	const countWrong = wrong?.length ?? 0
	if ((countRight + countWrong) === 0) {
		// just show the question and right/wrong buttons
		return 'info'
	}
	if ((countRight + countWrong) === 1) {
		return 'self assessment'
	}
	if (countRight === 1) {
		return 'simple'
	}
	return 'multiple'
}

export function getQuestionTypeLabel(questionType: QuestionType): string {
	switch (questionType) {
		case 'info': return 'Informativ'
		case 'self assessment': return 'Selbstbeurteilung'
		case 'simple': return 'Einfache Auswahl'
		case 'multiple': return 'Mehrfache Auswahl'
	}
}

export async function pullQuestions(): Promise<void> {
	const questions = await loadQuestions()
	const db = await databaseCurrent()
	await db.questions.bulkInsert(questions)
}

export async function pullQuestion(id: string): Promise<void> {
	const question = await loadQuestionById(id)
	if (question) {
		const db = await databaseCurrent()
		await db.questions.atomicUpsert(question)
	} else {
		await logError(`pullQuestion(${id}) returns ${question}`)
	}
}

export async function handleInsertQuestion(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const questionUnknown = await db.collections.questions.getById(id) === null
	if (questionUnknown) {
		await pullQuestion(id)
	}
}

export async function handleInsertQuestionsChange(
	change: QuestionsChangeType['Insert'],
): Promise<void> {
	await handleInsertQuestion(change.payload.id)
}

export async function handleUpdateQuestionsChange(
	change: QuestionsChangeType['Update'],
): Promise<void> {
	await pullQuestion(change.payload.id)
}

export async function deleteQuestion(
	questionid: string,
): Promise<void> {
	await deleteUserAnswersByQuestionId(questionid)
	await deleteUserQuestionByQuestionId(questionid)
	await deleteAnswersByQuestionId(questionid)
	const db = await databaseCurrent()
	const question = await db.collections.questions
		.findOne(questionid).exec()
	await question?.remove()
}

export async function handleDeleteQuestionsChange(
	change: QuestionsChangeType['Delete'],
): Promise<void> {
	const questionid = change.payload.id
	await deleteQuestion(questionid)
}

import {
	assertDefined,
	leftJoinAggObservable,
	leftJoinObservable,
} from '@hirn.app/shared'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	sortBy,
} from 'remeda'
import {
	map,
} from 'rxjs'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import { getUserIdEnsured } from '../../security/keycloak'
import { toJson } from '../../utils/RxDbUtils'
import { useEffectAsync } from '../../utils/useEffectAsync'
import { useObserveDb } from '../../utils/useObserveDb'
import { useQuery } from '../../utils/useQuery'
import { AppRoutes } from '../AppRoutes'
import {
	StudyModeDifficulty,
	StudySystem,
	isStudySystem,
} from '../studying/studying'
import { insertStudyingSessionSet } from '../studyingsessionset/studyingsessionsetsDb'
import {
	StudyingSessionParams,
	insertStudyingSession,
} from './studyingsessionsDb'

function useStudyingSessionParams(): StudyingSessionParams {
	const query = useQuery()
	const setids = query.getAll('setid')
	const system = query.get('system') as StudySystem
	const maxdifficulty = query.get('maxdifficulty')
	return useMemo(() => {
		if (system === 'hirn') {
			assertDefined(maxdifficulty)
			return {
				setids,
				system,
				maxdifficulty: parseInt(maxdifficulty, 10),
			}
		}
		return {
			setids,
			system,
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setids.join('-'), system, maxdifficulty])
}

function SelectStudySystem() {
	const {
		setids,
	} = useStudyingSessionParams()
	return (
		<Content variant="wide">
			<Frame>
				<H1>Lernsystem auswählen</H1>
				{/* eslint-disable max-len */}
				<div>Wir machen dir einfach, sich für ein System zu entscheiden. Denn egal für welches System du dich entscheidest, der Lernerfolg wird zwischen den Lernsystemen sitzungsübergreifend übertragen. Deshalb probiere gerne mehrere Lernsysteme aus 🤓</div>
				<div>
					<div className="flex flex-col md:flex-row">
						<div className="flex flex-col justify-center">
							<Button
								to={AppRoutes.studyingSession.create.url({
									setid: setids,
									system: 'leitner',
								})}
								color="secondary"
								addClassName="text-center w-full md:w-60 flex flex-col justify-center"
							>
								Leitner-System
							</Button>
						</div>
						<p className="md:pl-4 pt-2">Die Fragen werden nach dem Leitner-System gestellt und ausgewertet. Dabei werden Fragen in 7 Fächer eingeteilt. Richtig beantwortete Fragen werden jeweils in den nächsten Fach gelegt und falsch beantwortete Fragen immer in den ersten. Nach Leitner sollte eine Lernsitzung jeden Tag absolviert werden und für jede Lernsitzung sind ein oder mehrere Fächer vorgesehen. Sind für den Tag alle Fächer leer, so wird automatisch der Lerntag übersprungen. Kling kompliziert? Keine Sorge - du wirst von dem System Schritt für Schritt geleitet 🚀 Das Leitner-System ist gut für kleine oder einfache Lernsätze geeignet.</p>
					</div>
					<div className="pt-4 flex flex-col md:flex-row">
						<div className="flex flex-col justify-center">
							<Button
								to={AppRoutes.studyingSession.create.url({
									setid: setids,
									system: 'hirn',
									maxdifficulty: `${StudyModeDifficulty.Normal}`,
								})}
								color="secondary"
								addClassName="text-center w-full md:w-60 flex flex-col justify-center"
							>
								hirn!app®-System
							</Button>
						</div>
						<p className="md:pl-4 pt-2">Ein schlaues Algorithmus sorgt für ein optimales Lernerfolg. Dabei passt sich das System dir und deinen Bedürfnissen automatisch an. Ist der Lernstoff schwierig und umfangreich? 🤯 Dann probiere dieses Lernsystem aus! 🏋 Wichtig: hirn!app®-System funktioniert am Besten, wenn du online bist. Im offline Modus wird eine weniger schlaue Version verwendet, die jedoch trotzdem anderen Lernsystemen die Stirn bieten kann 👌</p>
					</div>
					<div className="pt-4 flex flex-col md:flex-row">
						<div className="flex flex-col justify-center">
							<Button
								to={AppRoutes.studyingSession.create.url({
									setid: setids,
									system: 'hirn',
									maxdifficulty: `${StudyModeDifficulty.Reha}`,
								})}
								color="secondary"
								addClassName="text-center w-full md:w-60 flex flex-col justify-center"
							>
								Rehabilitationslernen
							</Button>
						</div>
						<p className="md:pl-4 pt-2">Eine Variation des hirn!app®-Systems für Menschen mit Lernschwierigkeiten. Auch bei Krankheiten wie Alzheimer, Demenz oder anderen Gedächtnisstörungen kann diese Methode besser geeignet sein.</p>
					</div>
				</div>
			</Frame>
		</Content>
	)
}

// TODO: allow select multiple sets
function SelectSets() {
	const systemParams = useStudyingSessionParams()
	const organizationPackageLicenses = useObserveDb(db => {
		const licenses$ = db.collections.organizationpackagelicenses
			.getByUserIdsWithPackage([getUserIdEnsured()])
		const sets$ = toJson(db.collections.sets.find().$)
		const packages$ = toJson(db.collections.packages.find().$)

		const licensesWithPackage$ = leftJoinObservable(
			'package',
			licenses$, 'packageid',
			packages$, 'id',
		)
		const licensesWithPackageAndSets$ = leftJoinAggObservable(
			'sets',
			licensesWithPackage$, 'packageid',
			sets$, 'packageid',
		)
		return licensesWithPackageAndSets$.pipe(
			map(licenses => licenses.flatMap(license => license.sets.map(set => ({
				id: set.id,
				name: set.name,
				packageName: license.package?.name ?? '',
			})))),
			map(
				sets => sortBy(
					sets,
					set => set.packageName,
					set => set.name,
				),
			))
	}, [])

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Paket</TableHeader>,
		<TableHeader addClassName="sm:text-center">Lernsatz</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => organizationPackageLicenses.map(set => [
		<TableItem>{set.packageName}</TableItem>,
		<TableItem>{set.name}</TableItem>,
		<TableItem addClassName="flex flex-wrap justify-center">
			{
				set.id && (
					<Button
						color="secondary"
						to={AppRoutes.studyingSession.create.url({
							...systemParams,
							setid: [set.id],
						})}
					>
						Lernen
					</Button>
				)
			}
		</TableItem>,
	]), [organizationPackageLicenses, systemParams])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
		</Content>
	)
}

export function CreateStudyingSession(): React.ReactElement {
	const navigate = useNavigate()
	const systemParams = useStudyingSessionParams()

	// use useEffect to prevent call on second re-render
	useEffectAsync('CreateStudyingSession.insertStudyingSession', async () => {
		if (systemParams.setids.length > 0 && isStudySystem(systemParams.system)) {
			const studyingSession = await insertStudyingSession(systemParams)

			await Promise.all(systemParams.setids.map(setid => insertStudyingSessionSet({
				studyingsessionid: studyingSession.id,
				setid,
			})))

			navigate(AppRoutes.studyingSession.study.url(studyingSession.id))
		}
	}, [navigate, systemParams])

	if (isStudySystem(systemParams.system) === false) {
		return <SelectStudySystem />
	}

	if (systemParams.setids.length === 0) {
		return <SelectSets />
	}

	return <></>
}

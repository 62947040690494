import React from 'react'
import { TableProps } from './Table'

export function BigTable({ headers, rows }: TableProps): React.ReactElement {
	return (
		<table className="w-full rounded-lg shadow-md my-5">
			<thead className="text-white">
				<tr className="bg-green/75">
					{headers.map((header, headeri) => <th className="p-3" key={headeri}>
						{header}
					</th>)}
				</tr>
			</thead>
			<tbody>
				{
					rows.length === 0
						? <tr>
							<td colSpan={headers.length} className={`border-grey-light hover:bg-gray-100 p-3 text-center`}>
								Keine Einträge vorhanden
							</td>
						</tr>
						: rows.map((row, rowi) => <tr key={rowi}>
							{
								row.map((column, columni) => <td key={columni} className={`border-grey-light border hover:bg-gray-100 p-3`}>
									{column}
								</td>)
							}
						</tr>)
				}
			</tbody>
		</table>
	)
}

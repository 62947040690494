import { DependencyList, useEffect } from 'react'
import { logErrorFn } from '../monitoring/Monitoring'

type EffectCallback = () => Promise<void>

// there is an interesting use case with isMounted at
// https://github.com/rauldeheer/use-async-effect
export function useEffectAsync(
	errorTitle: string,
	effect: EffectCallback,
	deps?: DependencyList,
): void {
	return useEffect(() => {
		effect().catch(logErrorFn(errorTitle))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps ?? [], errorTitle])
}

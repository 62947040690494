import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	KeyFunctionMap,
	RxCollectionCreator,
} from 'rxdb/dist/types/types'
import {
	databaseCurrent,
} from '../../database/Database'
import {
	loadLastChange,
} from './settingsGql'

export interface LastChange {
	id: string
	createdat: string
}

interface SettingLastChange {
	key: 'lastchange'
	value: undefined | LastChange
}

type Setting =
	| SettingLastChange

interface SettingStaticMethods {
	getLastChange(): Promise<LastChange | null>
	setLastChange(lastChange: LastChange): Promise<void>
}

export type SettingCollection = RxCollection<Setting, unknown, SettingStaticMethods>

export const settingsSchema: RxJsonSchema<Setting> = {
	version: 0,
	primaryKey: 'key',
	type: 'object',
	required: [
		'key',
		'value',
	],
	properties: {
		key: {
			type: 'string',
			maxLength: 255,
		},
		value: {
			type: 'string',
			format: 'date-time',
		},
	},
}

const statics: SettingStaticMethods = {
	async getLastChange(this: SettingCollection) {
		const value = (await this.findOne().where({ key: 'lastchange' }).exec())?.value
		return value ?? null
	},
	async setLastChange(this: SettingCollection, { id, createdat }: LastChange): Promise<void> {
		await this.atomicUpsert({
			key: 'lastchange',
			value: { id, createdat },
		})
	},
}

export const settingsCollection: Record<'settings', RxCollectionCreator> = {
	settings: {
		schema: settingsSchema,
		statics: statics as unknown as KeyFunctionMap,
	},
}

export async function pullSettings(): Promise<void> {
	const db = await databaseCurrent()
	const lastChange = await loadLastChange()
	await db.settings.setLastChange(lastChange)
}

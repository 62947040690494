import React, {
	useMemo,
} from 'react'
import {
	leftJoinObservable,
} from '@hirn.app/shared'
import {
	Link,
} from 'react-router-dom'
import {
	Button,
} from '../../components/Button'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	Content,
} from '../../components/Content'
import {
	EditIcon,
} from '../../icon'
import {
	teamManagerFilter,
} from './teamsDb'
import {
	organizationOwnerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'

export function TeamList(): React.ReactElement {
	const organizationTeams = useObserveDb(
		db => {
			// all organization, where current user is member
			const organizations$ = db.organizations.getWithMembers([getUserIdEnsured()])
			const teams$ = db.teams.getWithTeamMembers()
			const result = leftJoinObservable(
				'organization',
				teams$, 'organizationid',
				organizations$, 'id',
			)
			return result
		}, [],
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Team</TableHeader>,
		<TableHeader addClassName="sm:text-center">Mitglieder</TableHeader>,
		<TableHeader addClassName="sm:text-center">Lernsätze</TableHeader>,
		<TableHeader addClassName="sm:text-center">Organisation</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => organizationTeams.map(team => {
		// to check zero index is enough, because it can be only current user
		const isOrganizationOwner = organizationOwnerFilter(
			team.organization?.organizationmembers[0]?.roles ?? [],
		).length > 0

		// check if current user can manage the team
		const isTeamManager = team.teammembers
			.find(member => member.userid === getUserIdEnsured()
			&& teamManagerFilter(member.roles ?? []).length > 0)

		const canManage = isOrganizationOwner || isTeamManager
		return [
			<TableItem>{team.name}</TableItem>,
			<TableItem addClassName="justify-center flex flex-row">
				<Link className="flex"
					to={AppRoutes.teammember.list.url(team.id)}
					data-test-id="edit-teammembers"
				>
					{team.teammembers.length}
					{canManage
						&& <EditIcon className="flex ml-4 mb-4 w-5 h-5 text-black" />
					}
				</Link>
			</TableItem>,
			<TableItem addClassName="sm:text-center">-</TableItem>,
			<TableItem addClassName="sm:text-center">{team.organization?.name}</TableItem>,
			<TableItem addClassName="flex flex-wrap justify-center">
				{
					canManage
					&& <Button
						color="secondary"
						dataTestId="edit-team"
						to={AppRoutes.team.edit.url(team.id)}
					>
						Bearbeiten
					</Button>
				}
			</TableItem>,
	]
	}), [organizationTeams])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="flex justify-end">
				<Button
					color="secondary"
					to={AppRoutes.outgoinginvitation.list.url()}
					addClassName="mr-2"
					>
					Ausgehende Einladungen ansehen
				</Button>
				<Button
					color="secondary"
					to={AppRoutes.incominginvitation.list.url()}
					addClassName="mr-2"
				>
					Einladungen verwalten
				</Button>
				<Button
					color="primary"
					to={AppRoutes.team.create.url()}
				>
					Team anlegen
				</Button>
			</div>
		</Content>
	)
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export async function logError(message: string, ...args: any): Promise<void> {
	// eslint-disable-next-line no-console
	console.error(message, ...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logErrorFn(breadcrumb: string): (message: string, ...args: any) => Promise<void> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (message: string, ...args: any) => logError(message, {
		breadcrumb,
		args,
	})
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export async function logInfo(message: string, ...args: any): Promise<void> {
	// eslint-disable-next-line no-console
	console.log(message, ...args)
}

import React, {
	useMemo,
} from 'react'
import {
	map,
	of,
} from 'rxjs'
import {
	Link,
} from 'react-router-dom'
import {
	Button,
} from '../../components/Button'
import {
	Content,
} from '../../components/Content'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	organizationPackageManagerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	EditIcon,
} from '../../icon'

export function ViewPackage(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()

	const pakage = useObserveDb(
		db => db.collections.packages.findOne(id).$
			.pipe(map(paka => paka?.toJSON())),
		null,
		[id],
	)

	const userCanManagePackage = useObserveDb(
		db => db.collections.organizationmembers.getWithRoles({
			organizationids: [pakage?.organizationid ?? ''],
			userids: [getUserIdEnsured()],
		}).pipe(map(([organizationmember]) => organizationPackageManagerFilter(
			organizationmember?.roles ?? [],
		).length > 0)),
		false,
		[pakage],
	)

	const userHasLicense = useObserveDb(
		db => db.collections.organizationpackagelicenses.findOne({
			selector: {
				packageid: pakage?.id,
				userid: getUserIdEnsured(),
			},
		}).$.pipe(map(license => !!license)),
		false,
		[pakage?.id, getUserIdEnsured()],
	)

	const packageEntries = useObserveDb(
		db => {
			if (pakage) {
				const sets$ = toJson(db.collections.sets.find({
					selector: {
						packageid: pakage.id,
					},
				}).$)
				return sets$
			}
			return of([])
		},
		[],
		[pakage],
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Modul</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => packageEntries.map(set => [
		<TableItem>{set.name}</TableItem>,
		<TableItem addClassName="cursor-pointer flex flex-wrap justify-center">
			{
				userCanManagePackage
				&& <Button color="secondary" addClassName="m-1" to={AppRoutes.set.edit.url(set.id)}>Bearbeiten</Button>
			}
			{
				userCanManagePackage
				&& <Button color="secondary" addClassName="m-1" to={AppRoutes.question.list.url(set.id)}>Fragen</Button>
			}
			{
				userHasLicense
				&& <Button color="primary" addClassName="m-1" to={AppRoutes.studyingSession.create.url({ setid: [set.id] })}>
					Lernen
				</Button>
			}
		</TableItem>,
	]), [userCanManagePackage, userHasLicense, packageEntries])
	/* eslint-enable react/jsx-key */

	if (pakage === undefined) {
		return <div></div>
	}

	if (pakage === null) {
		// TODO: add proper 404
		return <div>404</div>
	}

	return (
		<Content variant="wide">
			<div>
				Paket {pakage.name}
				<Link
					data-test-id="edit"
					to={AppRoutes.package.edit.url(pakage.id)}
				>
					{userCanManagePackage
						&& <EditIcon className="inline ml-4 w-5 h-5 text-black" />
					}
				</Link>
			</div>
			{
				rows.length > 0
					? <Table headers={headers} rows={rows} />
					: <div className="my-2">Hey, um das Paket mit Leben zu fühlen, fang an Inhalt hinzuzufügen. Füge beispielsweise einen Lernsatz hinzu, um diesen auswendig zu lernen 🧑‍🎓</div>

			}
			<div className="text-right">
				<Button
					color="secondary"
					to={AppRoutes.package.list.url()}
					addClassName="float-left"
				>
					Zurück
				</Button>
				<Button
					color="secondary"
					addClassName="ml-2"
					to={AppRoutes.set.create.url(pakage.id)}
				>
					Lernsatz hinzufügen
				</Button>
			</div>
		</Content>
	)
}

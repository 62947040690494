import React, {
	useMemo,
} from 'react'
import { map } from 'rxjs'
import {
	Button,
} from '../../components/Button'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	Content,
} from '../../components/Content'
import { deleteIncomingMemberInvitation, updateIncomingMemberInvitationState } from './memberInvitationsGql'

export function IncomingInvitationList(): React.ReactElement {
	const userinvitations = useObserveDb(
		db => db.collections.memberinvitations.getMemberInvitations()
			.pipe(map(invitations => invitations
				.filter(invitation => invitation.inviteduserid === getUserIdEnsured()))),
	[])

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Einladung</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => userinvitations.map(invitation => [
		<TableItem>
			<span data-test-id="incominginvitation">
				{[invitation.teamname, invitation.organizationname].filter(Boolean).join(', ')}
			</span>
		</TableItem>,
		<TableItem addClassName="flex flex-wrap justify-center">
			{invitation.state === 'invited' && (
				<span>
					<Button
						color="secondary"
						onClick={async () => {
							await updateIncomingMemberInvitationState(invitation.id, 'blocked')
						}}
						addClassName="mr-4"
					>
						Blockieren
					</Button>
					<Button
						color="secondary"
						onClick={async () => {
							await deleteIncomingMemberInvitation(invitation.id)
						}}
						addClassName="mr-4"
					>
						Ablehnen
					</Button>
					<Button
						color="primary"
						to={AppRoutes.incominginvitation.accept.url(invitation.id)}
					>
						Akzeptieren
					</Button>
				</span>
			)}
			{invitation.state === 'blocked' && <span>Blockiert</span>}
		</TableItem>,
	]), [userinvitations])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="flex justify-end">
				<Button
					color="secondary"
					to={AppRoutes.team.list.url()}
				>
					Abbrechen
				</Button>
			</div>
		</Content>
	)
}

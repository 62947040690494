import { groupBy } from 'remeda'
import {
	MangoQuerySelector,
	RxCollection,
	RxDocument,
	RxJsonSchema,
} from 'rxdb'
import {
	BehaviorSubject,
	Observable,
	Subscription,
	combineLatest,
	distinctUntilChanged,
	map,
	switchMap,
} from 'rxjs'
import { v4 as uuid } from 'uuid'
import { databaseRaw } from './database/Database'
import { toJson } from './utils/RxDbUtils'

export interface OfflineCapable {
	id: string
	offlinestate: 'inserted' | 'updated' | 'deleted' | 'insync'
	updatedat: string
}

export type OfflineEntity<T> = Omit<T, keyof OfflineCapable>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LocalSchema<T extends RxJsonSchema<any>> = T extends RxJsonSchema<infer D>
	? Omit<RxJsonSchema<OfflineEntity<D>>, 'primaryKey'>
	: never

type ExtractFromCollection<T extends RxCollection<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any,
	unknown
>> = T extends RxCollection<
	infer D,
	unknown
> ? D : never

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function offlineCapableSchema<S extends RxJsonSchema<any>>(
	schema: LocalSchema<S>,
): LocalSchema<S> & RxJsonSchema<OfflineCapable> {
	const capableSchema = schema as LocalSchema<S> & RxJsonSchema<OfflineCapable>
	capableSchema.required = [
		...capableSchema.required ?? [],
		'offlinestate',
		'updatedat',
	]
	return {
		...capableSchema,
		primaryKey: 'id',
		properties: {
			...capableSchema.properties,
			id: {
				type: 'string',
				maxLength: 36,
			},
			offlinestate: {
				type: 'string',
			},
			updatedat: {
				type: 'string',
				format: 'date-time',
			},
		},
	}
}

export function createOfflineCapable<T>(entity: T & Partial<OfflineCapable>): T & OfflineCapable {
	return {
		...entity,
		id: uuid(),
		offlinestate: 'inserted',
		updatedat: entity.updatedat ?? new Date().toISOString(),
	}
}

const activeState: OfflineCapable['offlinestate'][] = [
	'inserted',
	'updated',
	'insync',
]

export const activeSelector: MangoQuerySelector<OfflineCapable> = {
	offlinestate: {
		$in: activeState,
	},
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getActiveState<T extends RxCollection<any>>(
	collection: T,
): Observable<ExtractFromCollection<T>[]> {
	return toJson(collection.find({
		selector: activeSelector,
	}).$)
}

function getPendingSync<T extends RxCollection<OfflineCapable>>(
	collection: T,
): Observable<RxDocument<ExtractFromCollection<T> & OfflineCapable>[]> {
	const syncPendingState: OfflineCapable['offlinestate'][] = [
		'inserted',
		'updated',
		'deleted',
	]
	return collection.find({
		selector: {
			offlinestate: {
				$in: syncPendingState,
			},
		},
	}).$ as unknown as Observable<RxDocument<ExtractFromCollection<T> & OfflineCapable>[]>
}

export const lineState$ = new BehaviorSubject<'online' | 'offline'>('offline')

const offlineSubscriptions: Subscription[] = []

export interface StaticOffline<T> {
	handleOffline: (data: {
		inserted: RxDocument<T>[]
		updated: RxDocument<T>[]
		deleted: RxDocument<T>[]
	}) => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subscribeOffline<T extends RxCollection<any, any, StaticOffline<any>>>(
	subscriptions: Subscription[],
	collection: T,
): void {
	const subscription = getPendingSync(collection)
		.pipe(switchMap(async entities => {
			const grouped = groupBy(
				entities,
				entity => entity.offlinestate as OfflineCapable['offlinestate'],
			)

			await collection.handleOffline({
				inserted: grouped.inserted ?? [],
				updated: grouped.updated ?? [],
				deleted: grouped.deleted ?? [],
			})
		}))
		.subscribe()
	subscriptions.push(subscription)
}

export function clearOfflineSubscriptions(): void {
	while (offlineSubscriptions.length) {
		offlineSubscriptions.pop()?.unsubscribe()
	}
}

export function startOfflineHandling(): void {
	combineLatest([
		lineState$,
		databaseRaw(),
	])
		.pipe(
			map(([lineState, db]) => {
				// always unready, if db isn't initalized
				const ready = lineState === 'online' && !!db
				return [ready, db] as const
			}),
			distinctUntilChanged((
				[prevReady, prevDb],
				[currReady, currDb],
			) => prevReady === currReady && prevDb === currDb),
			switchMap(async ([ready, db]) => {
				clearOfflineSubscriptions()
				if (ready && db) {
					[
						db.collections.studyingsessions,
						db.collections.studyingsessionsets,
						db.collections.userquestions,
						db.collections.useranswers,
					].forEach(collection => subscribeOffline(offlineSubscriptions, collection))
				}
			}),
		).subscribe()
}

/**
* Ignores outdated updates. Usually, this isn't a problem,
* but the problem appears in fast paced tests
*/
export function isUpdateValid<P extends OfflineCapable>(
	update: P,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	persistent?: RxDocument<P, any>,
): boolean {
	return !persistent || persistent.updatedat < update.updatedat
}

import React, { Ref } from 'react'
import { Content } from '../components/Content'

interface TermsProps {
	observationRef: Ref<HTMLDivElement>
}

export function Terms({ observationRef }: TermsProps): React.ReactElement {
	return <Content variant="wide"><div ref={observationRef}>Terms</div></Content>
}

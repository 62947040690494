import React, {
 useContext, useEffect, useRef, useState,
} from 'react'
import { logErrorFn } from '../monitoring/Monitoring'
import {
 Database, databaseRaw, dbStateObservable, ensureInitializedDb, pullEverythingProgress,
} from './Database'

// we don't pass undefined with Context down in the tree,
// therefore we are safe here
const DatabaseContext = React.createContext<Database>(undefined as unknown as Database)

export function useDb(): Database {
	return useContext(DatabaseContext)
}

export function DatabaseProvider(
	{ children }: React.PropsWithChildren<unknown>,
): React.ReactElement {
	const [db, setDb] = useState<Database | undefined>()
	const [dbInitialized, setDbInitialized] = useState(false)
	// use native DOM to avoid re-renders
	const progessRef = useRef<HTMLSpanElement>(null)

	useEffect(() => {
		const dbSubscription = databaseRaw().subscribe(setDb)

		const dbStateSubscription = dbStateObservable.subscribe(state => {
			setDbInitialized(state === 'initialized')
		})

		ensureInitializedDb().catch(logErrorFn('DatabaseProvider.ensureInitializedDb'))

		const pullEverythingProgressSubscription = pullEverythingProgress.subscribe(progress => {
			if (progessRef.current) {
				progessRef.current.innerText = `${progress}`
			}
		})
		return () => {
			dbSubscription.unsubscribe()
			dbStateSubscription.unsubscribe()
			pullEverythingProgressSubscription.unsubscribe()
		}
	}, [])

	if (!db || !dbInitialized) {
		// TODO: provide general page for caps
		// TODO: there is an edge case on logout: we unset the db
		return <div data-test-id="db-initialization">Datenbank wird initialisiert: <span ref={progessRef}>0</span>%</div>
	}

	return (
		<DatabaseContext.Provider value={db}>
			{children}
		</DatabaseContext.Provider>
	)
}

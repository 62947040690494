import React, {
	useCallback,
	useEffect,
} from 'react'
import {
	useForm,
} from 'react-hook-form'
import {
	useNavigate,
} from 'react-router-dom'
import {
	Button,
} from '../../components/Button'
import {
	Content,
} from '../../components/Content'
import {
	Frame,
} from '../../components/Frame'
import {
	H1,
} from '../../components/H1'
import {
	Input,
} from '../../components/inputs/Input'
import {
	useDb,
} from '../../database/DatabaseProvider'
import {
	ValidationErrors,
	handleFormValidation,
} from '../../database/validation'
import {
	DeleteIcon,
} from '../../icon'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	usePromiseResult,
} from '../../utils/usePromiseResult'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	deletePackage,
	updatePackage,
} from './packagesGql'

export function EditPackage(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()
	const navigate = useNavigate()
	const db = useDb()
	const pakage = usePromiseResult(
		'EditPackage.package',
		async () => (await db.collections.packages.findOne(id).exec())?.toJSON() ?? null,
		undefined,
		[id],
	)

	const formConfig = {
		defaultValues: {
			name: '',
		},
	}

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		if (pakage) {
			reset(pakage)
		}
	}, [pakage, reset])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success] = await handleFormValidation(
			() => updatePackage(id, data.name),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.package.view.url(id))
		}
	}

	const deletePackageCallback = useCallback(async () => {
		try {
			await deletePackage(id)
			navigate(AppRoutes.package.list.url(), { replace: true })
		} catch (e) {
			if (e instanceof ValidationErrors) {
				const handled = e.errors.find(error => {
					if (error.code === 'foreignKeyConstraint') {
						setError('name', {
							message: error.translatedMessage,
						})
						return true
					}
					return false
				})
				if (handled) {
					return
				}
			}
			throw e
		}
	}, [id, navigate, setError])

	return (
		<Content variant="narrow">
			<Frame>
				<H1>Paket bearbeiten</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<Button
						onClick={deletePackageCallback}
						color="secondary"
						addClassName="float-left"
						dataTestId="delete"
					>
						<DeleteIcon className="w-5 h-5" />
					</Button>
					<div className="w-full text-right">
						<Button
							to={AppRoutes.package.view.url(id)}
							color="secondary"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
							addClassName="ml-2"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

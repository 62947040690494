import { Dispatch, useReducer } from 'react'
import { isFunction } from 'remeda'

// we can think about modeling from -> to states?
type StateProvider<T> =
	| ((state: T) => Partial<T>)
	| Partial<T>

/**
 * useState leads to multiple rerenders, if called multiple times.
 * useStateReducer avoids that. Inspired by:
 * https://dev.to/craigaholliday/using-the-usereducer-hook-in-react-with-typescript-27m1
 */
export function useStateReducer<T>(initialState: T): [
	T,
	Dispatch<StateProvider<T>>,
] {
	return useReducer(
		(state: T, stateProvider: StateProvider<T>) => ({
			...state,
			...isFunction(stateProvider) ? stateProvider(state) : stateProvider,
		}),
		initialState,
	)
}

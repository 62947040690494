import React from 'react'

export function Frame({
	children,
}: Required<React.PropsWithChildren<unknown>>): React.ReactElement {
	return (
		<div className="p-6 bg-white border sm:shadow-md sm:rounded-lg">
			{children}
		</div>
	)
}

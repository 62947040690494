import React, {
	useCallback,
} from 'react'
import {
	useKeycloak,
} from '@react-keycloak/web'
import {
	Link,
} from 'react-router-dom'
import {
	Button,
} from '../components/Button'

interface PublicMenuProps {
	state: 'normal' | 'shrinked'
}

export function PublicMenu({
	state,
}: PublicMenuProps): React.ReactElement {
	const { keycloak } = useKeycloak()
	const login = useCallback(() => keycloak.login(), [keycloak])

	return (
		<div>
			<nav className={`w-full -top-0 text-gray-700 bg-white z-50 ${state === 'normal' ? 'p-3' : 'shadow-sm md:shadow-md py-1 px-3'} transition-all transition-duration-1000`}>
				<div className="flex flex-row mx-auto items-center justify-between">
					<div>
						<Link to="/" className="text-lg font-semibold tracking-widest text-gray-900 focus:text-green hover:text-green">
							<span className="text-green">hirn!</span>app
						</Link>
					</div>
					<Button color="secondary" onClick={login} addClassName={state === 'normal' ? '' : 'py-1'}>
						Anmelden
					</Button>
				</div>
			</nav>
		</div>
	)
}

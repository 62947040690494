import { ensureDefined } from '@hirn.app/shared'
import { GraphQLError } from 'graphql'
import { UseFormSetError } from 'react-hook-form'
import {
	CustomError,
} from 'ts-custom-error'

const checkRequired = new RegExp(`^E432: '(.+?)' is required$`)
const checkTextMinlength = new RegExp(`^E433: '(.+?)' must be at least (\\d+?) characters long$`)
const checkTextMaxlength = new RegExp(`^E437: '(.+?)' must be at most (\\d+?) characters long$`)
const checkNotNullConstraint = new RegExp(`^Not-NULL violation. null value in column "(.+?)" violates not-null constraint$`)
const checkNotNullGqlConstraint = new RegExp(`^expecting a value for non-nullable variable: "(.+?)"$`)
// eslint-disable-next-line max-len
// TODO: test "Foreign key violation. insert or update on table \"studyingsessionsets\" violates foreign key constraint \"studyingsessionsets_studyingsessionid_fkey\""
const checkForeignConstraint = new RegExp(`^Foreign key violation. (.+?) on table "(.+?)" violates foreign key constraint "(.+?)"(:? on table "(.+?)")?$`)

export class ValidationErrors extends CustomError {
	constructor(
		public readonly errors: ErrorMessage[],
	) {
		super(`Validation fail: ${JSON.stringify(errors)}`)
	}
}

interface BaseErrorMessage {
	message: string
	translatedMessage: string
	dataPath: string
}

interface ErrorMessageRequired extends BaseErrorMessage {
	code: 'required'
	params: {
		field: string
	}
}

interface ErrorMessageTextMinLength extends BaseErrorMessage {
	code: 'textMinLength'
	params: {
		field: string
		min: number
	}
}

interface ErrorMessageTextMaxLength extends BaseErrorMessage {
	code: 'textMaxLength'
	params: {
		field: string
		max: number
	}
}

interface ErrorMessageNotNull extends BaseErrorMessage {
	code: 'notNull'
	params: {
		field: string
	}
}

interface ErrorMessageForeignKeyConstraint extends BaseErrorMessage {
	code: 'foreignKeyConstraint'
	params: {
		action: string
		actionTable: string
		constraintName: string
		relationTable: string | undefined
	}
}

interface ErrorMessageUnknown extends BaseErrorMessage {
	code: 'unknown'
	params: {
		message: string
	}
}

export type ErrorMessage =
	| ErrorMessageRequired
	| ErrorMessageTextMinLength
	| ErrorMessageTextMaxLength
	| ErrorMessageNotNull
	| ErrorMessageForeignKeyConstraint
	| ErrorMessageUnknown

const fieldTranslation = {
	name: 'Name',
	content: 'Inhalt',
}

function getFieldName(field: string): string {
	// @ts-expect-error
	const translation = fieldTranslation[field]
	if (translation) {
		return translation
	}
	return field
}

export function getGraphQLErrorMessages(
	errors: readonly GraphQLError[] | undefined = [],
): ErrorMessage[] {
	const parsedErrors: ErrorMessage[] = errors.flatMap((error): ErrorMessage[] => {
		switch (error.message.substring(0, 5)) {
		case 'E432:': {
			const [_, _field] = ensureDefined(checkRequired.exec(error.message))
			const field = ensureDefined(_field)
			return [{
				message: error.message.substring(6),
				translatedMessage: `${getFieldName(field)} ist verpflichtend`,
				dataPath: `/${field}`,
				code: 'required',
				params: {
					field,
				},
			}]
		}
		case 'E433:': {
			const [_, _field, _min] = ensureDefined(checkTextMinlength.exec(error.message))
			const field = ensureDefined(_field)
			const min = ensureDefined(_min)
			return [{
				message: error.message.substring(6),
				translatedMessage: `${getFieldName(field)} muss mindestens ${min} Zeichen lang sein`,
				dataPath: `/${field}`,
				code: 'textMinLength',
				params: {
					field,
					min: parseInt(min, 10),
				},
			}]
		}
		case 'E437:': {
			const [_, _field, _max] = ensureDefined(checkTextMaxlength.exec(error.message))
			const field = ensureDefined(_field)
			const max = ensureDefined(_max)
			return [{
				message: error.message.substring(6),
				translatedMessage: `${getFieldName(field)} darf maximal ${max} Zeichen lang sein`,
				dataPath: `/${field}`,
				code: 'textMaxLength',
				params: {
					field,
					max: parseInt(max, 10),
				},
			}]
		}
		case 'Not-N': {
			const [_, _field] = ensureDefined(checkNotNullConstraint.exec(error.message))
			const field = ensureDefined(_field)
			return [{
				message: `Field '${field}' is required`,
				translatedMessage: `${getFieldName(field)} ist verpflichtend`,
				dataPath: `/${field}`,
				code: 'notNull',
				params: {
					field,
				},
			}]
		}
		case 'expec': {
			const [_, _field] = ensureDefined(checkNotNullGqlConstraint.exec(error.message))
			const field = ensureDefined(_field)
			return [{
				message: `Field '${field}' is required`,
				translatedMessage: `${getFieldName(field)} ist verpflichtend`,
				dataPath: `/${field}`,
				code: 'notNull',
				params: {
					field,
				},
			}]
		}
		case 'Forei': {
			const [
				_,
				_action,
				_actionTable,
				_constraintName,
				relationTable, // is undefined for "insert or update" constraint
				// TODO: the error isn't meaningful, if no match
			] = ensureDefined(checkForeignConstraint.exec(error.message))
			const action = ensureDefined(_action)
			const actionTable = ensureDefined(_actionTable)
			const constraintName = ensureDefined(_constraintName)
			const translatedMessage = {
				teams_organizationid_fkey: `Die Organisation kann nicht gelöscht werden, weil noch Teams existieren`,
				organizationpackages_packageid_fkey: `Das Paket wird mindestens bei einer Organisation verwendet und kann deshalb nicht gelöscht werden`,
			}[constraintName] ?? `Das Objekt kann nicht gelöscht werden. Fehler ${constraintName}`
			return [{
				message: `foreign key constraint ${constraintName} doesn't allow deletion of this entity`,
				translatedMessage,
				dataPath: `/${constraintName}`,
				code: 'foreignKeyConstraint',
				params: {
					action,
					actionTable,
					constraintName,
					relationTable,
				},
			}]
		}
		default: return [{
			message: 'unknown error',
			translatedMessage: `Unbekannter Fehler`,
			dataPath: '/unknown',
			code: 'unknown',
			params: {
				message: error.message,
			},
		}]
		}
	})
	return parsedErrors
}

/**
 * Connect ValidationErrors with react-hook-form
 */
export async function handleFormValidation<R>(
	fn: () => Promise<R>,
	fields: string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setError: UseFormSetError<any>,
): Promise<[true, R] | [false, undefined]> {
	try {
		return [true, await fn()]
	} catch (e) {
		if (e instanceof ValidationErrors) {
			const unhandeled = []
			let atLeastOneHandled = false
			e.errors.forEach(error => {
				if ('field' in error.params && fields.includes(error.params.field)) {
					atLeastOneHandled = true
					setError(error.params.field, {
						message: error.translatedMessage,
					})
				} else {
					unhandeled.push(error)
				}
				// if we have at least one hanelded error,
				// then we display it first and ignore unhandled errors
				if (atLeastOneHandled === false) {
					throw e
				}
			})
		} else {
			throw e
		}
		return [false, undefined]
	}
}

import {
	ensureDefined,
} from '@hirn.app/shared'
import {
	clamp,
	difference,
	prop,
	sortBy,
	takeWhile,
} from 'remeda'

// https://fluent-forever.com/wp-content/uploads/2014/05/LeitnerSchedule.pdf
// https://www.youtube.com/watch?v=TB7sHXSgk6w&t=94s
const leitnerOriginalSchedule = [
	[2, 1],
	[3, 1],
	[2, 1],
	[4, 1],
	[2, 1],
	[3, 1],
	[2, 1],
	[1],
	[2, 1],
	[3, 1],
	[2, 1],
	[5, 1],
	[4, 2, 1],
	[3, 1],
	[2, 1],
	[1],
	[2, 1],
	[3, 1],
	[2, 1],
	[4, 1],
	[2, 1],
	[3, 1],
	[2, 1],
	[6, 1],
	[2, 1],
	[3, 1],
	[2, 1],
	[5, 1],
	[4, 2, 1],
	[3, 1],
	[2, 1],
	[1],
	[2, 1],
	[3, 1],
	[2, 1],
	[4, 1],
	[2, 1],
	[3, 1],
	[2, 1],
	[1],
	[2, 1],
	[3, 1],
	[2, 1],
	[5, 1],
	[4, 2, 1],
	[3, 1],
	[2, 1],
	[1],
	[2, 1],
	[3, 1],
	[2, 1],
	[4, 1],
	[2, 1],
	[3, 1],
	[2, 1],
	[7, 1],
	[2, 1],
	[3, 1],
	[6, 2, 1],
	[5, 1],
	[4, 2, 1],
	[3, 1],
	[2, 1],
	[1],
]

export const studySystems = [
	'leitner',
	'hirn',
] as const

export type StudySystem = typeof studySystems[number]

export function isStudySystem(system: string | null | undefined): system is StudySystem {
	return studySystems.includes(system as StudySystem)
}

const answerTypes = [
	'right',
	'wrong',
] as const

export type AnswerType = typeof answerTypes[number]

// I don't see any shadow here...
// eslint-disable-next-line no-shadow
export enum StudyModeDifficulty {
	Normal = 150,
	Reha = 80,
}

export function isRehaMode(difficulty: number): boolean {
	return difficulty < StudyModeDifficulty.Normal
}

/**
 * offline version of deck calculation.
 * The first element is the newest
 */
export function calcLevel(
	answerHistory: AnswerType[],
	currentLevel = 1,
): number {
	let newLevel = currentLevel
	for (let i = answerHistory.length - 1; i >= 0; i--) {
		const answerType = answerHistory[i]
		if (answerType === 'right') {
			newLevel = clamp(newLevel, { min: 1, max: 7 }) + 1
		} else {
			newLevel = 0
		}
	}
	return newLevel
}

type UserQuestionLight<T> = T & {
	createdat: string
	difficulty: number
	level: number
}

/**
 * offline version of next question determination.
 * It has lower quality than the online version
 */
export function getNextHirnSystemQuestions<T>(
	questions: UserQuestionLight<T>[],
	difficulty: number,
): UserQuestionLight<T>[] {
	const sortedQuestions = sortBy(
		questions,
		({ level }) => level,
		({ createdat }) => createdat,
		Math.random,
	)
	let difficultyPack = difficulty
	return takeWhile(
		sortedQuestions,
		question => {
			difficultyPack -= question.difficulty
			return difficultyPack >= 0
		},
	)
}

/**
 * returns leitner schedule for zero-based learning
 * day / session number. Levels are from 1 to 7
 */
export function getLeitnerSchedule(day: number): number[] {
	const normalizedDay = day % leitnerOriginalSchedule.length
	return ensureDefined(leitnerOriginalSchedule[normalizedDay])
}

type Day = number
type Level = number
/**
 * Determines the order of levels for learning from current session
 */
export function getLeitnerScheduleSequence(
	fromDay: Day,
): [Day, Level[]][] {
	const searchedAlreadyFor: [Day, Level[]][] = []
	for (let day = fromDay;
		day < fromDay + leitnerOriginalSchedule.length;
		day++
	) {
		const levels = getLeitnerSchedule(day)
		const searchForLevels = difference(levels, searchedAlreadyFor.flatMap(prop('1')))
		if (searchForLevels.length > 0) {
			searchedAlreadyFor.push([day, searchForLevels])
		}
	}
	return searchedAlreadyFor
}

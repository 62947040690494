import { RxCollection, RxJsonSchema } from 'rxdb'
import { databaseCurrent } from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { loadUserById, loadUsers } from './usersGql'
import { logError } from '../../monitoring/Monitoring'

export interface User {
	id: string
	email: string
}

interface StaticMethods {
	getById(id: string): Promise<User | null>
}

export type UserCollection = RxCollection<User, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: UserCollection, id: string): Promise<User | null> {
		const user = await this.findOne(id).exec()
		return user?.toJSON() ?? null
	},
}

export const usersSchema: RxJsonSchema<User> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'email',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		email: {
			type: 'string',
			maxLength: 200,
		},
	},
	indexes: ['email'],
}

export const userCollection: Record<'users', RxCollectionCreator<StaticMethods>> = {
	users: {
		schema: usersSchema,
		statics,
	},
}

export async function pullUsers(): Promise<void> {
	const users = await loadUsers()
	const db = await databaseCurrent()
	await db.users.bulkInsert(users)
}

export async function pullUser(id: string): Promise<void> {
	const user = await loadUserById(id)
	if (user) {
		const db = await databaseCurrent()
		await db.users.atomicUpsert(user)
	} else {
		await logError(`pullUser(${id}) returns ${user}`)
	}
}

export async function handleInsertUser(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const userUnknown = await db.collections.users.getById(id) === null
	if (userUnknown) {
		await pullUser(id)
	}
}

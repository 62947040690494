import {
	outdent,
} from 'outdent'
import {
	query,
} from '../../database/GraphQl'
import {
	OrganizationPackage,
} from './organizationpackagesDb'

interface Response {
	organizationpackages: OrganizationPackage[]
}

export async function loadOrganizationPackages(): Promise<OrganizationPackage[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationPackages {
				organizationpackages {
					id
					organizationid
					packageid
					licensescount
				}
			}`,
	})
	return result.organizationpackages
}

export async function loadOrganizationPackageById(id: string): Promise<OrganizationPackage | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationPackage($ids: [uuid!]!) {
				organizationpackages(where: {id: {_in: $ids}}) {
					id
					organizationid
					packageid
					licensescount
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.organizationpackages[0] ?? null
}

import {
	Client,
	ClientOptions,
	createClient,
} from 'graphql-ws'

export interface RestartableClient extends Client {
	restart(): void
}

// https://github.com/enisdenjo/graphql-ws#graceful-restart
export function createRestartableClient(options: ClientOptions): RestartableClient {
	let restartRequested = false
	let restart = () => {
		restartRequested = true
	}

	const client = createClient({
		...options,
		on: {
			...options.on,
			opened: socket => {
				const webSocket = socket as WebSocket
				options.on?.opened?.(socket)

				restart = () => {
					if (webSocket.readyState === WebSocket.OPEN) {
						// if the socket is still open for the restart, do the restart
						webSocket.close(4205, 'Client Restart')
					} else {
						// otherwise the socket might've closed, indicate that you want
						// a restart on the next opened event
						restartRequested = true
					}
				}

				// just in case you were eager to restart
				if (restartRequested) {
					restartRequested = false
					restart()
				}
			},
		},
	})

	return {
		...client,
		restart: () => restart(),
	}
}

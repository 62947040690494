import React from 'react'
import {
	createRoot,
} from 'react-dom/client'
import Div100vh from 'react-div-100vh'
import {
	ensureDefined,
} from '@hirn.app/shared'
import {
	App,
} from './App'
import {
	AuthProvider,
} from './security/AuthProvider'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(ensureDefined(container))
root.render(
	<Div100vh>
		<AuthProvider>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</AuthProvider>
	</Div100vh>,
)

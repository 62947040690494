import React, { ErrorInfo } from 'react'
import { logError } from '../monitoring/Monitoring'
import { Button } from './Button'

interface State {
	hasError: boolean
}

// see https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<{ children: React.ReactNode }, State> {
	state: State = {
		hasError: false,
	}

	// eslint-disable-next-line max-len
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
	static getDerivedStateFromError(...error: any): { hasError: boolean } {
		// eslint-disable-next-line no-console
		console.log('getDerivedStateFromError', error)
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		if (super.componentDidCatch) {
			super.componentDidCatch(error, errorInfo)
		}
		// You can also log the error to an error reporting service
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		logError('ErrorBoundary.componentDidCatch', error, errorInfo)
	}

	render(): JSX.Element {
		if (this.state.hasError) {
			return (
				<div>
					<h1>Something went wrong.</h1>
					<div><Button color="secondary" onClick={() => this.setState({ hasError: false })}>Close</Button></div>
					<div><Button color="secondary" onClick={() => window.location.reload()}>Refresh</Button></div>
				</div>
			)
		}

		return <>{this.props.children}</>
	}
}

import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { MemberInvitation } from './memberInvitationsDb'

interface Response {
	memberinvitations: MemberInvitation[]
}

interface AcceptInvitationResponse {
	accept_invitation: {
		organizationid: string
		teamid?: string
	}
}

interface InvitationDetailsResponse {
	invitation_details: {
		organizationid: string
		teamid?: string
	}
}

export async function loadMemberInvitations(): Promise<MemberInvitation[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadMemberInvitations {
				memberinvitations {
					id
					inviteduserid
					state
					email
					organizationid
					organizationname
					teamid
					teamname
				}
			}
		}`,
	})
	return result.memberinvitations
}

export async function loadMemberInvitationById(
	id: string,
): Promise<MemberInvitation | null> {
	const result = await query<Response>({
		query: outdent`
		query LoadInvitation($ids: [uuid!]!) {
			memberinvitations(where: {
				id: {_in: $ids},
			}) {
				id
				inviteduserid
				state
				email
				organizationid
				organizationname
				teamid
				teamname
			}
		}`,
		variables: {
			ids: [id],
		},
	})
	return result.memberinvitations[0] ?? null
}

export async function loadInvitationByEmailAndByTeamId(
	email: string,
	teamid: string,
): Promise<MemberInvitation | null> {
	const result = await query<Response>({
		query: outdent`
		query LoadInvitations($email: String!, $teamid: uuid!) {
			memberinvitations(where: {
				email: {_eq: $email},
				teamid: {_eq: $teamid},
			}) {
				state
			}
		}`,
		variables: {
			teamid,
			email,
		},
	})
	return result.memberinvitations[0] ?? null
}

export async function loadInvitationByEmailAndByOrganizationId(
	email: string,
	organizationid: string,
): Promise<MemberInvitation | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadInvitation($email: String!, $organizationid: uuid!) {
				memberinvitations(where: {
					email: {_eq: $email},
					organizationid: {_eq: $organizationid},
					teamid: {_is_null: true}
				}) {
					state
				}
			}`,
		variables: {
			email,
			organizationid,
		},
	})
	return result.memberinvitations[0] ?? null
}

export async function acceptInvitation(
	invitationid: string,
): Promise<{ organizationid: string; teamid?: string}> {
	const result = await query<AcceptInvitationResponse>({
		query: outdent`
			mutation acceptInvitation($invitationid: String!) {
				accept_invitation(invitationid: $invitationid) {
					organizationid,
					teamid,
				}
			}`,
		variables: {
			invitationid,
		},
	})
	const { teamid, organizationid } = result.accept_invitation
	return {
		organizationid,
		teamid,
	}
}

export async function loadInvitationDetails(
	invitationid: string,
): Promise<{ organizationid: string; teamid?: string}> {
	const result = await query<InvitationDetailsResponse>({
		query: outdent`
			mutation userInvitationList($invitationid: String!) {
				invitation_details(invitationid: $invitationid) {
					organizationid,
					teamid,
				}
			}`,
		variables: {
			invitationid,
		},
	})
	const { teamid, organizationid } = result.invitation_details
	return {
		organizationid,
		teamid,
	}
}

export async function updateIncomingMemberInvitationState(
	id: string,
	state: 'blocked',
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdateIncomingMemberInvitationState($id: uuid!, $state: String!) {
				update_memberinvitation(pk_columns: {id: $id}, _set: {state: $state}) {
					state
				}
			}`,
		variables: {
			id,
			state,
		},
	})
}

export async function deleteIncomingMemberInvitation(
	id: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteIncomingMemberInvitation($id: uuid!) {
				delete_memberinvitation(id: $id) {
					id
				}
			}`,
		variables: {
			id,
		},
	})
}

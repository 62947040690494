import { leftJoinAggObservable } from '@hirn.app/shared'
import { RxCollection, RxJsonSchema } from 'rxdb'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import {
	DbIdChangesImmutable, database, databaseCurrent,
} from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { toJson } from '../../utils/RxDbUtils'
import { handleInsertTeam } from '../team/teamsDb'
import { TeamMemberRole, TeamMemberRoleName, handleDeleteTeamMemberRolesByTeamMemberId } from '../teammemberrole/teamMemberRolesDb'
import { User } from '../user/usersDb'
import { loadTeamMemberById, loadTeamMembers } from './teamMembersGql'

type TeamMembersChangeType = DbIdChangesImmutable<'teammembers'>

export type ChangeTeamMembers = TeamMembersChangeType['All']

export interface TeamMember {
	id: string
	userid: string
	teamid: string
}

export interface TeamMemberWithRoles extends TeamMember {
	roles?: TeamMemberRole[]
	user?: User
}

export interface TeamMemberWithRoleNames extends TeamMember {
	roles: TeamMemberRoleName[]
	user?: User
}

interface StaticMethods {
	getById(id: string): Promise<TeamMember | null>
	getWithRolesName(): Observable<TeamMemberWithRoleNames[]>
	getByTeamIdWithMemberRoles(teamid: string): Observable<TeamMemberWithRoles[]>
}

export type TeamMemberCollection = RxCollection<
	TeamMember,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: TeamMemberCollection,
		id: string,
	): Promise<TeamMember | null> {
		const teamMember = await this.findOne(id).exec()
		return teamMember?.toJSON() as TeamMember ?? null
	},
	getWithRolesName(
		this: TeamMemberCollection,
	): Observable<TeamMemberWithRoleNames[]> {
		const teamMemberRolesObserver = database()
			.pipe(switchMap(db => {
				const teamMemberRoles = toJson(db.collections.teammemberroles
					.find().$)
				return teamMemberRoles
			}))

		const teamMembersObserver = toJson(this.find().$)

		const membersWithRolesName = leftJoinAggObservable(
			'roles',
			teamMembersObserver, 'id',
			teamMemberRolesObserver, 'teammemberid',
		).pipe(map(members => members.map(({ roles, ...member }) => ({
			...member,
			roles: roles.map(role => role.name),
		}))))
		return membersWithRolesName
	},
	getByTeamIdWithMemberRoles(
		this: TeamMemberCollection,
		teamid: string,
	): Observable<TeamMemberWithRoles[]> {
		const teamMemberRolesObserver = database()
			.pipe(switchMap(db => {
				const teamMemberRoles = toJson(db.collections.teammemberroles
					.find().$)
				return teamMemberRoles
			}))

		const teamMembersObserver: Observable<TeamMember[]> = toJson(
			this.find({
				selector: {
					teamid: {
						$eq: teamid,
					},
				},
			}).sort({ teamid: 'asc' }).$,
		)

		return leftJoinAggObservable(
			'roles',
			teamMembersObserver, 'id',
			teamMemberRolesObserver, 'teammemberid',
		)
	},
}

export const teamMembersSchema: RxJsonSchema<TeamMember> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'userid',
		'teamid',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		userid: {
			type: 'string',
			maxLength: 36,
		},
		teamid: {
			type: 'string',
			maxLength: 36,
		},
	},
	indexes: ['userid', 'teamid'],
}

export const teamMemberCollection: Record<string, RxCollectionCreator<StaticMethods>> = {
	teammembers: {
		schema: teamMembersSchema,
		statics,
	},
}

export async function pullTeamMembers(): Promise<void> {
	const teamMembers = await loadTeamMembers()
	const db = await databaseCurrent()
	await db.teammembers.bulkInsert(teamMembers)
}

export async function pullTeamMember(id: string): Promise<TeamMember | null> {
	const teamMember = await loadTeamMemberById(id)
	if (teamMember) {
		const db = await databaseCurrent()
		await db.teammembers.atomicUpsert(teamMember)
	} else {
		await logError(`pullTeamMember(${id}) returns ${teamMember}`)
	}
	return teamMember
}

export async function handleInsertTeamMember(id: string): Promise<void> {
	const db = await databaseCurrent()
	const teamMemberUnknown = await db.collections.teammembers.getById(id) === null
	if (teamMemberUnknown) {
		const teamMember = await pullTeamMember(id)
		if (teamMember) {
			// if team isn't found, then the current user
			// gained permission to access the team
			const teamInserted = await handleInsertTeam(teamMember.teamid)
			if (teamInserted) {
				await pullTeamMembers()
			}
		}
	}
}

export async function handleInsertTeamMembersChange(
	change: TeamMembersChangeType['Insert'],
): Promise<void> {
	await handleInsertTeamMember(change.payload.id)
}

export async function handleDeleteTeamMembersByTeam(
	teamid: string,
): Promise<void> {
	const db = await databaseCurrent()
	const teamMembers = await db.collections.teammembers
		.find({
			selector: {
				teamid,
			},
		}).exec()

	await Promise.all(teamMembers.map(async teamMember => {
		await handleDeleteTeamMemberRolesByTeamMemberId(teamMember.id)
		await teamMember.remove()
	}))
}

export async function handleDeleteTeamMembersChange(
	change: TeamMembersChangeType['Delete'],
): Promise<void> {
	const db = await databaseCurrent()
	const teamMember = await db.collections.teammembers.findOne(change.payload.id).exec()
	if (teamMember) {
		await handleDeleteTeamMemberRolesByTeamMemberId(teamMember.id)
		await teamMember.remove()
	}
}

import React, {
	useCallback,
	useMemo,
} from 'react'
import {
	map,
} from 'rxjs'
import {
	ensureDefined,
	leftJoinObservable,
} from '@hirn.app/shared'
import {
	Button,
} from '../../components/Button'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	Content,
} from '../../components/Content'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	teamMemberRoleNames,
} from '../teammemberrole/teamMemberRolesDb'
import {
	Multiselect,
} from '../../components/Multiselect'
import {
	createTeamMemberRole,
	deleteTeamMemberRole,
} from '../teammemberrole/teamMemberRolesGql'
import {
	deleteTeammember,
} from './teamMembersGql'
import {
	H1,
} from '../../components/H1'
import {
	TeamLabel,
} from '../team/TeamLabel'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	teamManagerFilter,
} from '../team/teamsDb'
import {
	organizationOwnerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'

export function TeamMemberList(): React.ReactElement {
	const { teamid } = useParamsDefined<'teamid'>()

	const currentUserCanManageTeam = useObserveDb(
		db => db.collections.organizationmemberroles
			.getRoleNamesByTeamIdAndUserId(teamid, getUserIdEnsured())
			.pipe(map(roleName => organizationOwnerFilter(roleName).length > 0)),
		false,
		[teamid, getUserIdEnsured()],
	)

	const teamMembers = useObserveDb(
		db => {
			const teammembers = db.collections.teammembers.getByTeamIdWithMemberRoles(teamid)

			const users = toJson(db.collections.users.find().$)
			const members = leftJoinObservable(
				'user',
				teammembers, 'userid',
				users, 'id',
			)
			return members
		},
		[], [teamid],
	)

	// check if current user is a teammember and can manage the team
	const currentUserRolenames = teamMembers
		.find(member => member.userid === getUserIdEnsured())?.roles?.map(role => role.name) ?? []
	const canManageTeam = teamManagerFilter(currentUserRolenames).length > 0

	const currentUserCanManageMembers = currentUserCanManageTeam || canManageTeam

	const removeMember = useCallback((memberid: string) => () => deleteTeammember(memberid), [])

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Mitglieder</TableHeader>,
		<TableHeader addClassName="sm:text-center">Berechtigungen</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const teamMemberRows = useMemo(() => teamMembers.map(member => [
		<TableItem>{member.user?.email}</TableItem>,
		<TableItem>
			<Multiselect
				items={
					teamMemberRoleNames
						.map(roleName => {
							const foundTeamRole = (member?.roles ?? []).find(role => role.name === roleName)
							return {
								selected: !!foundTeamRole,
								removeable: true,
								key: {
									id: foundTeamRole?.id,
									roleName,
								},
								label: roleName,
							}
						})
				}
				addItem={item => createTeamMemberRole(item.key.roleName, member.id)}
				removeItem={item => deleteTeamMemberRole(ensureDefined(item.key.id))}
				editable={currentUserCanManageMembers}
			/>
		</TableItem>,
		<TableItem addClassName="cursor-pointer flex flex-wrap justify-center">
			{currentUserCanManageMembers
				&& <Button
					color="secondary"
					onClick={removeMember(member.id)}
				>
					Entfernen
				</Button>
			}
		</TableItem>,
	]), [teamMembers, removeMember, currentUserCanManageMembers])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<H1>
				Team:&nbsp;<TeamLabel teamid={teamid} />
			</H1>
			<Table headers={headers} rows={teamMemberRows} />
			<div className="flex justify-center text-right">
				<div className="container">
					<Button
						to={AppRoutes.team.list.url()}
						color="secondary"
						addClassName="mr-2"
					>
						Abbrechen
					</Button>
					{currentUserCanManageMembers
						&& <Button
							to={AppRoutes.teammember.invitation.url(teamid)}
							color="primary"
						>
							Mitglieder einladen
						</Button>
					}
				</div>
			</div>
		</Content>
	)
}

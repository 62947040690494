import {
	outdent,
} from 'outdent'
import {
	query,
} from '../../database/GraphQl'
import {
	OrganizationPackageLicense,
} from './organizationpackagelicensesDb'

interface Response {
	organizationpackagelicenses: OrganizationPackageLicense[]
}

export async function loadOrganizationPackageLicenses(): Promise<OrganizationPackageLicense[]> {
	const response = await query<Response>({
		query: outdent`
			query LoadOrganizationPackageLicenses {
				organizationpackagelicenses {
					id
					organizationid
					packageid
					userid
				}
			}`,
	})
	return response.organizationpackagelicenses
}

export async function loadOrganizationPackageLicenseById(
	id: string,
): Promise<OrganizationPackageLicense | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationPackageLicense($ids: [uuid!]!) {
				organizationpackagelicenses(where: {id: {_in: $ids}}) {
					id
					organizationid
					packageid
					userid
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.organizationpackagelicenses[0] ?? null
}

import {
 MutableRefObject, useCallback, useRef, useState,
} from 'react'

interface UseIsVisibleOnScroll {
	atTop: boolean
	scrollRef: (node: HTMLDivElement) => void
	observationRef: (node: HTMLDivElement) => void
}

// https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
export function useIsVisibleOnScroll(): UseIsVisibleOnScroll {
	const [atTop, setAtTop] = useState(true)
	const scrollRef = useRef<HTMLDivElement>(null)
	const observationReferenceRef = useRef(null) as MutableRefObject<HTMLDivElement | null>
	const intersectionObserver = useRef(null) as MutableRefObject<IntersectionObserver | null>
	const setObservationRef = useCallback<(node: HTMLDivElement) => void>(node => {
		if (observationReferenceRef.current) {
			intersectionObserver.current?.unobserve(observationReferenceRef.current)
		}
		if (node) {
			intersectionObserver.current?.observe(node)
		}
		observationReferenceRef.current = node
	}, [])

	const setScrollRef = useCallback<(node: HTMLDivElement) => void>(node => {
		intersectionObserver.current?.disconnect()
		if (node) {
			intersectionObserver.current = new IntersectionObserver(
				([entry]) => setAtTop(!!entry?.isIntersecting),
				{
					root: scrollRef.current,
					threshold: 1,
				},
			)
			if (observationReferenceRef.current) {
				intersectionObserver.current.observe(observationReferenceRef.current)
			}
		}
	}, [])

	return {
		atTop,
		scrollRef: setScrollRef,
		observationRef: setObservationRef,
	}
}

import React, {
 Ref,
} from 'react'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../backoffice/AppRoutes'
import { Benefit, BenefitImage } from '../components/Benefit'
import { Button } from '../components/Button'
import { FeatureDescription, PriceCard } from '../components/PriceCard'
import { Typingwords } from './Typingwords'

const slanted = { clipPath: 'polygon(20% 0, 100% 0%, 100% 100%, 0 100%)' }

const HirnApp = <><span className="text-green">hirn!</span>app</>

interface StartpageProps {
	observationRef: Ref<HTMLDivElement>
}

export function Startpage({ observationRef }: StartpageProps): React.ReactElement {
	return (
		<div>
			<section ref={observationRef} className="flex px-2 lg:px-24 md:pt-14 border border-gray-100">
				<div className="flex items-center text-center lg:text-left lg:w-1/3 px-8">
					<div>
						<h2 className="text-2xl font-semibold text-gray-800">
							<span className="md:text-4xl">Sei schlauer als die anderen&nbsp;</span>
							<span className="text-green md:text-4xl whitespace-nowrap">
								<Typingwords />
							</span>
						</h2>
						<p className="mt-2 text-sm text-gray-500 md:text-base">
							Hebe dein Lernniveau mit {HirnApp}-System auf die nächste Ebene!
						</p>
						<div className="flex justify-center lg:justify-start mt-6">
							<Button color="primary">
								Get Started
							</Button>
							<span className="ml-6">
								<Button color="secondary">
									Learn More
								</Button>
							</span>
						</div>
					</div>
				</div>
				<div className="hidden lg:block lg:w-2/3 z-0" >
					<div className="h-full">
						<video
							autoPlay
							loop
							muted
							className="w-full h-full object-cover"
							style={slanted}
						>
							<source src="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/istockphoto-1015038498-640_adpp_is.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>

			{/* benefits */}
			<section className="px-2 lg:px-24 pt-16 md:pt-32">
				<div className="text-center text-xl text-gray-700">
					<h1 className="text-3xl md:text-5xl tracking-wide font-semibold">
						Deine Vorteile
					</h1>
					<p className="pt-6 px-8 w-full">
						Wissen ist ein Asset, ein Vermögen. Anwendung von Wissen ist das, was die Besten
						von anderen unterscheidet.</p>
					<p className="pt-4 px-8 w-full text-2xl font-semibold">Was willst du in deinem Leben erreichen?</p>
					<div className="pt-2 px-8 sm:px-24 text-base">
						<ul className="text-left list-inside list-disc">
							<li>Hervorragenden Abschluss - einen Türöffner für spannende Berufe?</li>
							<li>Besseres Gehalt durch Kompetenz?</li>
							<li>Schnelle Resultate?</li>
							<li>Zu den Besten gehören?</li>
						</ul>
					</div>
					<p className="py-6 px-8 w-full">Egal was du vor hast, {HirnApp} unterstützt dich dabei! Wie? Lese weiter!
					</p>
				</div>

				{/* benefit 1 */}
				<div className="flex flex-wrap lg:m-20 sm:m-8">
					<BenefitImage imagesrc="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/cute-little-child-wearing-glasses-600w-1651737364.png" />
					<Benefit
						title={<>Schlaues Lernen mit {HirnApp}-System</>}
					>
						In der Premium-Version steht dir unsere propriertäre Lern-Technologie zur Verfügung.
						Dieses leistungstarke System hat nur ein Ziel: Dich zu den <b>Besten</b> zu entwicklen
						und das in möglichst kurzer Zeit! Das adaptive System passt den Lernschwierigkeitsgrad
						automatisch an deine Bedürfnisse an. Egal, ob du Top-Checker/in bist oder schon immer
						Schwierigkeiten mit Lernen hattest - das {HirnApp}-System ist dein persönliches Coach!
					</Benefit>
				</div>
				<hr className="bg-gradient-to-r from-green-light via-green-dark to-green-light h-0.5 my-8"></hr>

				{/* benefit 2 */}
				<div className="flex flex-wrap lg:m-20 sm:m-8 p-4">
					<Benefit
						title={<>Willkommen im Club der Besten</>}
					>
						Fordere deine Familie, Freunde, Kommilitonen oder Kollegen in einer Lerngruppe heraus!
						Gemeinsam kommt man schneller zum Ziel und es macht noch mehr Spaß!
					</Benefit>
					<BenefitImage imagesrc="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/train-your-brain-cartoon-flat-600w-705401506.png" />
				</div>
				<hr className="bg-gradient-to-r from-green-light via-green-dark to-green-light h-0.5 my-8"></hr>

				{/* benefit 3 */}
				<div className="flex flex-wrap lg:m-20 sm:m-8">
					<BenefitImage imagesrc="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/stock-vector-rocket-launch-boost-online-app-on-mobile-phone-vector-smartphone-digital-fast-quick-growing-1838413774.jpg" />
					<Benefit
						title={<>Starte sofort mit dem Marktplatz</>}
					>
						Erstelle eigene Lernsätze oder bediene dich auf dem Marktplatz! Damit kannst du sofort
						loslegen, dir Wissen anzueignen. Unregelmäßige Verben für die Schule?
						Einwandsbehandlung im Vertrieb? Finde passende Lernsätze für deine Lebenssituation!
					</Benefit>
				</div>
				<hr className="bg-gradient-to-r from-green-light via-green-dark to-green-light h-0.5 my-8"></hr>

				{/* benefit 4 */}
				<div className="flex flex-wrap lg:m-20 sm:m-8">
					<Benefit
						title={<>100% save in Germany</>}
					>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati
						vel soluta dolore id nesciunt eum nam ipsam, eveniet cupiditate sint
						veritatis harum odit. Iste dignissimos, ad provident nulla
						voluptatum ut.
					</Benefit>
					<BenefitImage imagesrc="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/internet-security-crime-concept-icon-600w-379655164.png" />
				</div>

			</section>

			{/* pricing */}
			<section className="pt-16 lg:pt-32 lg:px-24 px-2">
				<div className="text-center font-semibold">
					<h1 className="text-3xl md:text-5xl tracking-wide">
						Investiere in deine Gegenwart und Zukunft
					</h1>
					<p className="pt-6 text-xl text-gray-700 font-normal w-full px-8 md:w-full">
						Entscheide selbst, wie du am Besten deine Ziele erreichst - mit der Starter-Variante
						oder der Premium-Variante für schnellere Ergebnisse! Bist du bereit für die nächste
						Lern-Ebene?
					</p>
				</div>
				<div className="flex flex-wrap lg:m-20 sm:m-8 p-4">
					<div className="w-full lg:w-1/3 md:w-1/2">
						<PriceCard
							styledCard="xl:mr-6 md:mr-6 my-8"
							header="Starter"
							headerBorderBottomColor="border-green-light"
							price={0.00}
							styledPrice="text-green"
							featureDescriptions={[
								'Eigene Lernsätze erstellen',
								'Leitner-Lernsystem',
								'Multiple-Choice Fragen',
								'Lernen in Lerngruppen',
								'Leadership-Board',
								'Tagging-System',
								'Individueller Lernplan',
								'Zugang zum Marktplatz',
							].map((feature, key) => <FeatureDescription key={key}>
								{feature}
							</FeatureDescription>)}
							styledButton="bg-green-light/50 hover:bg-green-light"
							buttonLabel="Schlauer werden"
						/>
					</div>

					{/* Card in the middle */}
					<div className="w-full lg:w-1/3 md:w-1/2">
						<PriceCard
							styledCard="lg:mx-3 md:ml-6 my-8"
							header="Premium"
							headerBorderBottomColor="border-green"
							price={0.20}
							styledPrice="text-green"
							featureDescriptions={[
								'Alle Funktionen von Starter',
								<>{HirnApp}-Lernsystem für beste Resultate</>,
								'Erweitertes Eingabeset für mehr Flexibilität',
								'Latex-Formeln für Hardcore-Mathe und Physik',
								'Mehrfachauswahl bei Multiple-Choice Fragen',
							].map((feature, key) => <FeatureDescription key={key}>
								{feature}
							</FeatureDescription>)}
							styledButton="bg-green/75 hover:bg-green"
							buttonLabel="Get started"
						/>
					</div>

					<div className="w-full lg:w-1/3">
						<PriceCard
							styledCard="lg:ml-6 lg:my-8 my-6"
							header="Professional"
							headerBorderBottomColor="border-green-dark"
							movingWords
							featureDescriptions={[
								'Individuelle Funktionspakete',
								'Lernkarten mit einer Feedbackfunktion',
								'Kommerzieller Zugang zum Marktplatz',
							].map((feature, key) => <FeatureDescription key={key}>
								{feature}
							</FeatureDescription>)}
							styledButton="bg-green-dark/90 hover:bg-green-dark text-white"
							buttonLabel="Contact us"
						/>
					</div>
				</div>
			</section>

			<footer className="w-full flex flex-col sm:px-8 pt-16">
				<hr className="bg-gradient-to-r from-green-light via-green-dark to-green-light h-0.5"></hr>
				<div className="flex flex-row items-center justify-between p-4">
					<Link to="/" className="text-gray-900 text-lg tracking-widest focus:text-green hover:text-green">
						©
						<span className="text-green">hirn!</span>
						app <span className="text-xs tracking-normal">{new Date().getFullYear()}</span>
					</Link>
					<div className="flex-col text-sm md:flex md:flex-row justify-end">
						<Link className="flex flex-row focus:text-green hover:text-green pr-2" to={AppRoutes.common.terms.url()}>
							ABG
						</Link>
						<Link className="flex flex-row focus:text-green hover:text-green pr-2" to={AppRoutes.common.terms.url()}>
							Datenstutz
						</Link>
						<Link className="flex flex-row items-start bg-transparent focus:text-green hover:text-green" to={AppRoutes.common.imprint.url()}>
							Impressum
						</Link>
					</div>
				</div>
			</footer>
		</div>
	)
}

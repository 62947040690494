import { outdent } from 'outdent'
import { omit } from 'remeda'
import { query } from '../../database/GraphQl'
import { StudyingSessionSet } from './studyingsessionsetsDb'

function fixStudyingSessionSet(studyingSession: StudyingSessionSet): StudyingSessionSet {
	const fixed: StudyingSessionSet = {
		...studyingSession,
		offlinestate: 'insync',
		// there is no need for updatedat column in database,
		// because `studyingsessionsets` can be only
		// added or removed
		updatedat: new Date().toISOString(),
	}
	return fixed
}

export async function loadStudyingSessionSets(): Promise<StudyingSessionSet[]> {
	const result = await query<{ studyingsessionsets: StudyingSessionSet[] }>({
		query: outdent`
			query LoadStudyingSessionSets {
				studyingsessionsets {
					id
					studyingsessionid
					setid
				}
			}`,
	})
	return result.studyingsessionsets.map(fixStudyingSessionSet)
}

export async function loadStudyingSessionSetById(id: string): Promise<StudyingSessionSet | null> {
	const result = await query<{ studyingsessionsets: StudyingSessionSet[] }>({
		query: outdent`
			query LoadStudyingSessionSet($ids: [uuid!]!) {
				studyingsessionsets(where: {id: {_in: $ids}}) {
					id
					studyingsessionid
					setid
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.studyingsessionsets.map(fixStudyingSessionSet)[0] ?? null
}

export async function insertStudyingSessionSetGql(
	studyingSessionSet: StudyingSessionSet,
): Promise<StudyingSessionSet> {
	const object = omit(studyingSessionSet, [
		'offlinestate',
		'updatedat',
	])

	const result = await query<{ insert_studyingsessionset: StudyingSessionSet }>({
		query: outdent`
			mutation InsertStudyingSessionSet($object: studyingsessionsets_insert_input!) {
				insert_studyingsessionset(object: $object) {
					id
					studyingsessionid
					setid
				}
			}`,
		variables: {
			object,
		},
	})
	return fixStudyingSessionSet(result.insert_studyingsessionset)
}

export async function deleteStudyingSessionSetGql(id: string): Promise<void> {
	await query<void>({
		query: outdent`
			mutation DeleteStudyingSessionSet($id: uuid!) {
				delete_studyingsessionset(id: $id) {
					id
				}
			}`,
		variables: {
			id,
		},
	})
}

import {
	outdent,
} from 'outdent'
import {
	query,
} from '../../database/GraphQl'
import {
	LastChange,
} from './settingsDb'

interface Response {
	updates: {
		id: string
		createdat: string
	}[]
}

export async function loadLastChange(): Promise<LastChange> {
	const result = await query<Response>({
		query: outdent`
			query LoadLastChange {
				updates(limit: 1, order_by: [{createdat: desc}, {id: desc}]) {
					id
					createdat
				}
			}`,
	})
	const lastChange = result.updates[0]
	return lastChange ?? {
		id: '00000000-0000-0000-0000-000000000000',
		createdat: new Date('2000-01-01T00:00:00+00:00').toISOString(),
	}
}

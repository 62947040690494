import { DependencyList, useEffect, useState } from 'react'
import { Observable } from 'rxjs'
import { Database } from '../database/Database'
import { useDb } from '../database/DatabaseProvider'

export function useObserveDb<S>(
	effect: (db: Database) => Observable<S>,
	initialState: S | (() => S),
	deps: DependencyList = [],
): S {
	const db = useDb()
	const [state, setState] = useState<S>(initialState)

	useEffect(() => {
		const subscription = effect(db).subscribe(result => {
			setState(result)
		})
		return () => subscription.unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, db])

	return state
}

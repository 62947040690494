import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
} from 'rxjs'
import {
	DbIdChanges,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	deleteSet,
} from '../set/setsDb'
import {
	loadPackageById,
	loadPackages,
} from './packagesGql'

type PackagesChangeType = DbIdChanges<'packages'>
export type ChangePackages = PackagesChangeType['All']

export interface Package {
	id: string
	organizationid: string
	organizationname: string
	name: string
}

interface StaticMethods {
	getById(id: string): Promise<Package | null>
	getByIds(ids: string[]): Observable<Package[]>
}

export type PackageCollection = RxCollection<Package, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: PackageCollection, id: string): Promise<Package | null> {
		const pakage = await this.findOne(id).exec()
		return pakage?.toJSON() ?? null
	},
	getByIds(
		this: PackageCollection,
		ids: string[],
	): Observable<Package[]> {
		return toJson(this.find({
			selector: {
				id: {
					$in: ids,
				},
			},
		}).$)
	},
}

export const packagesSchema: RxJsonSchema<Package> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'organizationid',
		'name',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		organizationid: {
			type: 'string',
		},
		organizationname: {
			type: 'string',
		},
		name: {
			type: 'string',
			maxLength: 200,
		},
	},
	indexes: ['name'],
}

export const packageCollection: Record<'packages', RxCollectionCreator<StaticMethods>> = {
	packages: {
		schema: packagesSchema,
		statics,
	},
}

export async function pullPackages(): Promise<void> {
	const packages = await loadPackages()
	const db = await databaseCurrent()
	await db.packages.bulkInsert(packages)
}

export async function pullPackage(id: string): Promise<void> {
	const pakage = await loadPackageById(id)
	if (pakage) {
		const db = await databaseCurrent()
		await db.packages.atomicUpsert(pakage)
	} else {
		await logError(`pullPackage(${id}) returns ${pakage}`)
	}
}

export async function handleInsertPackage(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const packageUnknown = await db.collections.packages.getById(id) === null
	if (packageUnknown) {
		await pullPackage(id)
	}
}

export async function handleInsertPackagesChange(
	change: PackagesChangeType['Insert'],
): Promise<void> {
	await handleInsertPackage(change.payload.id)
}

export async function handleUpdatePackagesChange(
	change: PackagesChangeType['Update'],
): Promise<void> {
	await pullPackage(change.payload.id)
}

export async function handleDeletePackagesChange(
	change: PackagesChangeType['Delete'],
): Promise<void> {
	const packageid = change.payload.id
	const db = await databaseCurrent()
	// remove package from ui faster
	await db.collections.packages.find({
		selector: {
			id: packageid,
		},
	}).remove()
	const sets = await db.collections.sets.find({
		selector: {
			packageid,
		},
	}).exec()
	for (const question of sets) {
		await deleteSet(question.id)
	}
}

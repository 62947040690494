import React, { Ref } from 'react'
import { Content } from '../components/Content'

interface ImprintProps {
	observationRef: Ref<HTMLDivElement>
}

export function Imprint({ observationRef }: ImprintProps): React.ReactElement {
	return <Content variant="wide"><div ref={observationRef}>Imprint</div></Content>
}

import { MakeOptional } from '@hirn.app/shared'
import React, {
	useMemo, useState,
} from 'react'
import {
	loadInvitationByEmailAndByOrganizationId,
	loadInvitationByEmailAndByTeamId,
} from '../../backoffice/memberinvitation/memberInvitationsGql'
import { User } from '../../backoffice/user/usersDb'
import {
	loadUserByEmailAndOrganizationId,
	loadUserByEmailAndTeamId,
} from '../../backoffice/user/usersGql'
import { useEffectAsync } from '../../utils/useEffectAsync'
import { Button } from '../Button'
import { Table, TableHeader, TableItem } from './Table'

interface Props {
	users: User[]
	invitation: (userEmail: string) => Promise<void>
	teamInfo?: { teamid: string; organizationid: string }
	organizationid?: string
}

interface InviteUserProps {
	userEmail: string
	invitation: (userEmail: string) => Promise<void>
	teamInfo?: { teamid: string; organizationid: string }
	organizationid?: string
	isInvited?: boolean
}

export function stateMemberLabel(state: string): string {
	return {
		invited: 'Eingeladen',
		blocked: 'Blockiert',
		member: 'Ist bereits Mitglied',
	}[state] ?? 'Einladen'
}

// https://stackoverflow.com/questions/5601647/html5-email-input-pattern-attribute/36379040#36379040
export const mailformat = /^[^@\s]+@[^@\s]+$/

function InviteUser(props: InviteUserProps): React.ReactElement {
	const {
 userEmail, invitation, teamInfo, organizationid,
} = props
	const [invitationState, setInvitationState] = useState('invite')
	useEffectAsync('Memberinvitation.state', async () => {
		if (teamInfo) {
			const { teamid } = teamInfo
			const isUserTeammember = await loadUserByEmailAndTeamId(userEmail, teamid)
			if (isUserTeammember) {
				setInvitationState('member')
				return
			}
			const organizationInvitation = await loadInvitationByEmailAndByOrganizationId(
				userEmail, teamInfo.organizationid,
			)
			const organizationState = organizationInvitation?.state
			if (organizationState === 'blocked') {
				setInvitationState(organizationState)
				return
			}
			const teamInvitation = await loadInvitationByEmailAndByTeamId(userEmail, teamid)
			const state = teamInvitation ? teamInvitation.state : 'invite'
			setInvitationState(state)
			return
		}

		if (organizationid) {
			const isUserOrganizationmember = await loadUserByEmailAndOrganizationId(
				userEmail, organizationid,
			)
			if (isUserOrganizationmember) {
				setInvitationState('member')
				return
			}
			const organizationInvitation = await loadInvitationByEmailAndByOrganizationId(
				userEmail,
				organizationid,
			)
			const state = organizationInvitation
				? stateMemberLabel(organizationInvitation.state) : 'invite'
			setInvitationState(state)
		}
	}, [userEmail, setInvitationState, organizationid, teamInfo])

	if (invitationState !== 'invite') {
		return <span>{ stateMemberLabel(invitationState) }</span>
	}
	return (
		<Button
			addClassName="cursor-pointer"
			color="secondary"
			onClick={async () => {
				await invitation(userEmail)
				setInvitationState('invited')
			}}
		>
			{ stateMemberLabel(invitationState) }
		</Button>
	)
}

export function MemberInvitationTable(props: Props): React.ReactElement {
	const {
		users, invitation, teamInfo, organizationid,
	} = props
	const [input, setInput] = useState('')
	const suggestedUsers = useMemo(() => {
		const wantedUser: MakeOptional<User, 'id'>[] = []
		if (input.match(mailformat) && users.find(user => user.email === input) === undefined) {
			wantedUser.push({
				email: input,
			})
		}
		wantedUser.push(...users.filter(user => (input ? user.email.includes(input) : true)))
		return wantedUser
	}, [users, input])

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Mitglieder</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => suggestedUsers.map(user => [
		<TableItem>{user.email}</TableItem>,
		<TableItem addClassName="flex flex-wrap justify-center">
			<InviteUser
				userEmail={user.email}
				invitation={invitation}
				teamInfo={teamInfo}
				organizationid={organizationid}
			/>
		</TableItem>,

	]), [suggestedUsers, invitation, organizationid, teamInfo])

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const searchEmailInput = e.currentTarget.value
		setInput(searchEmailInput)
	}

	return (
		<div className="relative px-2">
			<input
				data-test-id="search-input"
				type="text"
				onChange={onChange}
				value={input}
				className="border-b-2 p-2 lg:w-7/12 w-full border-black cursor-pointer"
				placeholder="Nach E-Mail-Adresse suchen und einladen"
			/>
			<Table headers={headers} rows={rows} />
		</div>
	)
}

import { intersection } from 'remeda'
import { RxCollection, RxJsonSchema } from 'rxdb'
import {
	Observable, map, of, switchMap,
} from 'rxjs'
import { DbIdChangesImmutable, database, databaseCurrent } from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { handleInsertOrganizationMember } from '../organizationmember/organizationMembersDb'
import { loadOrganizationMemberRoleById, loadOrganizationMemberRoles } from './organizationmemberrolesGql'

type OrganizationMemberRolesChangeType = DbIdChangesImmutable<'organizationmemberroles'>

export type ChangeOrganizationMemberRoles = OrganizationMemberRolesChangeType['All']

export const organizationMemberRoleNames = [
	'organization:owner',
	'organization:manage',
	'organizationmember:manage',
	'package:manage',
	'package:buy',
	'tag:manage',
] as const

export type OrganizationMemberRoleName = typeof organizationMemberRoleNames[number]

export function organizationRoleFilter(
	permissions: OrganizationMemberRoleName[],
): (source: unknown[]) => OrganizationMemberRoleName[] {
	return intersection(permissions)
}

export const organizationOwnerFilter = organizationRoleFilter([
	'organization:owner',
])

export const organizationMemberManagerFilter = organizationRoleFilter([
	'organization:owner',
	'organizationmember:manage',
])

export const organizationPackageManagerFilter = organizationRoleFilter([
	'organization:owner',
	'package:manage',
])

export interface OrganizationMemberRole {
	id: string
	organizationmemberid: string
	name: OrganizationMemberRoleName
}

interface StaticMethods {
	getById(id: string): Promise<OrganizationMemberRole | null>
	getRoleNamesByTeamIdAndUserId(
		teamid: string,
		userid: string,
	): Observable<OrganizationMemberRoleName[]>
}

export type OrganizationMemberRoleCollection = RxCollection<
	OrganizationMemberRole,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: OrganizationMemberRoleCollection,
		id: string,
	): Promise<OrganizationMemberRole | null> {
		const organizationMemberRole = await this.findOne(id).exec()
		return organizationMemberRole?.toJSON() ?? null
	},
	getRoleNamesByTeamIdAndUserId(
		this: OrganizationMemberRoleCollection,
		teamid: string,
		userid: string,
	): Observable<OrganizationMemberRoleName[]> {
		return database().pipe(switchMap(db => db.collections.teams.find({ selector: { id: teamid } }).$
			.pipe(switchMap(([team]) => {
				if (!team) {
					return of([])
				}
				return db.collections.organizationmembers.getWithRoles({
					userids: [userid],
					organizationids: [team.organizationid],
				})
			}), map(members => members.flatMap(roles => roles.roles)))))
	},
}

export const organizationMemberRolesSchema: RxJsonSchema<OrganizationMemberRole> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'organizationmemberid',
		'name',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		organizationmemberid: {
			type: 'string',
			maxLength: 36,
		},
		name: {
			type: 'string',
		},
	},
	indexes: ['organizationmemberid'],
}

export const organizationMemberRoleCollection: Record<
	string,
	RxCollectionCreator<StaticMethods>
> = {
	organizationmemberroles: {
		schema: organizationMemberRolesSchema,
		statics,
	},
}

export async function pullOrganizationMemberRoles(): Promise<void> {
	const organizationMemberRoles = await loadOrganizationMemberRoles()
	const db = await databaseCurrent()
	await db.organizationmemberroles
		.bulkInsert(organizationMemberRoles)
}

export async function pullOrganizationMemberRole(
	id: string,
): Promise<OrganizationMemberRole | null> {
	const organizationMemberRole = await loadOrganizationMemberRoleById(id)
	if (organizationMemberRole) {
		const db = await databaseCurrent()
		await db.organizationmemberroles.atomicUpsert(organizationMemberRole)
	} else {
		await logError(`pullOrganizationMemberRole(${id}) returns ${organizationMemberRole}`)
	}
	return organizationMemberRole
}

async function handleInsertOrganizationMemberRole(id: string): Promise<void> {
	const db = await databaseCurrent()
	const organizationMemberRoleUnknown = await db.collections.organizationmemberroles
		.getById(id) === null
	if (organizationMemberRoleUnknown) {
		const organizationMemberRole = await pullOrganizationMemberRole(id)
		if (organizationMemberRole) {
			// if we discover new organizationmemberrole, then look if we must load organizationmember
			await handleInsertOrganizationMember(organizationMemberRole.organizationmemberid)
		}
	}
}

export async function handleInsertOrganizationMemberRolesChange(
	change: OrganizationMemberRolesChangeType['Insert'],
): Promise<void> {
	await handleInsertOrganizationMemberRole(change.payload.id)
}

export async function handleDeleteOrganizationMemberRolesByOrganizationMemberId(
	organizationmemberid: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.organizationmemberroles.find({
		selector: {
			organizationmemberid,
		},
	}).remove()
}

export async function handleDeleteOrganizationMemberRolesChange(
	change: OrganizationMemberRolesChangeType['Delete'],
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.organizationmemberroles.findOne({
		selector: {
			id: change.payload.id,
		},
	}).remove()
}

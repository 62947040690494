import {
	Paragraph,
} from '@tiptap/extension-paragraph'
import {
	Text,
} from '@tiptap/extension-text'
import {
	Document,
} from '@tiptap/extension-document'
import {
	Content,
	Editor,
	EditorContent,
	EditorOptions,
	generateText,
	useEditor,
} from '@tiptap/react'
import React, {
	Key,
	useLayoutEffect,
} from 'react'
import {
	Bold,
} from '@tiptap/extension-bold'
import {
	Placeholder,
} from '@tiptap/extension-placeholder'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import {
	ensureDefined,
	truncate,
} from '@hirn.app/shared'
import {
	pick,
} from 'remeda'
import {
	Editor as CoreEditor,
} from '@tiptap/core'
import Blockquote from '@tiptap/extension-blockquote'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import Gapcursor from '@tiptap/extension-gapcursor'
import Italic from '@tiptap/extension-italic'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'

import alignRight from 'remixicon/icons/Editor/align-right.svg'
import alignLeft from 'remixicon/icons/Editor/align-left.svg'
import alignCenter from 'remixicon/icons/Editor/align-center.svg'
import blockquote from 'remixicon/icons/Editor/double-quotes-l.svg'
import bold from 'remixicon/icons/Editor/bold.svg'
import bulletList from 'remixicon/icons/Editor/list-unordered.svg'
import deleteColumn from 'remixicon/icons/Editor/delete-column.svg'
import deleteRow from 'remixicon/icons/Editor/delete-row.svg'
import H1 from 'remixicon/icons/Editor/h-1.svg'
import H2 from 'remixicon/icons/Editor/h-2.svg'
import H3 from 'remixicon/icons/Editor/h-3.svg'
import insertRowAbove from 'remixicon/icons/Editor/insert-row-top.svg'
import insertRowBelow from 'remixicon/icons/Editor/insert-row-bottom.svg'
import insertColumnLeft from 'remixicon/icons/Editor/insert-column-left.svg'
import insertColumnRight from 'remixicon/icons/Editor/insert-column-right.svg'
import italic from 'remixicon/icons/Editor/italic.svg'
import redo from 'remixicon/icons/System/arrow-go-forward-line.svg'
import table from 'remixicon/icons/Editor/table-2.svg'
import trash from 'remixicon/icons/System/delete-bin-6-line.svg'
import underline from 'remixicon/icons/Editor/underline.svg'
import undo from 'remixicon/icons/System/arrow-go-back-line.svg'
import toggleTableHeader from '../../icon/h-t.svg'

function EditorBold(props: { className: string }): React.ReactElement {
	return <img src={bold} title="Fett" className={props.className} />
}

function EditorItalic(props: { className: string }): React.ReactElement {
	return <img src={italic} title="Kursiv" className={props.className} />
}

function EditorUnderline(props: { className: string }): React.ReactElement {
	return <img src={underline} title="Unterstrichen" className={props.className} />
}

function EditorTextAlignLeft(props: { className: string }): React.ReactElement {
	return <img src={alignLeft} title="Links ausrichten" className={props.className} />
}

function EditorTextAlignRight(props: { className: string }): React.ReactElement {
	return <img src={alignRight} title="Rechts ausrichten" className={props.className} />
}

function EditorTextAlignCenter(props: { className: string }): React.ReactElement {
	return <img src={alignCenter} title="Zentrieren" className={props.className} />
}

function EditorBulletList(props: { className: string }): React.ReactElement {
	return <img src={bulletList} title="Aufzählung" className={props.className} />
}

function EditorHeadingLevel1(props: { className: string }): React.ReactElement {
	return <img src={H1} title="Überschrift 1" className={props.className} />
}

function EditorHeadingLevel2(props: { className: string }): React.ReactElement {
	return <img src={H2} title="Überschrift 2" className={props.className} />
}

function EditorHeadingLevel3(props: { className: string }): React.ReactElement {
	return <img src={H3} title="Überschrift 3" className={props.className} />
}

function EditorBlockQuote(props: { className: string }): React.ReactElement {
	return <img src={blockquote} title="Zitieren" className={props.className} />
}

function EditorArrowLeft(props: { className: string }): React.ReactElement {
	return <img src={undo} title="Rückgängig" className={props.className} />
}

function EditorArrowRight(props: { className: string }): React.ReactElement {
	return <img src={redo} title="Wiederholen" className={props.className} />
}

function EditorTableInsert(props: { className: string }): React.ReactElement {
	return <img src={table} title="Tabelle einfügen" className={props.className} />
}

function EditorTableDelete(props: { className: string }): React.ReactElement {
	return <img src={trash} title="Tabelle entfernen" className={props.className} />
}

function EditorTableToggleHeaderCell(props: { className: string }): React.ReactElement {
	return <img src={toggleTableHeader} title="Kopfzeile" className={props.className} />
}

function EditorTableInsertColumnRight(props: { className: string }): React.ReactElement {
	return <img src={insertColumnRight} title="Spalte rechts einfügen" className={props.className} />
}

function EditorTableInsertColumnLeft(props: { className: string }): React.ReactElement {
	return <img src={insertColumnLeft} title="Spalte links einfügen" className={props.className} />
}

function EditorTableDeleteColumn(props: { className: string }): React.ReactElement {
	return <img src={deleteColumn} title="Spalte löschen" className={props.className} />
}

function EditorInsertRowAbove(props: { className: string }): React.ReactElement {
	return <img src={insertRowAbove} title="Zeile oberhalb einfügen" className={props.className} />
}

function EditorTableInsertRowBelow(props: { className: string }): React.ReactElement {
	return <img src={insertRowBelow} title="Zeile unterhalb einfügen" className={props.className} />
}

function EditorTableDeleteRow(props: { className: string }): React.ReactElement {
	return <img src={deleteRow} title="Zeile löschen" className={props.className} />
}

interface EditorProps extends Partial<Pick<EditorOptions, 'content' | 'editable' | 'onUpdate' | 'onBlur'>> {
	placeholder?: string
	className?: string
}

export function createEditorConfig(props: EditorProps = {}): Partial<EditorOptions> {
	return {
		extensions: [
			// StarterKit,
			// see:
			// https://www.tiptap.dev/api/extensions/starter-kit
			// Nodes
			// Marks
			Blockquote,
			Bold,
			BulletList,
			Document,
			Gapcursor,
			Italic,
			History,
			ListItem,
			Paragraph,
			Text,
			Underline,
			// Others
			Heading.configure({
				levels: [1, 2, 3],
			}),
			Placeholder.configure({
				placeholder: props.placeholder,
			}),
			TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
			Table.configure({
				resizable: true,
				allowTableNodeSelection: true,
			}),
			TableRow,
      TableHeader,
      TableCell,
		],
		// leads to problems with strict scp
		injectCSS: false,
		editorProps: {
			attributes: {
				class: props.className ?? [
					'min-h-200 p-2 border-dashed border-2 border-black-500',
					// makes default outline more beautiful, see
					// https://tailwindcss.com/docs/outline#remove-outlines
					'focus:outline-none focus:border-solid focus:border-black-500',
					// allows resize of editor content
					'resize-y overflow-auto',
				].join(' '),
			},
		},
		...pick(props, [
			'content',
			'editable',
		]),
	}
}

export function createEditor(props?: EditorProps): Editor {
	return new Editor(createEditorConfig(props))
}

interface EditorInputProps extends Partial<Pick<EditorOptions, 'content' | 'editable'>> {
	variant: 'free' | 'premium' | 'school'
	placeholder?: string
	className?: string
	onChange?: (editor: Editor, data?: Key) => void
	keyId?: Key
}

export function EditorInput({
	content,
	placeholder,
	className,
	onChange,
	keyId,
}: EditorInputProps): React.ReactElement {
	const editor = useEditor(createEditorConfig({
		content: content as Content,
		className,
		placeholder,
	}), [className, placeholder])

	// useEffect is called too late and because of
	// that the 'create' event is raised before
	// onChange handler is attached
	useLayoutEffect((): (() => void) | undefined => {
		if (editor && onChange) {
			function externalOnChange(param?: { editor: CoreEditor }): void {
				onChange?.(param?.editor as Editor, keyId)
			}
			// used for passing editors and
			// evaluation of empty editors
			editor.on('create', externalOnChange)
			editor.on('blur', externalOnChange)
			editor.on('update', externalOnChange)
			// @ts-expect-error
			editor.on('destroy', externalOnChange)
			return () => {
				editor.off('create', externalOnChange)
				editor.off('blur', externalOnChange)
				editor.off('update', externalOnChange)
				// @ts-expect-error
				editor.off('destroy', externalOnChange)
			}
		}
		return undefined
	}, [keyId, editor, onChange])

	const controls = {
		bold: () => editor?.chain().focus().toggleBold().run(),
		isBold: () => editor?.isFocused && editor?.isActive('bold'),
		italic: () => editor?.chain().focus().toggleItalic().run(),
		isItalic: () => editor?.isFocused && editor?.isActive('italic'),
		underline: () => editor?.chain().focus().toggleUnderline().run(),
		isUnderline: () => editor?.isFocused && editor?.isActive('underline'),
		textAlignLeft: () => editor?.chain().focus().setTextAlign('left').run(),
		isTextAlignLeft: () => editor?.isFocused && editor?.isActive({ textAlign: 'left' }),
		textAlignCenter: () => editor?.chain().focus().setTextAlign('center').run(),
		isTextAlignCenter: () => editor?.isFocused && editor?.isActive({ textAlign: 'center' }),
		textAlignRight: () => editor?.chain().focus().setTextAlign('right').run(),
		isTextAlignRight: () => editor?.isFocused && editor?.isActive({ textAlign: 'right' }),
		bulletList: () => editor?.chain().focus().toggleBulletList().run(),
		isBulletList: () => editor?.isActive('bulletList'),
		headingLevel1: () => editor?.chain().focus().toggleHeading({ level: 1 }).run(),
		isHeadingLevel1: () => editor?.isActive('heading', { level: 1 }),
		headingLevel2: () => editor?.chain().focus().toggleHeading({ level: 2 }).run(),
		isHeadingLevel2: () => editor?.isActive('heading', { level: 2 }),
		headingLevel3: () => editor?.chain().focus().toggleHeading({ level: 3 }).run(),
		isHeadingLevel3: () => editor?.isActive('heading', { level: 3 }),
		blockquote: () => editor?.chain().focus().toggleBlockquote().run(),
		isBlockquote: () => editor?.isActive('blockquote'),
		undo: () => editor?.chain().focus().undo().run(),
		isUndo: () => editor?.can().undo(),
		redo: () => editor?.chain().focus().redo().run(),
		isRedo: () => editor?.can().redo(),
		insertTable: () => editor?.chain().focus().insertTable({
			rows: 3, cols: 3, withHeaderRow: true,
		}).run(),
		// is not allowed to insert table into table
		isInsertTable: () => !editor?.can().deleteTable(),
		deleteTable: () => editor?.chain().focus().deleteTable().run(),
		isDeleteTable: () => editor?.can().deleteTable(),
		toggleTableHeaderCell: () => editor?.chain().focus().toggleHeaderCell().run(),
		isToggleTableHeaderCell: () => editor?.can().toggleHeaderCell(),
		insertRowBelow: () => editor?.chain().focus().addRowAfter().run(),
		isInsertRowBelow: () => editor?.can().addRowAfter(),
		insertRowAbove: () => editor?.chain().focus().addRowBefore().run(),
		isInsertRowAbove: () => editor?.can().addRowBefore(),
		deleteRow: () => editor?.chain().focus().deleteRow().run(),
		isDeleteRow: () => editor?.can().deleteRow(),
		insertColumnRight: () => editor?.chain().focus().addColumnAfter().run(),
		isInsertColumnRight: () => editor?.can().addColumnAfter(),
		insertColumnLeft: () => editor?.chain().focus().addColumnBefore().run(),
		isInsertColumnLeft: () => editor?.can().addColumnBefore(),
		deleteColumn: () => editor?.chain().focus().deleteColumn().run(),
		isDeleteColumn: () => editor?.can().deleteColumn(),
	}

	return (
		<>
			<button
				onClick={controls.bold}
				type="button"
			>
				<EditorBold className={`w-5 h-5 mr-1 ${controls.isBold() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.italic}
				type="button"
			>
				<EditorItalic className={`w-5 h-5 mr-1 ${controls.isItalic() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.underline}
				type="button"
			>
				<EditorUnderline className={`w-5 h-5 mr-1 ${controls.isUnderline() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.textAlignLeft}
				type="button"
			>
				<EditorTextAlignLeft className={`w-5 h-5 mr-1 ${controls.isTextAlignLeft() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.textAlignCenter}
				type="button"
			>
				<EditorTextAlignCenter className={`w-5 h-5 mr-1 ${controls.isTextAlignCenter() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.textAlignRight}
				type="button"
			>
				<EditorTextAlignRight className={`w-5 h-5 mr-1 ${controls.isTextAlignRight() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.bulletList}
				type="button"
			>
				<EditorBulletList className={`w-5 h-5 mr-1 ${controls.isBulletList() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.headingLevel1}
				type="button"
			>
				<EditorHeadingLevel1 className={`w-5 h-5 mr-1 ${controls.isHeadingLevel1() ? 'opacity-80' : 'opacity-30'}`} />
			</button>
			<button
				onClick={controls.headingLevel2}
				type="button"
			>
				<EditorHeadingLevel2 className={`w-5 h-5 mr-1 ${controls.isHeadingLevel2() ? 'opacity-80' : 'opacity-30'}`} />
			</button>

			<button
				onClick={controls.headingLevel3}
				type="button"
			>
				<EditorHeadingLevel3 className={`w-5 h-5 mr-1 ${controls.isHeadingLevel3() ? 'opacity-80' : 'opacity-30'}`} />
			</button>
			<button
				onClick={controls.blockquote}
				type="button"
			>
				<EditorBlockQuote className={`w-5 h-5 mr-1 ${controls.isBlockquote() ? 'opacity-80' : 'opacity-30'}`} />
			</button>
			<button
				onClick={controls.undo}
				type="button"
			>
				<EditorArrowLeft className={`w-5 h-5 mr-1 ${controls.isUndo() ? 'opacity-80' : 'opacity-30'}`} />
			</button>
			<button
				onClick={controls.redo}
				type="button"
			>
				<EditorArrowRight className={`w-5 h-5 mr-1 ${controls.isRedo() ? 'opacity-80' : 'opacity-30'}`} />
			</button>
			{controls.isInsertTable()
				&& <button
					onClick={controls.insertTable}
					type="button"
				>
					<EditorTableInsert className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isDeleteTable()
				&& <button
					onClick={controls.deleteTable}
					type="button"
				>
					<EditorTableDelete className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isToggleTableHeaderCell()
				&& <button
					onClick={controls.toggleTableHeaderCell}
					type="button"
				>
					<EditorTableToggleHeaderCell className='w-5 h-5 opacity-80' />
				</button>
			}
			{controls.isInsertRowBelow()
				&& <button
					onClick={controls.insertRowBelow}
					type="button"
				>
					<EditorTableInsertRowBelow className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isInsertRowAbove()
				&& <button
					onClick={controls.insertRowAbove}
					type="button"
				>
					<EditorInsertRowAbove className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isDeleteRow()
				&& <button
					onClick={controls.deleteRow}
					type="button"
				>
					<EditorTableDeleteRow className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isInsertColumnLeft()
			&& <button
				onClick={controls.insertColumnLeft}
				type="button"
			>
				<EditorTableInsertColumnLeft className='w-5 h-5 mr-1 opacity-80' />
			</button>
			}
			{controls.isInsertColumnRight()
				&& <button
					onClick={controls.insertColumnRight}
					type="button"
				>
					<EditorTableInsertColumnRight className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			{controls.isDeleteColumn()
				&& <button
					onClick={controls.deleteColumn}
					type="button"
				>
					<EditorTableDeleteColumn className='w-5 h-5 mr-1 opacity-80' />
				</button>
			}
			<EditorContent editor={editor} />
		</>
	)
}

/**
 * shows only a small part of the content
 */
export function TeaserEditor({
	content,
}: { content: Record<string, unknown> }): React.ReactElement {
	return <>{truncate(generateText(content, ensureDefined(createEditorConfig({
		content: content as Content,
		editable: false,
	}).extensions)), 100)}</>
}

export function ReadOnlyEditor({
	content,
}: { content: Record<string, unknown> }): React.ReactElement {
	const editor = useEditor(createEditorConfig({
		content: content as Content,
		editable: false,
		className: '',
	}), [content])
	return <EditorContent editor={editor} />
}

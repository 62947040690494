import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { OrganizationMember } from './organizationMembersDb'

interface Response {
	organizationmembers: OrganizationMember[]
}

export async function loadOrganizationMembers(): Promise<OrganizationMember[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationMembers {
				organizationmembers {
					id
					userid
					organizationid
				}
			}`,
	})
	return result.organizationmembers
}

export async function loadOrganizationMemberById(id: string): Promise<OrganizationMember | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationMember($ids: [uuid!]!) {
				organizationmembers(where: {id: {_in: $ids}}) {
					id
					userid
					organizationid
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.organizationmembers[0] ?? null
}

export async function inviteOrganizationmember(
	organizationid: string,
	email: string,
): Promise<void> {
	await query<void>({
		query: outdent`
			mutation InviteOrganizationmember($email: String!, $organizationid: String!) {
				invite_member(email: $email, organizationid: $organizationid)
			}`,
		variables: {
			organizationid,
			email,
		},
	})
}

export async function deleteOrganizationmember(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteOrganizationmember($organizationmemberid: uuid!) {
				delete_organizationmember(id: $organizationmemberid) {
					id
				}
			}
			`,
		variables: {
			organizationmemberid: id,
		},
	})
}

import {
	MakeOptional,
} from '@hirn.app/shared'
import PQueue from 'p-queue'
import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
} from 'rxjs'
import {
	DbIdChange,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
	logErrorFn,
} from '../../monitoring/Monitoring'
import {
	OfflineCapable,
	OfflineEntity,
	StaticOffline,
	createOfflineCapable,
	getActiveState,
	offlineCapableSchema,
} from '../../Offline'
import {
	toJsonObject,
} from '../../utils/RxDbUtils'
import {
	AnswerType,
} from '../studying/studying'
import {
	insertUserAnswerGql,
	loadUserAnswerById,
	loadUserAnswers,
} from './useranswersGql'

export type ChangeUserAnswers = DbIdChange<'insert', 'useranswers'>

export interface UserAnswer extends OfflineCapable {
	questionid: string
	createdat: string
	answer: AnswerType
	answered: string[]
	// required on backend, but not on frontend
	timespend: number | undefined
}

interface StaticMethods extends StaticOffline<UserAnswer> {
	getById(id: string): Promise<UserAnswer | null>
	getActive(): Observable<UserAnswer[]>
}

export type UserAnswerCollection = RxCollection<
	UserAnswer,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: UserAnswerCollection,
		id: string,
	): Promise<UserAnswer | null> {
		const userAnswer = await this.findOne(id).exec()
		return userAnswer ? toJsonObject(userAnswer) : null
	},
	getActive(this: UserAnswerCollection): Observable<UserAnswer[]> {
		return getActiveState(this)
	},
	async handleOffline({ inserted }) {
		// TODO: probably we can skip insert and update for deleted entities?
		const queue = new PQueue({ concurrency: 3 })
		await queue.addAll(inserted.map(userAnswer => async () => {
			const result = await insertUserAnswerGql(toJsonObject(userAnswer))
			await userAnswer.atomicPatch({
				...result,
				offlinestate: 'insync',
			})
		}))
	},
}

const userAnswersSchema = offlineCapableSchema<RxJsonSchema<UserAnswer>>({
	version: 0,
	type: 'object',
	required: [
		'questionid',
		'createdat',
		'answer',
		'answered',
	],
	properties: {
		questionid: {
			type: 'string',
			maxLength: 36,
		},
		createdat: {
			type: 'string',
			format: 'date-time',
			maxLength: 36,
		},
		answer: {
			type: 'string', // right | wrong
		},
		answered: {
			type: 'array',
			uniqueItems: true,
			items: {
				type: 'string',
			},
		},
		timespend: {
			type: 'number',
		},
	},
	indexes: [
		['createdat', 'questionid'],
	],
})

export const userAnswerCollection: Record<'useranswers', RxCollectionCreator<StaticMethods>> = {
	useranswers: {
		schema: userAnswersSchema,
		statics,
	},
}

function cleanUpOldUserAnswers(questionid: string): void {
	// run asynchronously to not block anything
	// old answers aren't relevant
	databaseCurrent()
		.then(db => db.collections.useranswers.find({
			selector: {
				// don't use $in here,
				// it would produce wrong results here
				questionid,
			},
			sort: [{
				createdat: 'desc',
			}],
			skip: 10,
		}).remove())
		.catch(logErrorFn('cleanUpOldUserAnswers'))
}

export async function insertUserAnswer(
	userAnswer: MakeOptional<OfflineEntity<UserAnswer>, 'createdat'>,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.useranswers.insert(createOfflineCapable({
		createdat: new Date().toISOString(),
		...userAnswer,
	}))
	cleanUpOldUserAnswers(userAnswer.questionid)
}

export async function pullUserAnswers(): Promise<void> {
	const userAnswers = await loadUserAnswers()
	const db = await databaseCurrent()
	await db.useranswers.bulkInsert(userAnswers)
	for (const userAnswer of userAnswers) {
		cleanUpOldUserAnswers(userAnswer.questionid)
	}
}

async function pullUserAnswer(id: string): Promise<void> {
	const userAnswer = await loadUserAnswerById(id)
	if (userAnswer) {
		const db = await databaseCurrent()
		await db.useranswers.atomicUpsert(userAnswer)
		cleanUpOldUserAnswers(userAnswer.questionid)
	} else {
		await logError(`pullUserAnswer(${id}) returns ${userAnswer}`)
	}
}

export async function handleInsertUserAnswersChange(
	change: ChangeUserAnswers,
): Promise<void> {
	const db = await databaseCurrent()
	const userAnswerUnknown = await db.collections.useranswers.getById(change.payload.id) === null
	if (userAnswerUnknown) {
		await pullUserAnswer(change.payload.id)
	}
}

export async function deleteUserAnswersByQuestionId(
	questionid: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.useranswers.find({
		selector: {
			questionid,
		},
	}).remove()
}

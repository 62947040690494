import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { TeamMember } from './teamMembersDb'

interface Response {
	teammembers: TeamMember[]
}

export async function loadTeamMembers(): Promise<TeamMember[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeamMembers {
				teammembers {
					id
					userid
					teamid
				}
			}
		}`,
	})
	return result.teammembers
}

export async function loadTeamMemberById(id: string): Promise<TeamMember | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeamMember($ids: [uuid!]!) {
				teammembers(where: {id: {_in: $ids}}) {
					id
					userid
					teamid
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.teammembers[0] ?? null
}

export async function inviteTeammember(
	teamid: string,
	email: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation InviteTeammember($email: String!, $teamid: String!) {
				invite_member(email: $email, teamid: $teamid)
			}`,
		variables: {
			teamid,
			email,
		},
	})
}

export async function deleteTeammember(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteTeammember($teammemberid: uuid!) {
				delete_teammember(id: $teammemberid) {
					id
				}
			}
			`,
		variables: {
			teammemberid: id,
		},
	})
}

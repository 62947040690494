import { intersection } from 'remeda'
import { RxCollection, RxJsonSchema } from 'rxdb'
import { DbIdChangesImmutable, databaseCurrent } from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { handleInsertTeamMember } from '../teammember/teamMembersDb'
import { loadTeamMemberRoleById, loadTeamMemberRoles } from './teamMemberRolesGql'

type TeamMemberRolesChangeType = DbIdChangesImmutable<'teammemberroles'>

export type ChangeTeamMemberRoles = TeamMemberRolesChangeType['All']

export const teamMemberRoleNames = [
	'member:manage',
	'package:manage',
] as const

export type TeamMemberRoleName = typeof teamMemberRoleNames[number]

export function teamRoleFilter(
	permissions: TeamMemberRoleName[],
): (source: unknown[]) => TeamMemberRoleName[] {
	return intersection(permissions)
}

export interface TeamMemberRole {
	id: string
	teammemberid: string
	name: TeamMemberRoleName
}

interface StaticMethods {
	getById(id: string): Promise<TeamMemberRole | null>
}

export type TeamMemberRoleCollection = RxCollection<
	TeamMemberRole,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: TeamMemberRoleCollection,
		id: string,
	): Promise<TeamMemberRole | null> {
		const teamMemberRole = await this.findOne(id).exec()
		return teamMemberRole?.toJSON() ?? null
	},
}

export const teamMemberRolesSchema: RxJsonSchema<TeamMemberRole> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'teammemberid',
		'name',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		teammemberid: {
			type: 'string',
			maxLength: 36,
		},
		name: {
			type: 'string',
		},
	},
	indexes: ['teammemberid'],
}

export const teamMemberRoleCollection: Record<
	string,
	RxCollectionCreator<StaticMethods>
> = {
	teammemberroles: {
		schema: teamMemberRolesSchema,
		statics,
	},
}

export async function pullTeamMemberRoles(): Promise<void> {
	const teamMemberRoles = await loadTeamMemberRoles()
	const db = await databaseCurrent()
	await db.teammemberroles.bulkInsert(teamMemberRoles)
}

export async function pullTeamMemberRole(
	id: string,
): Promise<TeamMemberRole | null> {
	const teamMemberRole = await loadTeamMemberRoleById(id)
	if (teamMemberRole) {
		const db = await databaseCurrent()
		await db.teammemberroles.atomicUpsert(teamMemberRole)
	} else {
		await logError(`pullTeamMemberRole(${id}) returns ${teamMemberRole}`)
	}
	return teamMemberRole
}

async function handleInsertTeamMemberRole(id: string): Promise<void> {
	const db = await databaseCurrent()
	const teamMemberRoleUnknown = await db.collections.teammemberroles
		.getById(id) === null
	if (teamMemberRoleUnknown) {
		const teamMemberRole = await pullTeamMemberRole(id)
		if (teamMemberRole) {
			// if we discover new teammemberrole, then look if we must load teammember
			await handleInsertTeamMember(teamMemberRole.teammemberid)
		}
	}
}

export async function handleInsertTeamMemberRolesChange(
	change: TeamMemberRolesChangeType['Insert'],
): Promise<void> {
	await handleInsertTeamMemberRole(change.payload.id)
}

export async function handleDeleteTeamMemberRolesByTeamMemberId(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.teammemberroles.find({
		selector: {
			id,
		},
	}).remove()
}

export async function handleDeleteTeamMemberRolesChange(
	change: TeamMemberRolesChangeType['Delete'],
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.teammemberroles.findOne({
		selector: {
			id: change.payload.id,
		},
	}).remove()
}

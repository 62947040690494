import { useParams } from 'react-router-dom'

type ParamsDefined<Key extends string = string> = {
	readonly [key in Key]: string
}

export function useParamsDefined<Key extends string = string>(): Readonly<ParamsDefined<Key>> {
	const params = useParams<Key>()
	return params as Readonly<ParamsDefined<Key>>
}

import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { map } from 'rxjs/operators'
import { ensureDefined } from '@hirn.app/shared'
import { Button } from '../../components/Button'
import { Input } from '../../components/inputs/Input'
import { Option } from '../../components/inputs/Option'
import { handleFormValidation } from '../../database/validation'
import { AppRoutes } from '../AppRoutes'
import { createTeam } from './teamsGql'
import { useObserveDb } from '../../utils/useObserveDb'
import { getUserIdEnsured } from '../../security/keycloak'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'

export function CreateTeam(): React.ReactElement {
	const navigate = useNavigate()

	const organizations = useObserveDb(db => db.organizations
		.getWithMembers([getUserIdEnsured()])
		.pipe(map(orgs => orgs.filter(org => org.organizationmembers[0]?.roles.includes('organization:owner')).map(org => ({
				label: org.name,
				value: org.id,
			})))),
	[])

	const formConfig = {
		defaultValues: {
			name: '',
			organizationid: organizations?.[0]?.value,
		},
	}

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		reset(formConfig.defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations.length > 0])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success] = await handleFormValidation(
			() => createTeam(data.name, ensureDefined(data.organizationid)),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.team.list.url())
		}
	}

	return (
		<Content variant="narrow">
			<Frame>
				<H1>Team anlegen</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Option
						label="Organisation"
						register={register('organizationid')}
						options={organizations}
						error={errors.organizationid?.message}
					/>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<div className="w-full text-right">
						<Button
							to={AppRoutes.team.list.url()}
							color="secondary"
							addClassName="mr-2"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
						>
							Speichern
						</Button>
					</div>

				</form>
			</Frame>
		</Content>
	)
}

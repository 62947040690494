import {
	useEffect,
	useState,
} from 'react'
import {
	equals,
	takeWhile,
	toPairs,
} from 'remeda'
import {
	BehaviorSubject,
	asyncScheduler,
	fromEvent,
} from 'rxjs'
import {
	filter,
	map,
	throttleTime,
} from 'rxjs/operators'

const sizes = {
	xs: 0,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	'2xl': 1536,
}

type Breakpoint = keyof typeof sizes

const sizesAsArray = toPairs(sizes).sort(([_, resolution]) => resolution)

function getSizes(w: Window): Breakpoint[] {
	return takeWhile(
		sizesAsArray,
		([_, width]) => w.innerWidth >= width,
	)
		.map(([size]) => size)
		.filter((size): size is Breakpoint => size in sizes)
}

const resizeObservable = new BehaviorSubject(getSizes(window))

fromEvent<{ target: Window }>(window, 'resize', {
	passive: true,
})
	.pipe(
		// save some cpu
		throttleTime(100, asyncScheduler, {
			leading: false,
			trailing: true,
		}),
		map(event => event.target),
		map(getSizes),
		// remove already set values
		filter(breakpoints => equals(breakpoints, resizeObservable.value) === false),
	).subscribe(resizeObservable)

/**
 * follows the same logic like tailwind and returns
 * the upper bound:
 * - ["xs"]
 * - ["xs", "sm"]
 * - ["xs", "sm", "md"] ...
 * see https://tailwindcss.com/docs/breakpoints
 */
export function useBreakpoints(): Breakpoint[] {
	const [breakpoints, setBreakpoints] = useState(resizeObservable.value)
	useEffect(() => {
		const subscription = resizeObservable.subscribe(setBreakpoints)
		return () => subscription.unsubscribe()
	}, [])
	return breakpoints
}

import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { TeamMemberRole } from './teamMemberRolesDb'

interface Response {
	teammemberroles: TeamMemberRole[]
}

export async function loadTeamMemberRoles(): Promise<TeamMemberRole[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeamMemberRoles {
				teammemberroles {
					id
					teammemberid
					name
				}
			}
		}`,
	})
	return result.teammemberroles
}

export async function loadTeamMemberRoleById(
	id: string,
): Promise<TeamMemberRole | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeamMemberRole($ids: [uuid!]!) {
				teammemberroles(where: {id: {_in: $ids}}) {
					id
					teammemberid
					name
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.teammemberroles[0] ?? null
}

export async function createTeamMemberRole(
	name: string,
	teammemberid: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
		mutation CreateTeamMemberRole($name: String!, $teammemberid: uuid!) {
			insert_teammemberrole(object: {name: $name, teammemberid: $teammemberid}) {
				id
			}
		}`,
		variables: {
			name,
			teammemberid,
		},
	})
}

export async function deleteTeamMemberRole(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteTeamMemberRole($teamMemberRoleid: uuid!) {
				delete_teammemberrole(id: $teamMemberRoleid) {
					id
				}
			}
			`,
		variables: {
			teamMemberRoleid: id,
		},
	})
}

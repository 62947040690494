import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { OrganizationMemberRole } from './organizationmemberrolesDb'

interface Response {
	organizationmemberroles: OrganizationMemberRole[]
}

export async function loadOrganizationMemberRoles(): Promise<OrganizationMemberRole[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationMemberRoles {
				organizationmemberroles {
					id
					organizationmemberid
					name
				}
			}`,
	})
	return result.organizationmemberroles
}

export async function loadOrganizationMemberRoleById(
	id: string,
): Promise<OrganizationMemberRole | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizationMemberRole($ids: [uuid!]!) {
				organizationmemberroles(where: {id: {_in: $ids}}) {
					id
					organizationmemberid
					name
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.organizationmemberroles[0] ?? null
}

export async function createOrganizationMemberRole(
	name: string,
	organizationmemberid: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation CreateOrganizationMemberRole($name: String!, $organizationmemberid: uuid!) {
				insert_organizationmemberrole(object: {name: $name, organizationmemberid : $organizationmemberid }) {
					id
				}
			}`,
		variables: {
			name,
			organizationmemberid,
		},
	})
}

export async function deleteOrganizationMemberRole(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteOrganizationMemberRole($organizationMemberRoleid: uuid!) {
				delete_organizationmemberrole(id: $organizationMemberRoleid) {
					id
				}
			}
			`,
		variables: {
			organizationMemberRoleid: id,
		},
	})
}

import { useKeycloak } from '@react-keycloak/web'
import React, { useCallback, useEffect } from 'react'
import { resetDatabase } from '../database/Database'
import { logError } from '../monitoring/Monitoring'

export function AuthenticatedView({
	children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
	const { keycloak, initialized } = useKeycloak()

	const logout = useCallback(() => {
		resetDatabase()
			.catch(e => logError(e))
			.finally(() => keycloak.login().catch(e => logError(e)))
	}, [keycloak])

	// use useEffect to prevent logout on second re-render
	useEffect(() => {
		if (initialized && keycloak.authenticated === false) {
			logout()
		}
	}, [initialized, keycloak, keycloak.authenticated, logout])

	return keycloak.authenticated ? <>{children}</> : <></>
}

import {
	outdent,
} from 'outdent'
import {
	omit,
} from 'remeda'
import {
	query,
} from '../../database/GraphQl'
import {
	UserAnswer,
} from './useranswersDb'

function fixUserAnswer(userAnswer: UserAnswer): UserAnswer {
	const fixed: UserAnswer = {
		...userAnswer,
		offlinestate: 'insync',
		// there is no need for updatedat column in database,
		// because `useranswers` can be only
		// added or removed
		updatedat: new Date().toISOString(),
	}
	return fixed
}

export async function loadUserAnswers(): Promise<UserAnswer[]> {
	const result = await query<{ useranswers: UserAnswer[] }>({
		query: outdent`
			query LoadUserAnswers {
				useranswers {
					id
					questionid
					createdat
					answer
					answered
				}
			}`,
	})
	return result.useranswers.map(fixUserAnswer)
}

export async function loadUserAnswerById(id: string): Promise<UserAnswer | null> {
	const result = await query<{ useranswers: UserAnswer[] }>({
		query: outdent`
			query LoadUserAnswer($ids: [uuid!]!) {
				useranswers(where: {id: {_in: $ids}}) {
					id
					questionid
					createdat
					answer
					answered
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.useranswers.map(fixUserAnswer)[0] ?? null
}

export async function insertUserAnswerGql(
	userAnswer: UserAnswer,
): Promise<UserAnswer> {
	const object = omit(userAnswer, [
		'offlinestate',
		'updatedat',
	])

	const result = await query<{ insert_useranswer: UserAnswer }>({
		query: outdent`
			mutation InsertUserAnswer($object: useranswers_insert_input!) {
				insert_useranswer(object: $object) {
					id
					questionid
					createdat
					answer
					answered
				}
			}`,
		variables: {
			object,
		},
	})
	return fixUserAnswer(result.insert_useranswer)
}

import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import { Input } from '../../components/inputs/Input'
import { Option } from '../../components/inputs/Option'
import { handleFormValidation } from '../../database/validation'
import { AppRoutes } from '../AppRoutes'
import { createOrganization } from './organizationsGql'

const profiles = [
	{
		label: 'Unternehmen',
		value: 'company',
	},
	{
		label: 'Schule',
		value: 'school',
	},
	{
		label: 'Privatlehrer/in',
		value: 'teacher',
	},
]

const formConfig = {
	defaultValues: {
		name: '',
		profile: profiles[0]?.value as 'company',
	},
}

export function CreateOrganization(): React.ReactElement {
	const navigate = useNavigate()
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm(formConfig)
	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success] = await handleFormValidation(
			() => createOrganization(data.name, data.profile),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.organization.list.url())
		}
	}
	return (
		<Content variant="narrow">
			<Frame>
				<H1>Organisation anlegen</H1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Option
						label="Organisationstyp"
						register={register('profile')}
						options={profiles}
						error={errors.profile?.message}
					/>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<div className="w-full text-right">
						<Button
							to={AppRoutes.organization.list.url()}
							color="secondary"
							addClassName="mr-2"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

import React from 'react'

interface InputErrorProps {
	error?: string
}

export function InputError({
	error,
}: InputErrorProps): React.ReactElement {
	if (error) {
		return (
			<span className="text-sm text-red-600">
				{error}
			</span>
		)
	}
	return <></>
}

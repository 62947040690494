import {
	ensureDefined,
} from './ObjectUtils.js'

// https://stackoverflow.com/questions/273789/is-there-a-version-of-javascripts-string-indexof-that-allows-for-regular-expr/21420210#21420210
export function lastIndexOf(string: string, regex: RegExp): number {
	const match = string.match(regex)
	if (!match) {
		return -1
	}
	const lastMatch = ensureDefined(match[match.length - 1])
  return string.lastIndexOf(lastMatch)
}

// https://github.com/ueberdosis/tiptap/issues/1279#issuecomment-841627580
export function truncate(string: string, max: number, suffix = '...'): string {
	if (string.length < max) {
		return string
	}

	const lastSpaceIndex = lastIndexOf(string.substring(0, max - suffix.length), /[ \n\r]+/gi)

	return `${string.substring(0, lastSpaceIndex)}${suffix}`
}

import { RxCollection, RxJsonSchema } from 'rxdb'
import { Observable } from 'rxjs'
import { DbIdChanges, databaseCurrent } from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { toJson } from '../../utils/RxDbUtils'
import { loadMemberInvitationById, loadMemberInvitations } from './memberInvitationsGql'

type MemberInvitationsChangeType = DbIdChanges<'memberinvitations'>

export type ChangeMemberInvitations = MemberInvitationsChangeType['All']

export interface MemberInvitation {
	id: string
	inviteduserid: string
	state: 'invited' | 'blocked'
	email: string
	organizationid: string
	organizationname: string
	teamid?: string
	teamname?: string
}

interface StaticMethods {
	getById(id: string): Promise<MemberInvitation | null>
	getMemberInvitations(this: MemberInvitationCollection): Observable<MemberInvitation[]>
}

export type MemberInvitationCollection = RxCollection<MemberInvitation, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: MemberInvitationCollection, id: string): Promise<MemberInvitation | null> {
		const invitation = await this.findOne(id).exec()
		return invitation?.toJSON() ?? null
	},
	getMemberInvitations(this: MemberInvitationCollection): Observable<MemberInvitation[]> {
		return toJson(this
			.find().sort({ id: 'asc' }).$)
	},
}

const memberInvitationsSchema: RxJsonSchema<MemberInvitation> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		inviteduserid: {
			type: 'string',
			maxLength: 36,
		},
		state: {
			type: 'string',
			maxLength: 36,
		},
		email: {
			type: 'string',
			maxLength: 200,
		},
		organizationid: {
			type: 'string',
			maxLength: 36,
		},
		organizationname: {
			type: 'string',
			maxLength: 200,
		},
		teamid: {
			type: 'string',
			maxLength: 36,
		},
		teamname: {
			type: 'string',
			maxLength: 200,
		},
	},
	indexes: ['inviteduserid'],
}

export const memberInvitationCollection: Record<'memberinvitations', RxCollectionCreator<StaticMethods>> = {
	memberinvitations: {
		schema: memberInvitationsSchema,
		statics,
	},
}

export async function pullMemberInvitations(): Promise<void> {
	const invitations = await loadMemberInvitations()
	const db = await databaseCurrent()
	await db.memberinvitations.bulkInsert(invitations)
}

export async function pullMemberInvitation(id: string): Promise<void> {
	const invitation = await loadMemberInvitationById(id)
	if (invitation) {
		const db = await databaseCurrent()
		await db.memberinvitations.atomicUpsert(invitation)
	} else {
		await logError(`pullMemberInvitation(${id}) returns ${invitation}`)
	}
}

export async function handleInsertMemberInvitation(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const invitationUnknown = await db.collections.memberinvitations.getById(id) === null
	if (invitationUnknown) {
		await pullMemberInvitation(id)
	}
}

export async function handleInsertMemberInvitationsChange(
	change: MemberInvitationsChangeType['Insert'],
): Promise<void> {
	await handleInsertMemberInvitation(change.payload.id)
}

export async function handleUpdateMemberInvitationsChange(
	change: MemberInvitationsChangeType['Update'],
): Promise<void> {
	await pullMemberInvitation(change.payload.id)
}

export async function handleDeleteMemberInvitationsChange(
	change: MemberInvitationsChangeType['Delete'],
): Promise<void> {
	const invitationid = change.payload.id
	const db = await databaseCurrent()
	const invitation = await db.collections.memberinvitations.findOne(invitationid).exec()
	await invitation?.remove()
}

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Table, TableHeader, TableItem } from '../../components/table/Table'
import { getUserIdEnsured } from '../../security/keycloak'
import { AppRoutes } from '../AppRoutes'
import { organizationMemberManagerFilter, organizationRoleFilter } from '../organizationmemberrole/organizationmemberrolesDb'
import { useObserveDb } from '../../utils/useObserveDb'
import { Content } from '../../components/Content'
import { EditIcon } from '../../icon'

const organizationManagerFilter = organizationRoleFilter([
	'organization:owner',
	'organization:manage',
])

export function AccountList(): React.ReactElement {
	const organizations = useObserveDb(db => db.organizations.getWithMembers(), [])
	const currentUserId = getUserIdEnsured()

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Organisation</TableHeader>,
		<TableHeader addClassName="sm:text-center">Mitglieder</TableHeader>,
		<TableHeader addClassName="sm:text-center">Lernsätze</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => organizations.map(organization => {
		const currentUserRoleNames = organization.organizationmembers
			.find(member => member.userid === currentUserId)?.roles ?? []
		const canCurrentUserManageOrganizationmember = organizationMemberManagerFilter(
			currentUserRoleNames,
			).length > 0
		const canCurrentUserManageOrganization = organizationManagerFilter(currentUserRoleNames)
			.length > 0
		return [
			<TableItem>{organization.name}</TableItem>,
			<TableItem addClassName="justify-center flex flex-row">
				{canCurrentUserManageOrganizationmember
					&& <Link
						className="flex"
						to={AppRoutes.organizationmember.list.url(organization.id)}
						data-test-id="edit-organizationmembers"
					>
						{organization.organizationmembers.length}
						<EditIcon className="flex ml-4 mb-4 w-5 h-5 text-black"/>
					</Link>
				}
			</TableItem>,
			<TableItem addClassName="sm:text-center">0</TableItem>,
			<TableItem addClassName="flex flex-wrap justify-center">
				{
					canCurrentUserManageOrganization
					&& <Button
						color="secondary"
						dataTestId="edit-organization"
						to={AppRoutes.organization.edit.url(organization.id)}
					>
						Bearbeiten
					</Button>
				}
			</TableItem>,
		]
	}), [organizations, currentUserId])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="flex justify-end">
				<Button
					color="secondary"
					to={AppRoutes.outgoinginvitation.list.url()}
					addClassName="mr-2"
					>
					Ausgehende Einladungen ansehen
				</Button>
				<Button
					color="secondary"
					to={AppRoutes.incominginvitation.list.url()}
					addClassName="mr-2"
					>
					Einladungen verwalten
				</Button>
				<Button
					color="primary"
					to={AppRoutes.organization.create.url()}
				>
					Organisation anlegen
				</Button>
			</div>
		</Content>
	)
}

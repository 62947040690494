import PQueue from 'p-queue'
import {
	RxDatabase,
	RxStorage,
	addRxPlugin,
	createRxDatabase,
} from 'rxdb'
import {
	addPouchPlugin,
	getRxStoragePouch,
} from 'rxdb/plugins/pouchdb'
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	firstValueFrom,
} from 'rxjs'
import { filter } from 'rxjs/operators'
import { isDefined } from 'remeda'
import { getRxStorageLoki } from 'rxdb/plugins/lokijs'
import {
	RxDBQueryBuilderPlugin,
} from 'rxdb/plugins/query-builder'
import {
	RxDBUpdatePlugin,
} from 'rxdb/plugins/update'
import {
	ChangeOrganizations,
	OrganizationCollection,
	handleDeleteOrganizationsChange,
	handleInsertOrganizationsChange,
	handleUpdateOrganizationsChange,
	organizationCollection,
	pullOrganizations,
} from '../backoffice/organization/organizationsDb'
import {
	SettingCollection,
	pullSettings,
	settingsCollection,
} from '../backoffice/setting/settingsDb'
import {
	ChangeOrganizationMembers,
	OrganizationMemberCollection,
	handleDeleteOrganizationMembersChange,
	handleInsertOrganizationMembersChange,
	organizationMemberCollection,
	pullOrganizationMembers,
} from '../backoffice/organizationmember/organizationMembersDb'
import {
	ChangeOrganizationMemberRoles,
	OrganizationMemberRoleCollection,
	handleDeleteOrganizationMemberRolesChange,
	handleInsertOrganizationMemberRolesChange,
	organizationMemberRoleCollection,
	pullOrganizationMemberRoles,
} from '../backoffice/organizationmemberrole/organizationmemberrolesDb'
import {
	ChangeTeams,
	TeamCollection,
	handleDeleteTeamsChange,
	handleInsertTeamsChange,
	handleUpdateTeamsChange,
	pullTeams,
	teamCollection,
} from '../backoffice/team/teamsDb'
import {
	ChangeTeamMembers,
	TeamMemberCollection,
	handleDeleteTeamMembersChange,
	handleInsertTeamMembersChange,
	pullTeamMembers,
	teamMemberCollection,
} from '../backoffice/teammember/teamMembersDb'
import { logError } from '../monitoring/Monitoring'
import { connect } from './GraphQl'
import {
	ChangeSets,
	SetCollection,
	handleDeleteSetsChange,
	handleInsertSetsChange,
	handleUpdateSetsChange,
	pullSets,
	setCollection,
} from '../backoffice/set/setsDb'
import {
	ChangeOrganizationPackages,
	OrganizationPackageCollection,
	handleDeleteOrganizationPackagesChange,
	handleInsertOrganizationPackagesChange,
	handleUpdateOrganizationPackagesChange,
	organizationPackageCollection,
	pullOrganizationPackages,
} from '../backoffice/organizationpackage/organizationpackagesDb'
import {
	ChangeQuestions,
	QuestionCollection,
	handleDeleteQuestionsChange,
	handleInsertQuestionsChange,
	handleUpdateQuestionsChange,
	pullQuestions,
	questionCollection,
} from '../backoffice/question/questionsDb'
import {
	ChangeTeamMemberRoles,
	TeamMemberRoleCollection,
	handleDeleteTeamMemberRolesChange,
	handleInsertTeamMemberRolesChange,
	pullTeamMemberRoles,
	teamMemberRoleCollection,
} from '../backoffice/teammemberrole/teamMemberRolesDb'
import {
	AnswerCollection,
	ChangeAnswers,
	answerCollection,
	handleDeleteAnswersChange,
	handleInsertAnswersChange,
	handleUpdateAnswersChange,
	pullAnswers,
} from '../backoffice/answer/answersDb'
import {
	ChangeStudyingSessions,
	StudyingSessionCollection,
	handleDeleteStudyingSessionsChange,
	handleInsertStudyingSessionsChange,
	handleUpdateStudyingSessionsChange,
	pullStudyingSessions,
	studyingSessionCollection,
} from '../backoffice/studyingsession/studyingsessionsDb'
import {
	ChangeOrganizationPackageLicenses,
	OrganizationPackageLicenseCollection,
	handleDeleteOrganizationPackageLicensesChange,
	handleInsertOrganizationPackageLicensesChange,
	organizationPackageLicenseCollection,
	pullOrganizationPackageLicenses,
} from '../backoffice/organizationpackagelicenses/organizationpackagelicensesDb'
import { clearOfflineSubscriptions, startOfflineHandling } from '../Offline'
import {
	ChangeStudyingSessionSets,
	StudyingSessionSetCollection,
	handleDeleteStudyingSessionSetsChange,
	handleInsertStudyingSessionSetsChange,
	pullStudyingSessionSets,
	studyingSessionSetCollection,
} from '../backoffice/studyingsessionset/studyingsessionsetsDb'
import {
	UserCollection,
	pullUsers,
	userCollection,
} from '../backoffice/user/usersDb'
import {
	ChangeUserQuestions,
	UserQuestionCollection,
	handleDeleteUserQuestionsChange,
	handleInsertUserQuestionsChange,
	handleUpdateUserQuestionsChange,
	pullUserQuestions,
	userQuestionCollection,
} from '../backoffice/userquestion/userquestionsDb'
import {
	ChangeUserAnswers,
	UserAnswerCollection,
	handleInsertUserAnswersChange,
	pullUserAnswers,
	userAnswerCollection,
} from '../backoffice/useranswer/useranswersDb'
import {
	ChangePackages,
	PackageCollection,
	handleDeletePackagesChange,
	handleInsertPackagesChange,
	handleUpdatePackagesChange,
	packageCollection,
	pullPackages,
} from '../backoffice/package/packagesDb'
import {
 ChangeMemberInvitations,
 MemberInvitationCollection,
 handleDeleteMemberInvitationsChange,
 handleInsertMemberInvitationsChange,
 handleUpdateMemberInvitationsChange,
 memberInvitationCollection,
 pullMemberInvitations,
} from '../backoffice/memberinvitation/memberInvitationsDb'

export interface DatabaseCollections {
	settings: SettingCollection
	organizations: OrganizationCollection
	organizationmembers: OrganizationMemberCollection
	organizationmemberroles: OrganizationMemberRoleCollection
	memberinvitations: MemberInvitationCollection
	packages: PackageCollection
	sets: SetCollection
	organizationpackages: OrganizationPackageCollection
	questions: QuestionCollection
	answers: AnswerCollection
	teams: TeamCollection
	teammembers: TeamMemberCollection
	teammemberroles: TeamMemberRoleCollection
	studyingsessions: StudyingSessionCollection
	studyingsessionsets: StudyingSessionSetCollection
	organizationpackagelicenses: OrganizationPackageLicenseCollection
	users: UserCollection
	userquestions: UserQuestionCollection
	useranswers: UserAnswerCollection
}

export type Database = RxDatabase<DatabaseCollections>

/**
	base interface for all events
 */
export interface ChangeBase {
	id: string
	createdat: string
}

export interface DbIdChange<Action extends 'insert' | 'update' | 'delete', Topic extends string> extends ChangeBase {
	topic: `${Action}_${Topic}`
	payload: {
		id: string
	}
}

export interface DbIdChanges<Topic extends string> {
	Insert: DbIdChange<'insert', Topic>
	Update: DbIdChange<'update', Topic>
	Delete: DbIdChange<'delete', Topic>
	All:
		| DbIdChange<'insert', Topic>
		| DbIdChange<'update', Topic>
		| DbIdChange<'delete', Topic>
}

export interface DbIdChangesImmutable<Topic extends string> {
	Insert: DbIdChange<'insert', Topic>
	Delete: DbIdChange<'delete', Topic>
	All:
		| DbIdChange<'insert', Topic>
		| DbIdChange<'delete', Topic>
}

interface ResetDatabase extends ChangeBase {
	topic: 'reset_database'
}

export type Change =
	| ResetDatabase
	| ChangeOrganizations
	| ChangeOrganizationMembers
	| ChangeOrganizationMemberRoles
	| ChangeMemberInvitations
	| ChangePackages
	| ChangeSets
	| ChangeOrganizationPackages
	| ChangeQuestions
	| ChangeAnswers
	| ChangeTeams
	| ChangeTeamMembers
	| ChangeTeamMemberRoles
	| ChangeStudyingSessions
	| ChangeStudyingSessionSets
	| ChangeOrganizationPackageLicenses
	| ChangeUserQuestions
	| ChangeUserAnswers

const dbObservable = new ReplaySubject<Database | undefined>(1)
export const dbStateObservable = new BehaviorSubject<'uninitialized' | 'in progress' | 'initialized'>('uninitialized')

export const pullEverythingProgress = new BehaviorSubject(0)

async function pullEverything() {
	pullEverythingProgress.next(0)
	const queue = new PQueue({ concurrency: 4 })
	const actions: (() => Promise<void>)[] = [
		pullSettings,
		pullOrganizations,
		pullOrganizationMembers,
		pullOrganizationMemberRoles,
		pullMemberInvitations,
		pullPackages,
		pullSets,
		pullOrganizationPackages,
		pullQuestions,
		pullAnswers,
		pullTeams,
		pullTeamMembers,
		pullTeamMemberRoles,
		pullStudyingSessions,
		pullStudyingSessionSets,
		pullOrganizationPackageLicenses,
		pullUsers,
		pullUserQuestions,
		pullUserAnswers,
	]
	const queuedActions = queue.addAll(actions)
	queue.on('active', () => {
		pullEverythingProgress.next(100 - Math.round((queue.size * 100) / actions.length))
	})
	await queuedActions
}

let debugInitialized = false
async function initDebug() {
	// https://rxdb.info/dev-mode.html
	if (debugInitialized === false && process.env.NODE_ENV === 'development') {
		debugInitialized = true
		const { RxDBDevModePlugin } = await import('rxdb/plugins/dev-mode')
		addRxPlugin(RxDBDevModePlugin)
	}
}

function idbStorage(): RxStorage<unknown, unknown> {
	addRxPlugin(RxDBQueryBuilderPlugin)
	addRxPlugin(RxDBUpdatePlugin)
	// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
	addPouchPlugin(require('pouchdb-adapter-idb'))
	return getRxStoragePouch('idb', {
		// RxDB is used just as persistent store
		revs_limit: 1,
		auto_compaction: true,
	})
}

function lokiStorage(): RxStorage<unknown, unknown> {
	addRxPlugin(RxDBQueryBuilderPlugin)
	addRxPlugin(RxDBUpdatePlugin)
	// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
	const LokiIncrementalIndexedDBAdapter = require('lokijs/src/incremental-indexeddb-adapter')
	return getRxStorageLoki({
		adapter: new LokiIncrementalIndexedDBAdapter(),
	})
}

// ! don't forget to logout first
const debugData = false

async function removeDatabase(
	db: RxDatabase<DatabaseCollections>,
): Promise<unknown> {
	if (debugData) {
		// idb adapter creates multiple databases
		return db.remove()
	}
	return new Promise((resolve, reject) => {
		// lokijs adapter remove method is broken
		const request = indexedDB.deleteDatabase(`${db.name}.db`)
		request.onsuccess = resolve
		request.onerror = reject
	})
}

async function createDatabase(retry = 2): Promise<Database> {
	await initDebug()
	const storage = debugData ? idbStorage() : lokiStorage()
	const db = await createRxDatabase<DatabaseCollections>({
		name: 'hirn',
		storage,
	})
	async function addCollections() {
		// @ts-expect-error RxCollectionCreatorBase uses
		// KeyFunctionMap for some value and it's crap
		await db.addCollections({
			...settingsCollection,
			...organizationCollection,
			...organizationMemberCollection,
			...organizationMemberRoleCollection,
			...memberInvitationCollection,
			...packageCollection,
			...setCollection,
			...organizationPackageCollection,
			...questionCollection,
			...answerCollection,
			...teamCollection,
			...teamMemberCollection,
			...teamMemberRoleCollection,
			...studyingSessionCollection,
			...studyingSessionSetCollection,
			...organizationPackageLicenseCollection,
			...userCollection,
			...userQuestionCollection,
			...userAnswerCollection,
		})
	}

	try {
		await addCollections()
	} catch (e) {
		// DB6 means that schema and/or migrations are broken
		if ((e as { code: string }).code !== 'DB6') {
			throw e
		}
		// migration is broken, reset database
		await removeDatabase(db)
		try {
			await addCollections()
		} catch (error) {
			// strange things happen here. Something is broken
			// and because of that finder doesn't returns anything
			// if we restore the db in this way. To reproduce that
			// go to view with db index usage, then delete index
			// definition, save, refresh and then restore index again
			return createDatabase(retry - 1)
		}
	}
	return db
}

export function databaseRaw(): Observable<Database | undefined> {
	return dbObservable
}

export function database(): Observable<Database> {
	return databaseRaw().pipe(filter(isDefined))
}

export async function ensureInitializedDb(): Promise<void> {
	if (dbStateObservable.value === 'uninitialized') {
		dbStateObservable.next('in progress')
		const db = await createDatabase()
		dbObservable.next(db)
		// there are no last changes on fresh database
		const lastChange = await db.settings.getLastChange()
		if (lastChange === null) {
			await pullEverything()
		}
		dbStateObservable.next('initialized')
	}
}

export function databaseCurrent(): Promise<Database> {
	return firstValueFrom(database())
}

export async function resetDatabase(): Promise<void> {
	// there is no point to reset uninitilized database.
	// Furthermore, if a user without database tries to
	// access restricted route, it hangs in "loading",
	// because we can't resolve anything here
	if (dbStateObservable.value === 'initialized') {
		dbStateObservable.next('in progress')
		const db = await firstValueFrom(databaseRaw())
		if (db) {
			dbObservable.next(undefined)
			await removeDatabase(db)
		}
		dbStateObservable.next('uninitialized')
	}
}

// eslint-disable-next-line rxjs/no-async-subscribe
dbStateObservable.subscribe(async state => {
	if (state === 'initialized') {
		while (dbStateObservable.value === 'initialized') {
			await connect(async changes => {
				// serialize changes
				for (const change of changes) {
					switch (change.topic) {
					case 'reset_database':
						await resetDatabase()
						await ensureInitializedDb()
						return null
					case 'insert_organizations':
						await handleInsertOrganizationsChange(change)
						break
					case 'update_organizations':
						await handleUpdateOrganizationsChange(change)
						break
					case 'delete_organizations':
						await handleDeleteOrganizationsChange(change)
						break
					case 'insert_organizationmembers':
						await handleInsertOrganizationMembersChange(change)
						break
					case 'delete_organizationmembers':
						await handleDeleteOrganizationMembersChange(change)
						break
					case 'insert_organizationmemberroles':
						await handleInsertOrganizationMemberRolesChange(change)
						break
					case 'delete_organizationmemberroles':
						await handleDeleteOrganizationMemberRolesChange(change)
						break
					case 'insert_memberinvitations':
						await handleInsertMemberInvitationsChange(change)
						break
					case 'update_memberinvitations':
						await handleUpdateMemberInvitationsChange(change)
						break
					case 'delete_memberinvitations':
						await handleDeleteMemberInvitationsChange(change)
						break
					case 'insert_packages':
						await handleInsertPackagesChange(change)
						break
					case 'update_packages':
						await handleUpdatePackagesChange(change)
						break
					case 'delete_packages':
						await handleDeletePackagesChange(change)
						break
					case 'insert_sets':
						await handleInsertSetsChange(change)
						break
					case 'update_sets':
						await handleUpdateSetsChange(change)
						break
					case 'delete_sets':
						await handleDeleteSetsChange(change)
						break
					case 'insert_organizationpackages':
						await handleInsertOrganizationPackagesChange(change)
						break
					case 'update_organizationpackages':
						await handleUpdateOrganizationPackagesChange(change)
						break
					case 'delete_organizationpackages':
						await handleDeleteOrganizationPackagesChange(change)
						break
					case 'insert_questions':
						await handleInsertQuestionsChange(change)
						break
					case 'update_questions':
						await handleUpdateQuestionsChange(change)
						break
					case 'delete_questions':
						await handleDeleteQuestionsChange(change)
						break
					case 'insert_answers':
						await handleInsertAnswersChange(change)
						break
					case 'update_answers':
						await handleUpdateAnswersChange(change)
						break
					case 'delete_answers':
						await handleDeleteAnswersChange(change)
						break
					case 'insert_teams':
						await handleInsertTeamsChange(change)
						break
					case 'update_teams':
						await handleUpdateTeamsChange(change)
						break
					case 'delete_teams':
						await handleDeleteTeamsChange(change)
						break
					case 'insert_teammemberroles':
						await handleInsertTeamMemberRolesChange(change)
						break
					case 'delete_teammemberroles':
						await handleDeleteTeamMemberRolesChange(change)
						break
					case 'insert_teammembers':
						await handleInsertTeamMembersChange(change)
						break
					case 'delete_teammembers':
						await handleDeleteTeamMembersChange(change)
						break
					case 'insert_studyingsessions':
						await handleInsertStudyingSessionsChange(change)
						break
					case 'update_studyingsessions':
						await handleUpdateStudyingSessionsChange(change)
						break
					case 'delete_studyingsessions':
						await handleDeleteStudyingSessionsChange(change)
						break
					case 'insert_studyingsessionsets':
						await handleInsertStudyingSessionSetsChange(change)
						break
					case 'delete_studyingsessionsets':
						await handleDeleteStudyingSessionSetsChange(change)
						break
					case 'insert_organizationpackagelicenses':
						await handleInsertOrganizationPackageLicensesChange(change)
						break
					case 'delete_organizationpackagelicenses':
						await handleDeleteOrganizationPackageLicensesChange(change)
						break
					case 'insert_userquestions':
						await handleInsertUserQuestionsChange(change)
						break
					case 'update_userquestions':
						await handleUpdateUserQuestionsChange(change)
						break
					case 'delete_userquestions':
						await handleDeleteUserQuestionsChange(change)
						break
						case 'insert_useranswers':
							await handleInsertUserAnswersChange(change)
							break
					default:
						// @ts-expect-error
						await logError(`unknown topic ${change.payload} in event ${change.id}`, change)
					}
				}
				const lastChange = changes[changes.length - 1]
				return lastChange ?? null
			})
		}
	}
})

dbStateObservable.subscribe(state => {
	if (state === 'initialized') {
		startOfflineHandling()
	} else {
		clearOfflineSubscriptions()
	}
})

import {
	ensureDefined,
	leftJoinObservable,
} from '@hirn.app/shared'
import React, {
	useCallback,
	useMemo,
} from 'react'
import {
	Button,
} from '../../components/Button'
import {
	Content,
} from '../../components/Content'
import {
	H1,
} from '../../components/H1'
import {
	Multiselect,
} from '../../components/Multiselect'
import {
	Table,
	TableHeader,
	TableItem,
} from '../../components/table/Table'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	OrganizationLabel,
} from '../organization/OrganizationLabel'
import {
	organizationMemberManagerFilter,
	organizationMemberRoleNames,
	organizationOwnerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'
import {
	createOrganizationMemberRole,
	deleteOrganizationMemberRole,
} from '../organizationmemberrole/organizationmemberrolesGql'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	deleteOrganizationmember,
} from './organizationMembersGql'

export function OrganizationMemberList(): React.ReactElement {
	const { organizationid } = useParamsDefined<'organizationid'>()

	const organizationMembers = useObserveDb(
		db => {
			const organizationmembers = db.collections.organizationmembers
				.getByOrganizationIdWithMemberRoles(organizationid)

			const users = toJson(db.collections.users.find().$)
			const members = leftJoinObservable(
				'user',
				organizationmembers, 'userid',
				users, 'id',
			)
			return members
		},
		[],
		[organizationid],
	)

	// TODO: use organizationOwnerFilter
	const organizationOwnersCount = organizationMembers
		.flatMap(member => (member.roles ?? []).filter(role => role.name === 'organization:owner'))
		.length

	// check if current user can manage an organization
	const currentUserRolenames = useMemo(() => organizationMembers
		.find(member => member.userid === getUserIdEnsured())?.roles?.map(role => role.name) ?? [],
		[organizationMembers],
	)

	const currentUserCanManageMembers = organizationMemberManagerFilter(currentUserRolenames)
		.length > 0

	const isCurrentUserOrganizationOwner = organizationOwnerFilter(currentUserRolenames).length > 0

	/**
	* owner can't be removed
	* if organization has only one owner
	* if current user doesn't have an owner role
	*/
	const canCurrentUserRemoveMember = useCallback((memberToRemoveRoleNames: string[]) => {
		if (organizationOwnerFilter(memberToRemoveRoleNames).length > 0) {
			return organizationOwnersCount > 1 && isCurrentUserOrganizationOwner
		}
		return currentUserCanManageMembers
	}, [currentUserCanManageMembers, organizationOwnersCount, isCurrentUserOrganizationOwner],
	)

	const removeMember = useCallback(
		(memberid: string) => () => deleteOrganizationmember(memberid),
		[],
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Mitglieder</TableHeader>,
		<TableHeader addClassName="sm:text-center">Berechtigungen</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const organizationMemberRows = useMemo(() => organizationMembers.map(member => [
		<TableItem>{member.user?.email}</TableItem>,
		<TableItem>
			<Multiselect
				items={
					organizationMemberRoleNames
						.map(roleName => {
							const foundOrganizationRole = (member?.roles ?? [])
								.find(role => role.name === roleName)
							return {
							selected: !!foundOrganizationRole,
							removeable: roleName === 'organization:owner' ? organizationOwnersCount > 1 && currentUserRolenames.includes('organization:owner') : currentUserCanManageMembers,
							key: {
								id: foundOrganizationRole?.id,
								roleName,
							},
							label: roleName,
							}
						})
						.filter(item => currentUserRolenames.includes('organization:owner')
						|| item.key.roleName !== 'organization:owner'
						|| item.selected,
						)
				}

				addItem={item => createOrganizationMemberRole(item.key.roleName, member.id)}
				removeItem={item => deleteOrganizationMemberRole(ensureDefined(item.key.id))}
				editable={currentUserCanManageMembers}
			/>
		</TableItem>,
		<TableItem addClassName="cursor-pointer flex flex-wrap justify-center">
			{canCurrentUserRemoveMember(member.roles?.map(rolename => rolename.name) ?? [])
				&& <Button
					color="secondary"
					onClick={removeMember(member.id)}
				>
					Entfernen
				</Button>
			}
		</TableItem>,
	]), [
		canCurrentUserRemoveMember,
		currentUserCanManageMembers,
		currentUserRolenames,
		organizationMembers,
		organizationOwnersCount,
		removeMember,
	])
	/* eslint-enable react/jsx-key */

	return (
		<Content variant="wide">
			<H1>
				Organization:&nbsp;<OrganizationLabel organizationid={organizationid} />
			</H1>
			<Table headers={headers} rows={organizationMemberRows} />
			<div className="flex justify-center text-right">
				<div className="container">
					<Button
						to={AppRoutes.organization.list.url()}
						color="secondary"
						addClassName="mr-2"
					>
						Abbrechen
					</Button>
					{currentUserCanManageMembers
						&& <Button
							to={AppRoutes.organizationmember.invitation.url(organizationid)}
							color="primary"
						>
							Mitglieder einladen
						</Button>
					}
				</div>
			</div>
		</Content>
	)
}

import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	DbIdChanges,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	handleInsertOrganization,
} from '../organization/organizationsDb'
import {
	handleInsertPackage,
} from '../package/packagesDb'
import {
	loadOrganizationPackageById,
	loadOrganizationPackages,
} from './organizationpackagesGql'

type OrganizationPackagesChangeType = DbIdChanges<'organizationpackages'>
export type ChangeOrganizationPackages = OrganizationPackagesChangeType['All']

export interface OrganizationPackage {
	id: string
	organizationid: string
	packageid: string
	licensescount: number | null
}

interface StaticMethods {
	getById(id: string): Promise<OrganizationPackage | null>
}

export type OrganizationPackageCollection = RxCollection<
	OrganizationPackage, unknown, StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: OrganizationPackageCollection,
		id: string,
	): Promise<OrganizationPackage | null> {
		const organizatonPackage = await this.findOne(id).exec()
		return organizatonPackage?.toJSON() ?? null
	},
}

export const organizationPackagesSchema: RxJsonSchema<OrganizationPackage> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'organizationid',
		'packageid',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		organizationid: {
			type: 'string',
		},
		packageid: {
			type: 'string',
		},
		licensescount: {
			type: 'number',
		},
	},
}

export const organizationPackageCollection: Record<'organizationpackages', RxCollectionCreator<StaticMethods>> = {
	organizationpackages: {
		schema: organizationPackagesSchema,
		statics,
	},
}

export async function pullOrganizationPackages(): Promise<void> {
	const organizationPackages = await loadOrganizationPackages()
	const db = await databaseCurrent()
	await db.organizationpackages.bulkInsert(organizationPackages)
}

export async function pullOrganizationPackage(id: string): Promise<void> {
	const organizationPackage = await loadOrganizationPackageById(id)
	if (organizationPackage) {
		const db = await databaseCurrent()
		await db.organizationpackages.atomicUpsert(organizationPackage)
	} else {
		await logError(`pullOrganizationPackage(${id}) returns ${organizationPackage}`)
	}
}

async function handleInsertOrganizationPackage(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	let organizationPackage = await db.collections.organizationpackages.getById(id)
	if (!organizationPackage) {
		await pullOrganizationPackage(id)
		organizationPackage = await db.collections.organizationpackages.getById(id)
	}
	if (organizationPackage?.packageid) {
		await handleInsertPackage(organizationPackage.packageid)
	}
	if (organizationPackage?.organizationid) {
		await handleInsertOrganization(organizationPackage.organizationid)
	}
}

export async function handleInsertOrganizationPackagesChange(
	change: OrganizationPackagesChangeType['Insert'],
): Promise<void> {
	await handleInsertOrganizationPackage(change.payload.id)
}

export async function handleUpdateOrganizationPackagesChange(
	change: OrganizationPackagesChangeType['Update'],
): Promise<void> {
	await pullOrganizationPackage(change.payload.id)
}

export async function handleDeleteOrganizationPackagesChange(
	change: OrganizationPackagesChangeType['Delete'],
): Promise<void> {
	const organizationPackageId = change.payload.id
	const db = await databaseCurrent()
	const organizationPackage = await db.collections.organizationpackages
		.findOne(organizationPackageId).exec()
	await organizationPackage?.remove()
	// TODO: delete organizationpackagelicenses
}

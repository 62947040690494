import React from 'react'
import { useBreakpoints } from '../../utils/useBreakpoint'
import { BigTable } from './BigTable'
import { SmallTable } from './SmallTable'

type TableHeaderProps = React.PropsWithChildren<{
	addClassName?: string
}>

export function TableHeader({ addClassName, children }: TableHeaderProps): React.ReactElement {
	return (
		<div className={`text-left ${addClassName ?? ''}`}>
			{children}
		</div>
	)
}

type TableItemProps = React.PropsWithChildren<{
	addClassName?: string
}>

export function TableItem({
	addClassName = '',
	children,
}: TableItemProps): React.ReactElement {
	return (
		<div className={`${addClassName}`}>
			{children}
		</div>
	)
}

export interface TableProps {
	headers: React.ReactElement[]
	rows: React.ReactElement[][]
}

export function Table(props: TableProps): React.ReactElement {
	const breakpoints = useBreakpoints()
	if (breakpoints.includes('sm')) {
		return BigTable(props)
	}
	return SmallTable(props)
}

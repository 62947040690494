import React from 'react'
import { isDefined } from 'remeda'
import { CheckIcon } from '../icon'

interface PriceCardProps {
	styledCard: string
	header: string
	headerBorderBottomColor: string
	price?: number
	movingWords?: boolean
	styledPrice?: string
	featureDescriptions?: React.ReactElement[]
	styledButton: string
	buttonLabel: string
	onClick?: () => void
}

export function FeatureDescription({
	children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
	return (
		<p className="pt-5 inline-flex">
			<span className="fill-current w-4 h-4 text-green"><CheckIcon /></span>
			<span className="font-medium text-gray-800 pl-2">
				{children}
			</span>
		</p>
	)
}

export function PriceCard({
	styledCard,
	header,
	headerBorderBottomColor,
	price,
	movingWords,
	styledPrice,
	featureDescriptions,
	styledButton,
	buttonLabel,
}: React.PropsWithChildren<PriceCardProps>): React.ReactElement {
	return (
		<div className={`flex flex-col border rounded-lg shadow-md ${styledCard}`}>
			<div className="bg-gray-100 bg-opacity-75 py-5 text-center">
				<h1 className="text-3xl semibold">
					{ header }
				</h1>
			</div>
			<hr className={`border-4 ${headerBorderBottomColor}`} />
			<div className="flex-grow pt-4 px-8">
				{isDefined(price)
				&& <p className="py-3 tracking-wide text-center">
					<span className={`text-3xl font-semibold ${styledPrice}`}>{price > 0 ? `Ab nur ${price.toFixed(2)}` : price.toFixed(2)}</span>
					<span className="font-medium">€/Tag</span>
				</p>
				}
				{movingWords
				&& <div className="overflow-hidden leading-9 text-xl py-3">
					<div className="overflow-hidden h-9 justify-center">
						<span className="text-green font-bold">Für</span>
						<ul className="animate-movingWords text-center tracking-wide list-none">
							<li>Privatlehrer</li>
							<li>Schulen</li>
							<li>Unternehmen</li>
						</ul>
					</div>
				</div>
				}
				<div className="flex flex-col">
					{featureDescriptions}
				</div>
			</div>
			<div className="justify-end text-center">
				<button className={`font-medium rounded-md focus:outline-none border px-4 py-2 my-8 w-4/5 ${styledButton}`}>
					{ buttonLabel }
				</button>
			</div>
		</div>
	)
}

import {
	Observable,
	combineLatest,
	map,
} from 'rxjs'
import {
	type ExtractTypes,
} from './TypeUtils.js'
import {
	leftJoin,
	leftJoinAgg,
} from './ObjectUtils.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

/**
 * Same as {@link leftJoin}, but for observables
 */
export function leftJoinObservable<
	JoinAlias extends string,
	FromTableRow extends Record<PropertyKey, Any>,
	FromSideKey extends keyof ExtractTypes<FromTableRow, PropertyKey>,
	JoinTableRow extends Record<PropertyKey, Any>,
	JoinSideKey extends keyof ExtractTypes<JoinTableRow, PropertyKey>,
	R extends(FromTableRow & {
		[joinAliasResult in JoinAlias]: JoinTableRow | undefined
	}),
	>(
		joinAlias: JoinAlias,
		fromTableRows$: Observable<FromTableRow[]>,
		fromSideKey: FromSideKey,
		joinTableRows$: Observable<JoinTableRow[]>,
		joinSideKey: JoinSideKey): Observable<R[]> {
	return combineLatest([fromTableRows$, joinTableRows$])
		.pipe(map(([fromRows, join]) => leftJoin(
			joinAlias,
			fromRows, fromSideKey,
			join, joinSideKey,
		)))
}

/**
 * Same as {@link leftJoinAgg}, but for observables
 */
export function leftJoinAggObservable<
	JoinAlias extends string,
	FromTableRow extends Record<PropertyKey, Any>,
	FromSideKey extends keyof ExtractTypes<FromTableRow, PropertyKey>,
	JoinTableRow extends Record<PropertyKey, Any>,
	JoinSideKey extends keyof ExtractTypes<JoinTableRow, PropertyKey>,
	R extends(FromTableRow & {
		[joinAliasResult in JoinAlias]: JoinTableRow[]
	})
>(
	joinAlias: JoinAlias,
	fromTableRows$: Observable<FromTableRow[]>,
	fromSideKey: FromSideKey,
	joinTableRows$: Observable<JoinTableRow[]>,
	joinSideKey: JoinSideKey): Observable<R[]> {
	return combineLatest([fromTableRows$, joinTableRows$])
		.pipe(map(([fromRows, join]) => leftJoinAgg(
			joinAlias,
			fromRows, fromSideKey,
			join, joinSideKey,
		)))
}

import {
	ensureDefined,
} from './ObjectUtils.js'

// see https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/6470794#6470794
export function moveToEndInPlace<T>(item: T, arr: T[]): void {
	if (arr.length > 1) {
		const itemPosition = arr.indexOf(item)
		if (itemPosition >= 0) {
			const element = ensureDefined(arr[itemPosition])
			arr.splice(itemPosition, 1)
			arr.splice(arr.length, 0, element)
		}
	}
}

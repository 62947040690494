import { outdent } from 'outdent'
import { omit } from 'remeda'
import { query } from '../../database/GraphQl'
import { StudyingSession } from './studyingsessionsDb'

function fixStudyingSession(studyingSession: StudyingSession): StudyingSession {
	const fixed: StudyingSession = {
		...studyingSession,
		offlinestate: 'insync',
	}
	return fixed
}

export async function loadStudyingSessions(): Promise<StudyingSession[]> {
	const result = await query<{ studyingsessions: StudyingSession[] }>({
		query: outdent`
			query LoadStudyingSessions {
				studyingsessions {
					id
					createdat
					updatedat
					system
					data
				}
			}`,
	})
	return result.studyingsessions.map(fixStudyingSession)
}

export async function loadStudyingSessionById(id: string): Promise<StudyingSession | null> {
	const result = await query<{ studyingsessions: StudyingSession[] }>({
		query: outdent`
			query LoadStudyingSession($ids: [uuid!]!) {
				studyingsessions(where: {id: {_in: $ids}}) {
					id
					createdat
					updatedat
					system
					data
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.studyingsessions.map(fixStudyingSession)[0] ?? null
}

export async function insertStudyingSessionGql(
	studyingSession: StudyingSession,
): Promise<StudyingSession> {
	const object = omit(studyingSession, [
		'offlinestate',
		'createdat',
		'updatedat',
	])

	const result = await query<{ insert_studyingsession: StudyingSession }>({
		query: outdent`
			mutation InsertStudyingSession($object: studyingsessions_insert_input!) {
				insert_studyingsession(object: $object) {
					id
					createdat
					updatedat
					system
					data
				}
			}`,
		variables: {
			object,
		},
	})
	return fixStudyingSession(result.insert_studyingsession)
}

export async function updateStudyingSession(
	{ id, data }: StudyingSession,
): Promise<StudyingSession> {
	const result = await query<{ update_studyingsession: StudyingSession }>({
		query: outdent`
			mutation UpdateStudyingSession($id: uuid!, $updatedat: timestamptz!, $data: jsonb!) {
				update_studyingsession(
					pk_columns: {id: $id},
					_set: {
						updatedat: $updatedat,
						data: $data
					}
				) {
					updatedat
					data
				}
			}`,
		variables: {
			id,
			updatedat: new Date().toISOString(),
			data,
		},
	})
	return fixStudyingSession(result.update_studyingsession)
}

export async function deleteStudyingSessionGql(id: string): Promise<void> {
	await query<void>({
		query: outdent`
			mutation DeleteStudyingSession($id: uuid!) {
				delete_studyingsession(id: $id) {
					id
				}
			}`,
		variables: {
			id,
		},
	})
}

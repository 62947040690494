import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { Team } from './teamsDb'

interface Response {
	teams: Team[]
}

export async function loadTeams(): Promise<Team[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeams {
				teams {
					id
					name
					organizationid
				}
			}
		}`,
	})
	return result.teams
}

export async function loadTeamById(id: string): Promise<Team | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadTeam($ids: [uuid!]!) {
				teams(where: {id: {_in: $ids}}) {
					id
					name
					organizationid
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.teams[0] ?? null
}

export async function createTeam(
	name: string,
	organizationid: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
		mutation CreateTeam($name: String!, $organizationid: uuid!) {
			insert_team(object: {name: $name, organizationid: $organizationid}) {
				id
			}
		}`,
		variables: {
			name,
			organizationid,
		},
	})
}

export async function updateTeam(
	id: string,
	name: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdateTeam($id: uuid!, $name: String!) {
				update_team(pk_columns: {id: $id}, _set: {name: $name}) {
					name
					organizationid
				}
			}`,
		variables: {
			id,
			name,
		},
	})
}

export async function deleteTeam(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteTeam($teamid: uuid!) {
				delete_team(id: $teamid) {
					id
				}
			}
			`,
		variables: {
			teamid: id,
		},
	})
}

import {
	JSONContent,
} from '@tiptap/react'
import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	DbIdChanges,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	handleInsertQuestion,
} from '../question/questionsDb'
import {
	AnswerType,
} from '../studying/studying'
import {
	loadAnswerById,
	loadAnswers,
} from './answersGql'

type AnswersChangeType = DbIdChanges<'answers'>

export type ChangeAnswers = AnswersChangeType['All']

export interface Answer {
	id: string
	questionid: string
	updatedat: string
	content: JSONContent
	type: AnswerType
	selectedfeedback?: JSONContent
	unselectedfeedback?: JSONContent
}

type StaticMethods = {
	getById(id: string): Promise<Answer | null>
	getByQuestionId(questionid: string): Promise<Answer[]>
}

export type AnswerCollection = RxCollection<Answer, unknown, StaticMethods>

const statics: StaticMethods = {
	async getById(this: AnswerCollection, id: string): Promise<Answer | null> {
		const answer = await this.findOne(id).exec()
		return answer?.toJSON() as Answer ?? null
	},
	async getByQuestionId(this: AnswerCollection, questionid: string): Promise<Answer[]> {
		const answers = await this.find({
			selector: {
				questionid,
			},
		}).exec()
		return answers.map(answer => ({
			...answer.toJSON(),
		})) as Answer[]
	},
}

export const answersSchema: RxJsonSchema<Answer> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'updatedat',
		'questionid',
		'content',
		'type',
		// TODO: 'order',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		questionid: {
			type: 'string',
		},
		updatedat: {
			type: 'string',
			format: 'date-time',
		},
		content: {
			type: 'object',
		},
		type: {
			type: 'string',
		},
		selectedfeedback: {
			type: 'object',
		},
		unselectedfeedback: {
			type: 'object',
		},
	},
}

export const answerCollection: Record<'answers', RxCollectionCreator<StaticMethods>> = {
	answers: {
		schema: answersSchema,
		statics,
	},
}

export async function pullAnswers(): Promise<void> {
	const answers = await loadAnswers()
	const db = await databaseCurrent()
	await db.answers.bulkInsert(answers)
}

export async function pullAnswer(id: string): Promise<Answer | null> {
	const answer = await loadAnswerById(id)
	if (answer) {
		const db = await databaseCurrent()
		await db.answers.atomicUpsert(answer)
	} else {
		await logError(`pullAnswer(${id}) returns ${answer}`)
	}
	return answer
}

export async function handleInsertAnswer(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	let answer = await db.collections.answers.getById(id)
	if (answer === null) {
		answer = await pullAnswer(id)
	}
	if (answer) {
		await handleInsertQuestion(answer.questionid)
	}
}

export async function handleInsertAnswersChange(
	change: AnswersChangeType['Insert'],
): Promise<void> {
	await handleInsertAnswer(change.payload.id)
}

export async function handleUpdateAnswersChange(
	change: AnswersChangeType['Update'],
): Promise<void> {
	await pullAnswer(change.payload.id)
}

export async function handleDeleteAnswersChange(
	change: AnswersChangeType['Delete'],
): Promise<void> {
	const answerid = change.payload.id
	const db = await databaseCurrent()
	const answer = await db.collections.answers
		.findOne(answerid).exec()
	await answer?.remove()
}

export async function deleteAnswersByQuestionId(
	questionid: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.answers.find({
		selector: {
			questionid,
		},
	}).remove()
}

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AccountList } from './organization/AccountList'
import { BillingList } from './billing/BillingList'
import { AppRoutes } from './AppRoutes'
import { TeamList } from './team/TeamList'
import { CreateOrganization } from './organization/CreateOrganization'
import { CreateTeam } from './team/CreateTeam'
import { EditOrganization } from './organization/EditOrganization'
import { CreateSet } from './set/CreateSet'
import { EditSet } from './set/EditSet'
import { CreateQuestion, EditQuestion } from './question/ManageQuestion'
import { QuestionList } from './question/QuestionList'
import { EditTeam } from './team/EditTeam'
import { StudyingSessionList } from './studyingsession/StudyingSessionList'
import { StudyingChat } from './studyingsession/StudyingChat'
import { CreateStudyingSession } from './studyingsession/CreateStudyingSession'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { AddTeamMembers } from './teammember/AddTeamMembers'
import { AddOrganizationMembers } from './organizationmember/AddOrganizationMembers'
import { TeamMemberList } from './teammember/TeamMemberList'
import { OrganizationMemberList } from './organizationmember/OrganizationMemberList'
import { PackageList } from './package/PackageList'
import { CreatePackage } from './package/CreatePackage'
import { EditPackage } from './package/EditPackage'
import { ViewPackage } from './package/ViewPackage'
import { AcceptIncomingInvitation } from './memberinvitation/AcceptIncomingInvitation'
import { IncomingInvitationList } from './memberinvitation/IncomingInvitationList'
import { IncomingInvitationDetails } from './memberinvitation/IncomingInvitationDetails'
import { OutgoingInvitationList } from './memberinvitation/OutgoingInvitationList'

export function Backoffice(): React.ReactElement {
	return (
		<Routes>
			<Route path={AppRoutes.team.list.path} element={
				<ErrorBoundary>
					<TeamList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.team.create.path} element={
				<ErrorBoundary>
					<CreateTeam />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.team.edit.path} element={
				<ErrorBoundary>
					<EditTeam />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.teammember.list.path} element={
				<ErrorBoundary>
					<TeamMemberList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.teammember.invitation.path} element={
				<ErrorBoundary>
					<AddTeamMembers />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.organization.list.path} element={
				<ErrorBoundary>
					<AccountList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.organization.create.path} element={
				<ErrorBoundary>
					<CreateOrganization />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.organization.edit.path} element={
				<ErrorBoundary>
					<EditOrganization />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.organizationmember.list.path} element={
				<ErrorBoundary>
					<OrganizationMemberList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.organizationmember.invitation.path} element={
				<ErrorBoundary>
					<AddOrganizationMembers />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.package.list.path} element={
				<ErrorBoundary>
					<PackageList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.package.create.path} element={
				<ErrorBoundary>
					<CreatePackage />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.package.view.path} element={
				<ErrorBoundary>
					<ViewPackage />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.package.edit.path} element={
				<ErrorBoundary>
					<EditPackage />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.set.create.path} element={
				<ErrorBoundary>
					<CreateSet />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.set.edit.path} element={
				<ErrorBoundary>
					<EditSet />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.question.list.path} element={
				<ErrorBoundary>
					<QuestionList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.question.create.path} element={
				<ErrorBoundary>
					<CreateQuestion />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.question.edit.path} element={
				<ErrorBoundary>
					<EditQuestion />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.studyingSession.list.path} element={
				<ErrorBoundary>
					<StudyingSessionList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.studyingSession.study.path} element={
				<ErrorBoundary>
					<StudyingChat />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.studyingSession.create.path} element={
				<ErrorBoundary>
					<CreateStudyingSession />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.billing.list.path} element={
				<ErrorBoundary>
					<BillingList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.outgoinginvitation.list.path} element={
				<ErrorBoundary>
					<OutgoingInvitationList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.incominginvitation.list.path} element={
				<ErrorBoundary>
					<IncomingInvitationList />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.incominginvitation.accept.path} element={
				<ErrorBoundary>
					<AcceptIncomingInvitation />
				</ErrorBoundary>
			} />
			<Route path={AppRoutes.incominginvitation.details.path} element={
				<ErrorBoundary>
					<IncomingInvitationDetails />
				</ErrorBoundary>
			} />
		</Routes>
	)
}

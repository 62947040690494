import React from 'react'
import { map } from 'rxjs'
import { useObserveDb } from '../../utils/useObserveDb'

interface TeamLabelProps {
	teamid: string
}

export function TeamLabel({ teamid }: TeamLabelProps): React.ReactElement {
	const teamName = useObserveDb<string | undefined>(
		db => db.collections.teams.findOne(teamid)
			.$
			.pipe(map(q => q?.name)),
		undefined,
		[teamid],
	)

 return <span>{teamName}</span>
}

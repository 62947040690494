import React from 'react'

export function Benefit({
	title,
	children,
}: React.PropsWithChildren<{title: React.ReactElement}>): React.ReactElement {
	return (
		<div className="w-full md:w-1/2 flex">
			<div className="text-center">
				<h3 className="text-2xl font-semibold p-4 tracking-wide">
					{title}
				</h3>
				<div>{children}</div>
			</div>
		</div>
	)
}

export function BenefitImage({
	imagesrc,
}: React.PropsWithChildren<{imagesrc: string}>): React.ReactElement {
	return (
		<div className="w-full md:w-1/2 flex">
			<img
				className="w-1/2 m-auto block justify-center"
				src={imagesrc}
			></img>
		</div>
	)
}

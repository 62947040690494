import {
	assertDefined,
	wait,
} from '@hirn.app/shared'
import {
	groupBy,
	pick,
	prop,
	sortBy,
} from 'remeda'
import {
	RxDocument,
} from 'rxdb'
import {
	firstValueFrom,
} from 'rxjs'
import {
	databaseCurrent,
} from '../../database/Database'
import {
	lineState$,
} from '../../Offline'
import {
	QuestionWithUserQuestion,
} from '../question/questionsDb'
import {
	StudyingSession,
	StudyingSessionHirn,
	StudyingSessionLeitner,
	isStudyingSessionHirn,
	isStudyingSessionLeitner,
} from '../studyingsession/studyingsessionsDb'
import {
	questionsRequestPackage,
} from '../userquestion/userquestionsGql'
import {
	getLeitnerSchedule,
	getLeitnerScheduleSequence,
	getNextHirnSystemQuestions,
} from './studying'

/**
 * Following heuristic for finding question is applied:
 * 1. Search for any question in current level
 * 2. Search for any question in all levels for current session
 * 3. Search for question in next learning sessions
 */
async function getNextLeitnerQuestion(
	studyingSession: RxDocument<StudyingSessionLeitner, unknown>,
): Promise<{
	sessionSwitch: boolean
	question: QuestionWithUserQuestion
}> {
	const db = await databaseCurrent()
	const { data } = studyingSession
	const scheduleLevels = getLeitnerSchedule(data.sessionnumber)

	const setids = (await db.studyingsessionsets.find({
		selector: {
			studyingsessionid: studyingSession.id,
		},
	}).exec()).map(prop('setid'))

	const questionsGrouped = groupBy(await firstValueFrom(db.questions.getWithUserQuestions$({
		setids,
	})), question => question.userquestion.level)

	const sequence: [number, number[]][] = [
		[data.sessionnumber, scheduleLevels.filter(level => level <= data.sessionlevel)],
		...getLeitnerScheduleSequence(data.sessionnumber + 1),
	]

	const levelOneDriedUp = scheduleLevels.includes(1)
		&& (questionsGrouped[1]?.length ?? 0) === 0
		&& (questionsGrouped[0]?.length ?? 0) > 0
	const levelSevenDriedUp = scheduleLevels.includes(7)
		&& !Object.values(pick(questionsGrouped, scheduleLevels))
			.find(questions => (questions?.length ?? 0) > 0)
		&& (questionsGrouped[8]?.length ?? 0) > 0

	for (const [day, levels] of sequence) {
		for (const level of levels) {
			const sessionSwitch = studyingSession.data.sessionnumber !== day
				|| level > studyingSession.data.sessionlevel
			const questions = questionsGrouped[level]
			const question = sortBy(
				questions ?? [],
				// TODO: order by order
				questionForSort => questionForSort.userquestion.lastansweredat,
				questionForSort => questionForSort.createdat,
			)[0]
			if (question) {
				// levelOneDriedUp and levelSevenDriedUp
				// are triggered on session end
				if (levelOneDriedUp && sessionSwitch) {
					await db.userquestions.updateLevel(0, 1)
				}
				if (levelSevenDriedUp) {
					await db.userquestions.updateLevel(8, 7)
				}
				await studyingSession.atomicPatch({
					offlinestate: 'updated',
					data: {
						...data,
						currentquestionid: question.id,
						sessionnumber: day,
						sessionlevel: level,
					},
				})
				return {
					sessionSwitch,
					question,
				}
			}
		}
	}

	// levelOneDriedUp and levelSevenDriedUp are
	// triggered if there are no questions beside
	// level 0 or 8
	if (!levelOneDriedUp && !levelSevenDriedUp) {
		throw new Error(`No questions for studyingSessionId ${studyingSession.id} and sessionNumber ${data.sessionnumber} found`)
	}

	await studyingSession.atomicPatch({
		offlinestate: 'updated',
		data: {
			...data,
			sessionnumber: data.sessionnumber + 1,
		},
	})

	if (levelOneDriedUp) {
		await db.userquestions.updateLevel(0, 1)
	}

	if (levelSevenDriedUp) {
		await db.userquestions.updateLevel(8, 7)
	}

	return {
		sessionSwitch: true,
		question: (await getNextLeitnerQuestion(studyingSession)).question,
	}
}

async function getNextHirnQuestion(
	studyingSession: RxDocument<StudyingSessionHirn, unknown>,
): Promise<{
	sessionSwitch: boolean
	question: QuestionWithUserQuestion
}> {
	const db = await databaseCurrent()

	// check remaining questions in the package
	if (studyingSession.data.questionspackage.length > 0) {
		const { data } = studyingSession
		const questions = await firstValueFrom(db.questions.getWithUserQuestions$({
			ids: data.questionspackage,
		}))
		const firstQuestion = sortBy(
			questions,
			// restore ordering of questions
			question => data.questionspackage.indexOf(question.id),
		)[0]
		if (firstQuestion) {
			return {
				sessionSwitch: false,
				question: firstQuestion,
			}
		}
	}

	// don't wait to long if we are offline now
	// or we have a very bad network connection
	const result = await Promise.race([
		firstValueFrom(lineState$).then(lineState => {
			if (lineState === 'online') {
				return questionsRequestPackage(studyingSession.id)
			}
			return null
		}),
		wait(2000).then(() => null),
	])

	let questions: QuestionWithUserQuestion[]
	if (result === null) {
		const setids = (await db.studyingsessionsets.find({
			selector: {
				studyingsessionid: studyingSession.id,
			},
		}).exec()).map(prop('setid'))
		const allQuestions = await firstValueFrom(db.questions.getWithUserQuestions$({
			setids,
		}))
		questions = getNextHirnSystemQuestions(
			allQuestions.map(question => ({
				question,
				createdat: question.createdat,
				level: question.userquestion.level,
				difficulty: question.userquestion.difficulty,
			})),
			studyingSession.data.maxdifficulty,
		).map(prop('question'))
	} else {
		const ids = result.map(prop('questionid'))
		questions = sortBy(await firstValueFrom(db.questions.getWithUserQuestions$({
			ids: result.map(prop('questionid')),
		})),
			// restore sorting
			question => ids.indexOf(question.id),
		)
	}

	assertDefined(questions[0])

	await studyingSession.atomicPatch({
		offlinestate: 'updated',
		data: {
			...studyingSession.data,
			questionspackage: questions.map(prop('id')),
		},
	})

	return {
		sessionSwitch: true,
		question: questions[0],
	}
}

export function getNextQuestion(
	studyingSession: RxDocument<StudyingSession, unknown>,
): Promise<{
	sessionSwitch: boolean
	question: QuestionWithUserQuestion
}> {
	if (isStudyingSessionLeitner(studyingSession)) {
		return getNextLeitnerQuestion(studyingSession as RxDocument<StudyingSessionLeitner, unknown>)
	}
	if (isStudyingSessionHirn(studyingSession)) {
		return getNextHirnQuestion(studyingSession as RxDocument<StudyingSessionHirn, unknown>)
	}
	// @ts-expect-error
	throw new Error(`Unknown system ${studyingSession.system} for ${studyingSession.id}`)
}

/**
 * Returns currently associated valid question
 * or search for the next question
 */
export async function getCurrentQuestion(
	studyingSession: RxDocument<StudyingSession, unknown>,
): Promise<{
	sessionSwitch: boolean
	question: QuestionWithUserQuestion
}> {
	if (isStudyingSessionLeitner(studyingSession)) {
		const { data } = studyingSession
		const db = await databaseCurrent()
		const setids = (await db.studyingsessionsets.find({
			selector: {
				studyingsessionid: studyingSession.id,
			},
		}).exec()).map(prop('setid'))

		if (data.currentquestionid) {
			// question is only valid if it match all
			// expectations from learning session
			const question = (await firstValueFrom(db.questions.getWithUserQuestions$({
				ids: [data.currentquestionid],
				setids,
				levels: [data.sessionlevel],
			})))[0]
			if (question) {
				return {
					sessionSwitch: false,
					question,
				}
			}
		}

		return getNextQuestion(studyingSession)
	}

	if (isStudyingSessionHirn(studyingSession)) {
		return getNextQuestion(studyingSession)
	}

	// @ts-expect-error
	throw new Error(`Unknown system ${studyingSession.system} for ${studyingSession.id}`)
}

import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import { Input } from '../../components/inputs/Input'
import { useDb } from '../../database/DatabaseProvider'
import { handleFormValidation } from '../../database/validation'
import { DeleteIcon } from '../../icon'
import { logError } from '../../monitoring/Monitoring'
import { useParamsDefined } from '../../utils/useParamsDefined'
import { AppRoutes } from '../AppRoutes'
import { deleteTeam, updateTeam } from './teamsGql'

export function EditTeam(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()
	const navigate = useNavigate()
	const db = useDb()

	const formConfig = {
		defaultValues: {
			name: '',
		},
	}

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		async function getTeam(): Promise<void> {
			const team = await db.collections.teams.findOne({ selector: { id } }).exec()
			if (team) {
				reset(team.toJSON())
			} else {
				await logError(`team ${id} was not found`)
			}
		}
		getTeam().catch(logError)
	}, [id, reset, db])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success] = await handleFormValidation(
			() => updateTeam(id, data.name),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.team.list.url())
		}
	}

	const removeTeam = useCallback(async () => {
		await deleteTeam(id)
		navigate(AppRoutes.team.list.url(), { replace: true })
	}, [id, navigate])

	return (
		<Content variant="narrow">
			<Frame>
				<H1>Team bearbeiten</H1>
				<form onSubmit={handleSubmit(onSubmit)}>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<Button
						onClick={removeTeam}
						color="secondary"
						addClassName="float-left"
						dataTestId="delete-team"
					>
						<DeleteIcon className="w-5 h-5" />
					</Button>
					<div className="w-full text-right">
						<Button
							to={AppRoutes.team.list.url()}
							color="secondary"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
							addClassName="ml-2"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { User } from './usersDb'

interface Response {
	users: User[]
}

export async function loadUsers(): Promise<User[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadUsers {
				users {
					email
					id
				}
			}`,
		})
	return result.users
}

export async function loadUserById(id: string): Promise<User | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadUser($ids: [uuid!]!) {
				users(where: {id: {_in: $ids}}) {
					id
					email
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.users[0] ?? null
}

export async function loadUserByEmailAndTeamId(
	email: string,
	teamid: string,
): Promise<User | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadUser($email: String!, $teamid: uuid!) {
				users(where: {
					email: {_eq: $email},
					teammembers: {
						teamid: {_eq: $teamid}
					}
				}) {
					id
				}
			}`,
		variables: {
			email,
			teamid,
		},
	})
	return result.users[0] ?? null
}

export async function loadUserByEmailAndOrganizationId(
	email: string,
	organizationid: string,
): Promise<User | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadUser($email: String!, $organizationid: uuid!) {
				users(where: {
					email: {_eq: $email},
					organizationmembers: {
						organizationid: {_eq: $organizationid}
					}
				}) {
					id
				}
			}`,
		variables: {
			email,
			organizationid,
		},
	})
	return result.users[0] ?? null
}

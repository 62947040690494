import {
	addProp,
	difference,
	groupBy,
	indexBy,
	intersection,
	pick,
} from 'remeda'
import {
	type ExtractTypes,
} from './TypeUtils.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

export function assertDefined(value: unknown): asserts value {
	if (value === undefined || value === null) {
		throw new Error(`value ${value} isn't defined`)
	}
}

export function ensureDefined<T>(value: T): NonNullable<T> {
	assertDefined(value)
	return value as NonNullable<T>
}

// https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object/59787784#59787784
export function isObjectEmpty(obj: Record<string, unknown>): boolean {
	/* eslint-disable-next-line guard-for-in, no-restricted-syntax, no-unreachable-loop */
  for (const key in obj) {
    return false
  }
  return true
}

export function compare<O, N, I extends string | number>(
	oldObjects: O[],
	newObjects: N[],
	getOldId: (id: O) => I,
	getNewId: (id: N) => I | undefined,
	equal: (oldObject: O, newObject: N) => boolean,
): {
	added: N[]
	removed: O[]
	changed: N[]
} {
	const byIdOld = indexBy(oldObjects, getOldId)

	const {
		definitelyNew,
		possibleNew,
	} = groupBy(
		newObjects,
		// eslint-disable-next-line no-confusing-arrow
		newObject => getNewId(newObject) === undefined
			? 'definitelyNew'
			: 'possibleNew'
		,
	)

	const byIdNew = indexBy(
		possibleNew ?? [],
		possibleNewObject => ensureDefined(getNewId(possibleNewObject)),
	)
	const idsOld = Object.keys(byIdOld)
	const idsNew = Object.keys(byIdNew)

	const addedIds = difference(idsNew, idsOld)
	const added: N[] = Object.values(pick(byIdNew, addedIds))
	added.push(...definitelyNew ?? [])

	const removedIds = difference(idsOld, idsNew)
	const removed: O[] = Object.values(pick(byIdOld, removedIds))

	const sameIds = intersection(idsOld, idsNew)
	const changedIds = sameIds.filter(id => equal(
		byIdOld[id] as unknown as O,
		byIdNew[id] as unknown as N,
	) === false)
	const changed: N[] = Object.values(pick(byIdNew, changedIds))
	return {
		added,
		removed,
		changed,
	}
}

/**
 * converts an object to a string representation
 * without additional JSON quotes
 * @see https://stackoverflow.com/questions/54970719/json-stringify-object-with-keys-without-the-quotes-in-subobject/54987438#54987438
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function toString(object: Object): string {
	return JSON.stringify(object).replace(/"(\w+)"\s*:/g, '$1:')
}

/**
 * Very similar to left join in sql:
 * SELECT fromTable.*, joinTable.* as joinAlias
 * FROM fromTable
 * LEFT JOIN joinTable ON fromTable.fromSide = joinTable.joinSide
 *
 * It's best to read the parameters as:
 * joinAlias: fromTable.fromSide = joinTable.joinSide
 *
 * @param joinAlias adds JOIN table as joinAlias to fromTable
 * @param fromTableRows like FROM table
 * @param fromSideKey foreign key of fromTable
 * @param joinTableRows like JOIN table
 * @param joinSideKey primary key of joinSide
 */
export function leftJoin<
	JoinAlias extends string,
	FromTableRow extends Record<PropertyKey, Any>,
	FromSideKey extends keyof ExtractTypes<FromTableRow, PropertyKey>,
	JoinTableRow extends Record<PropertyKey, Any>,
	JoinSideKey extends keyof ExtractTypes<JoinTableRow, PropertyKey>,
	R extends(FromTableRow & {
		[joinAliasResult in JoinAlias]: JoinTableRow | undefined
	}),
	>(
		joinAlias: JoinAlias,
		fromTableRows: FromTableRow[],
		fromSideKey: FromSideKey,
		joinTableRows: JoinTableRow[],
		joinSideKey: JoinSideKey): R[] {
	function grouper(row: JoinTableRow): JoinTableRow[JoinSideKey] {
		return row[joinSideKey]
	}

	function getFromGrouped(
		joinGrouped: Record<FromTableRow[FromSideKey], JoinTableRow>,
		from: FromTableRow,
	): JoinTableRow {
		const onValue = from[fromSideKey]
		return joinGrouped[onValue]
	}

	const joinGrouped = indexBy(joinTableRows, grouper)
	const result = fromTableRows.map(fromRow => addProp(
		fromRow,
		joinAlias,
		getFromGrouped(joinGrouped, fromRow),
	))
	return result as R[]
}

/**
 * Very similar to left join in sql, but with aggregation of join table:
 * SELECT fromTable.*, to_json(joinTable.*) as joinAlias
 * FROM fromTable
 * LEFT JOIN joinTable ON fromTable.fromSide = joinTable.joinSide
 *
 * It's best to read the parameters as:
 * joinAlias: fromTable.fromSide = joinTable.joinSide
 *
 * @param joinAlias adds JOIN table as joinAlias to fromTable
 * @param fromTableRows like FROM table
 * @param fromSideKey foreign key of fromTable
 * @param joinTableRows like JOIN table
 * @param joinSideKey primary key of joinSide
 */
export function leftJoinAgg<
	JoinAlias extends string,
	FromTableRow extends Record<PropertyKey, Any>,
	FromSideKey extends keyof ExtractTypes<FromTableRow, PropertyKey>,
	JoinTableRow extends Record<PropertyKey, Any>,
	JoinSideKey extends keyof ExtractTypes<JoinTableRow, PropertyKey>,
	R extends(FromTableRow & {
		[joinAliasResult in JoinAlias]: JoinTableRow[]
	})
>(
	joinAlias: JoinAlias,
	fromTableRows: FromTableRow[],
	fromSideKey: FromSideKey,
	joinTableRows: JoinTableRow[],
	joinSideKey: JoinSideKey): R[] {
	function grouper(row: JoinTableRow): JoinTableRow[JoinSideKey] {
		return row[joinSideKey]
	}

	function getFromGrouped(
		joinGrouped: Partial<Record<JoinTableRow[JoinSideKey], Array<JoinTableRow>>>,
		from: FromTableRow,
	): JoinTableRow {
		const onValue = from[fromSideKey]
		// the assumtion here is that JoinTableRow[JoinSideKey] === JoinTableRow[JoinSideKey]
		// @ts-expect-error
		return joinGrouped[onValue]
	}

	const joinGrouped = groupBy(joinTableRows, grouper)
	const result = fromTableRows.map(fromTableRow => addProp(
		fromTableRow,
		joinAlias,
		getFromGrouped(joinGrouped, fromTableRow) ?? [],
	))
	return result as unknown as R[]
}

import React, { useMemo } from 'react'
import { map } from 'rxjs'
import { Navigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Table, TableHeader, TableItem } from '../../components/table/Table'
import { useObserveDb } from '../../utils/useObserveDb'
import { AppRoutes } from '../AppRoutes'
import { Question } from './questionsDb'
import { Set } from '../set/setsDb'
import { Content } from '../../components/Content'
import { TeaserEditor } from '../../components/editor/EditorInput'
import { useParamsDefined } from '../../utils/useParamsDefined'

export function QuestionList(): React.ReactElement {
	const { setid } = useParamsDefined<'setid'>()
	const set = useObserveDb<Set | undefined>(
		db => db.collections.sets.findOne(setid)
			.$
			.pipe(map(q => q?.toJSON())),
		undefined,
	)
	const questions = useObserveDb<Question[] | undefined>(
		db => db.collections.questions.getBySetId$(setid),
		undefined,
		[setid],
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>#</TableHeader>,
		<TableHeader addClassName="sm:text-center">Frage</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => (questions ?? []).map(question => [
		<TableItem>{question.order}</TableItem>,
		<TableItem addClassName="question-content">
			<TeaserEditor content={question.content} />
		</TableItem>,
		<TableItem addClassName="cursor-pointer flex flex-wrap justify-center">
			{
				<Button color="secondary" to={AppRoutes.question.edit.url(question.id)}>Bearbeiten</Button>
			}
		</TableItem>,
	]), [questions])
	/* eslint-enable react/jsx-key */

	if (!set) {
		return <></>
	}
	if (questions?.length === 0) {
		return (
			<Navigate
				to={AppRoutes.question.create.url(set.id)}
				replace={true}
			/>
		)
	}
	return (
		<Content variant="wide">
			<div>{set.name}</div>
			<Table headers={headers} rows={rows} />
			<div className="text-right">
				<Button
					color="secondary"
					to={AppRoutes.package.view.url(set.packageid)}
					addClassName="float-left"
				>
					Zurück
				</Button>
				<Button
					color="primary"
					addClassName="ml-2"
					to={AppRoutes.question.create.url(set.id)}
				>
					Frage hinzufügen
				</Button>
			</div>
		</Content>
	)
}

import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { Organization } from './organizationsDb'

interface Response {
	organizations: Organization[]
}

export async function loadOrganizations(): Promise<Organization[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganizations {
				organizations {
					id
					name
				}
			}`,
	})
	return result.organizations
}

export async function loadOrganizationById(id: string): Promise<Organization | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadOrganization($ids: [uuid!]!) {
				organizations(where: {id: {_in: $ids}}) {
					id
					name
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.organizations[0] ?? null
}

export async function createOrganization(
	name: string,
	profile: 'company' | 'school' | 'teacher',
): Promise<void> {
	await query<Response>({
		query: outdent`
		mutation CreateOrganization($name: String!, $profile: String!) {
			insert_organization(object: {name: $name, profile: $profile}) {
				id
			}
		}`,
		variables: {
			name,
			profile,
		},
	})
}

export async function updateOrganization(
	id: string,
	name: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdateOrganization($id: uuid!, $name: String!) {
				update_organization(pk_columns: {id: $id}, _set: {name: $name}) {
					name
				}
			}`,
		variables: {
			id,
			name,
		},
	})
}

export async function deleteOrganization(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteOrganization($organizationid: uuid!) {
				delete_organization(id: $organizationid) {
					id
				}
			}
			`,
		variables: {
			organizationid: id,
		},
	})
}

import React from 'react'
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill'
import {
	ensureDefined,
	moveToEndInPlace,
} from '@hirn.app/shared'
import { Virtuoso } from 'react-virtuoso'
import {
	clamp,
	clone,
	difference,
	equals,
	noop,
	prop,
	sortBy,
	splitAt,
	times,
	toPairs,
} from 'remeda'
import {
	map,
	switchMap,
} from 'rxjs'
import { RxDocument } from 'rxdb'
import { Content } from '../../components/Content'
import {
	getCurrentQuestion,
	getNextQuestion,
} from '../studying/studyingDb'
import {
	Question,
	QuestionType,
	QuestionWithUserQuestion,
	guessQuestionType,
} from '../question/questionsDb'
import { databaseCurrent } from '../../database/Database'
import { ReadOnlyEditor } from '../../components/editor/EditorInput'
import {
	insertUserQuestion,
	updateUserQuestion,
} from '../userquestion/userquestionsDb'
import { useParamsDefined } from '../../utils/useParamsDefined'
import { Answer } from '../answer/answersDb'
import { insertUserAnswer } from '../useranswer/useranswersDb'
import { useStateReducer } from '../../utils/useStateReducer'
import { useEffectAsync } from '../../utils/useEffectAsync'
import { useObserveDb } from '../../utils/useObserveDb'
import {
	StudyingSession,
	StudyingSessionLeitner,
	isStudyingSessionHirn,
	isStudyingSessionLeitner,
} from './studyingsessionsDb'
import {
	AnswerType,
	calcLevel,
	getLeitnerSchedule,
	isRehaMode,
} from '../studying/studying'

// fix broken iOS smooth scrolling
elementScrollIntoViewPolyfill({
	duration: 100,
})

function Avatar() {
	return (
		<div className="flex items-center justify-center h-10 w-10 rounded-full bg-green flex-shrink-0 text-white">
			h!
		</div>
	)
}

let globalFeedbackCounter = 0
/* eslint-disable react/jsx-key */
const successImages = [
	<img height={200} src="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/placeholder/dance-dancing-cat.gif" />,
	<img height={200} src="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/placeholder/tumblr_myit1v8hsy1s0ggdgo1_500.gif" />,
]
const failImages = [
	<img height={200} src="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/placeholder/nein.gif" />,
	<img height={200} src="https://gitlab.dev.bessonov.de/hirn.app/static/-/raw/master/placeholder/nein-no.gif" />,
]
/* eslint-enable react/jsx-key */

function getSuccessImage(feedbackCounter: number): React.ReactElement {
	return ensureDefined(successImages[feedbackCounter % successImages.length])
}
function getFailImage(feedbackCounter: number): React.ReactElement {
	return ensureDefined(failImages[feedbackCounter % failImages.length])
}

function replaceByIdOrAdd<T extends { id: string }>(
	entries: T[],
	newEntry: Partial<T> & { id: string },
): T[] {
	const existingEntry = entries.find(entry => entry.id === newEntry.id)
	if (existingEntry) {
		return entries.map(entry => (entry.id === newEntry.id ? {
			...entry,
			...newEntry,
		} : entry))
	}
	// @ts-expect-error
	return [...entries, newEntry]
}

interface QuestionContent {
	type: 'question'
	id: string
	question: Question
}

interface AnswerContent {
	type: 'answer'
	id: string
	answer: Answer
}

interface YesNoAnswerContent {
	type: 'yesNoAnswer'
	id: string
}

interface SelfAssessmentAnswerContent {
	type: 'selfAssessmentAnswer'
	id: string
	showed: boolean
	answer: Answer
}

interface CheckAnswersContent {
	type: 'checkAnswers'
	id: string
}

interface FeedbackContent {
	type: 'feedback'
	id: string
	feedbackCounter: number
	feedback: AnswerType
	timespend: number
}

interface HirnAppMessageContent {
	type: 'hirnAppMessage'
	id: string
	message: React.ReactElement
}

interface UserMessageContent {
	type: 'userMessage'
	id: string
	message: React.ReactElement
}

type Content =
	| QuestionContent
	| AnswerContent
	| SelfAssessmentAnswerContent
	| YesNoAnswerContent
	| CheckAnswersContent
	| FeedbackContent
	| HirnAppMessageContent
	| UserMessageContent

interface StudyingChatStateBase {
	contents: Content[]
	answers: Answer[]
	selectedAnswers: Answer[]
	studyingSession?: RxDocument<StudyingSession, unknown>
}

type StudyingChatState = StudyingChatStateBase & (
	{
		step: 'noop'
		startedat: Date
		question?: QuestionWithUserQuestion
	} | {
		step: 'initialization'
	} | {
		step: 'fetch next question'
		question: QuestionWithUserQuestion
	} | {
		step: 'fetch answers'
		question: QuestionWithUserQuestion
	} | {
		step: 'show question'
		question: QuestionWithUserQuestion
		questionType: QuestionType
	} | {
		step: 'check feedback'
		question: QuestionWithUserQuestion
		questionType: QuestionType
		feedback: AnswerType
		startedat: Date
	}
)

function LeitnerHeader({
	studyingSession,
}: { studyingSession: StudyingSessionLeitner }): React.ReactElement {
	const scheduledLevels = getLeitnerSchedule(studyingSession.data.sessionnumber)

	function getLevelColor(level: number) {
		// current learning level
		if (studyingSession.data.sessionlevel === level) {
			return 'bg-green-500'
		}
		// learning levels for current session
		if (scheduledLevels.includes(level)) {
			return 'bg-green-400'
		}
		return ''
	}

	function getCompartmentLabel(level: number) {
		return [
			'🙅',
			'#1',
			'#2',
			'#3',
			'#4',
			'#5',
			'#6',
			'#7',
			'🎓',
		][level]
	}

	const levels = useObserveDb(db => db.studyingsessionsets.find({
		selector: {
			studyingsessionid: studyingSession.id,
		},
	}).$.pipe(
		map(sets => sets.map(prop('setid'))),
		switchMap(setids => db.collections.questions.getWithUserQuestions$({ setids })
			.pipe(map(questions => {
				const levelsMap: Record<number, number> = {
					0: 0,
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
					6: 0,
					7: 0,
					8: 0,
				}
				for (const question of questions) {
					const { level } = question.userquestion
					levelsMap[level] += 1
				}
				return sortBy(toPairs(levelsMap), ([levelMap]) => levelMap)
			})))), undefined, [studyingSession])

	if (levels === undefined) {
		return <div></div>
	}

	return (
		<div>
			<div>Lerntag:&nbsp;
				<span data-test-id="leitner-test-day">
					{studyingSession.data.sessionnumber + 1}
				</span>
			</div>
			<div className="bg-green-light flex justify-evenly">
				{
					levels.map(([level, count]) => (
						<div key={level} className={`float-left mx-1 w-full text-center ${getLevelColor(+level)}`}>
							<p>{getCompartmentLabel(+level)}</p>
							<p data-test-id="leitner-level-counter">{count}</p>
						</div>
					))
				}
			</div>
		</div>
	)
}

export function StudyingChat(): React.ReactElement {
	const { id: studyingsessionid } = useParamsDefined<'id'>()

	const [state, setState] = useStateReducer<StudyingChatState>(
		{
			step: 'initialization',
			contents: [],
			answers: [],
			selectedAnswers: [],
		},
	)

	useEffectAsync('StudyingChat.state', async () => {
		if (state.step === 'initialization') {
			const db = await databaseCurrent()
			// TODO: show not found view instead of error
			const studyingSession = ensureDefined(
				await db.studyingsessions.findOne(studyingsessionid).exec())

			// touch session last used time
			await studyingSession.atomicPatch({
				offlinestate: 'updated',
				updatedat: new Date().toISOString(),
			})

			const { question: currentQuestion } = await getCurrentQuestion(
				studyingSession,
			)

			setState({
				step: 'fetch answers',
				question: currentQuestion,
				studyingSession,
			})
		}

		if (state.step === 'fetch next question') {
			const {
				sessionSwitch,
				question: nextQuestion,
			} = await getNextQuestion(ensureDefined(state.studyingSession))
			if (sessionSwitch) {
				return setState({
					step: 'noop',
					question: nextQuestion,
					contents: [
						{
							type: 'hirnAppMessage',
							id: 'hirnAppMessage',
							message: <>Toll, du hast alle Fragen für die Sitzung beantwortet!</>,
						}, {
							type: 'userMessage',
							id: 'userMessage',
							message: <span data-test-id="next-package" onClick={() => setState({
								step: 'fetch answers',
								question: nextQuestion,
							})}>Nächste Packung lernen 🚀</span>,
						},
					],
				})
			}
			return setState({
				step: 'fetch answers',
				question: nextQuestion,
			})
		}

		if (state.step === 'fetch answers') {
			const db = await databaseCurrent()
			const answers = await db.answers.getByQuestionId(state.question.id)
			const questionType = guessQuestionType(answers)
			setState({
				step: 'show question',
				questionType,
				contents: [],
				answers,
				selectedAnswers: [],
			})
		}

		if (state.step === 'show question') {
			setState(current => ({
				step: 'noop',
				contents: [...current.contents, {
					type: 'question',
					id: state.question.id,
					question: state.question,
				}],
				startedat: new Date(),
			}))

			if (state.questionType === 'self assessment') {
				const answer = ensureDefined(state.answers[0])
				setState(current => ({
					contents: [
						...current.contents,
						{
							type: 'selfAssessmentAnswer',
							id: state.question.id,
							showed: false,
							answer,
						},
					],
				}))
			} else {
				setState(current => ({
					contents: [
						...current.contents,
						...state.answers.map((answer): Content => ({
							type: 'answer',
							id: answer.id,
							answer,
						})),
					],
				}))
			}

			if (state.questionType === 'info') {
				setState(current => ({
					contents: [
						...current.contents,
						{
							type: 'yesNoAnswer',
							id: 'yesNoAnswer',
						},
					],
				}))
			}

			if (state.questionType === 'multiple') {
				setState(current => ({
					contents: [...current.contents, {
						type: 'checkAnswers',
						id: 'checkAnswers',
					}],
				}))
			}
		}

		if (state.step === 'check feedback') {
			const timespend = new Date().valueOf() - state.startedat.valueOf()

			const db = await databaseCurrent()
			const userQuestion = await db.collections.userquestions.findOne({
				selector: {
					questionid: state.question.id,
				},
			}).exec()

			let newLevel = state.question.userquestion.level
			let newSessionlevel = state.question.userquestion.sessionlevel
			let newStudyingSession = ensureDefined(state.studyingSession)

			if (isStudyingSessionLeitner(state.studyingSession)) {
				newLevel = calcLevel([state.feedback], newLevel)
			}

			if (isStudyingSessionHirn(state.studyingSession)) {
				const clonedData = clone(state.studyingSession.data)
				if (state.feedback === 'right') {
					// boost questions known by the user. If user
					// answers 3 times right in a row and previous one
					// wasn't wrong, then we assume that the question
					// is known good enough
					let questionBoosted = false
					if (newLevel < 5) {
						// reha mode is mode for people with learning
						// difficulty, therefore need one right answer more
						const rehaMode = isRehaMode(state.studyingSession.data.maxdifficulty)
						const rightAnswersNeeded = rehaMode ? 4 : 3

						const lastAnswers = await db.collections.useranswers.find({
							selector: {
								questionid: state.question.id,
							},
							sort: [{
								createdat: 'desc',
							}],
							// current answer isn't saved yet
							limit: rightAnswersNeeded,
						}).exec()

						const [
							restAnswers,
							[fourthAnswer], // or fifth for reha
						] = splitAt(lastAnswers, rightAnswersNeeded - 1)
						if (fourthAnswer?.answer !== 'wrong' && equals(
							restAnswers.map(prop('answer')),
							times(rightAnswersNeeded - 1, () => 'right'),
						)) {
							questionBoosted = true
							newLevel = 5
							newSessionlevel = 0
							// remove question from the session
							clonedData.questionspackage.splice(
								clonedData.questionspackage.indexOf(
									state.question.id), 1)
						}
					}

					if (questionBoosted === false) {
						// level 1-3: 3 right answers are needed
						// level 4: 2 right answers are needed
						// level 5-7: 1 right answer is needed
						const maxSessionlevel = clamp(5 - newLevel, {
							max: 2,
						})
						const learnSessionComplete = newSessionlevel >= maxSessionlevel
						if (learnSessionComplete) {
							newSessionlevel = 0
							newLevel = clamp(calcLevel(['right'], newLevel), {
								max: 7,
							})
							// remove question from the session
							clonedData.questionspackage.splice(
								clonedData.questionspackage.indexOf(
									state.question.id), 1)
						} else {
							newSessionlevel++
							// not learned yet
							moveToEndInPlace(
								state.question.id,
								clonedData.questionspackage,
							)
						}
					}
					newStudyingSession = await state.studyingSession.atomicPatch({
						offlinestate: 'updated',
						data: clonedData,
					})
				} else {
					// in hirn learning system we have many repetition
					// cycles. Therefore don't punish user too much on
					// wrong answer
					newLevel = clamp(Math.floor((newLevel + 1) / 2), {
						min: 1,
					})

					// if the question was never answered right
					// for current session, then we ask it again
					// and again until user answers it right.
					// Otherwise move it to the end of session
					if (newSessionlevel > 0) {
						moveToEndInPlace(
							state.question.id,
							clonedData.questionspackage,
						)
						newStudyingSession = await state.studyingSession.atomicPatch({
							offlinestate: 'updated',
							data: clonedData,
						})
					}
				}
			}

			// userquestions are created lazily.
			// Update statistics
			if (userQuestion) {
				await updateUserQuestion({
					id: userQuestion.id,
					level: newLevel,
					sessionlevel: newSessionlevel,
					lastansweredat: new Date().toISOString(),
				})
			} else {
				await insertUserQuestion({
					level: newLevel,
					sessionlevel: newSessionlevel,
					questionid: state.question.id,
				})
			}

			await insertUserAnswer({
				questionid: state.question.id,
				answer: state.feedback,
				answered: state.selectedAnswers.map(prop('id')),
				timespend,
			})

			// on self assessment questions
			// no external feedback is needed
			if (['info', 'self assessment'].includes(state.questionType)) {
				setState({
					step: 'fetch next question',
				})
				return
			}
			const feedbackCounter = globalFeedbackCounter++
			setState(current => ({
				step: 'noop',
				studyingSession: newStudyingSession,
				contents: [
					...current.contents,
					{
						type: 'feedback',
						id: 'feedback',
						feedbackCounter,
						feedback: state.feedback,
						timespend,
					},
				],
			}))
		}
	}, [state.step])

	return (
		<Content variant="full" addClassName="h-full bg-blue-50">
			<div className="grid grid-rows-3-center h-full">
				{
					state.studyingSession?.system === 'leitner'
						? <LeitnerHeader studyingSession={state.studyingSession} />
						: <div></div>
				}
				<Virtuoso
					className="no-scrollbar"
					data={state.contents}
					followOutput="smooth"
					alignToBottom={true}
					overscan={10000}
					itemContent={(index, message): React.ReactElement => {
						const { questionType } = state as StudyingChatState & { step: 'show question' }
						// prevent clicks in the history after user got feedback
						const enableStateChange = !state.contents.find(content => content.type === 'feedback')
						switch (message.type) {
							case 'question': return (
								<div
									key={message.id}
									className="max-w-90p p-3 flex flex-row items-center animate-toBottom"
									data-test-id="question"
								>
									<Avatar />
									<div className="ml-3 bg-white py-2 px-4 shadow rounded-xl overflow-auto">
										<ReadOnlyEditor content={message.question.content} />
									</div>
								</div>
							)
							case 'selfAssessmentAnswer': return message.showed
								? (
									<div
										key={message.id}
										className="max-w-90p p-3 flex flex-row items-center animate-toBottom"
										data-test-id="answer selfAssessmentAnswer"
									>
										<Avatar />
										<div className="ml-3 bg-white py-2 px-4 shadow rounded-xl overflow-auto">
											<ReadOnlyEditor content={message.answer.content} />
										</div>
									</div>
								)
								: (
									<div
										key={message.id}
										className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
										data-test-id="answer selfAssessmentAnswer"
									>
										<div
											className="bg-indigo-100 py-2 px-4 shadow rounded-xl cursor-pointer"
											onClick={() => setState(current => ({
												contents: [
													...replaceByIdOrAdd(current.contents, {
														id: message.id,
														showed: true,
													}),
													{
														type: 'yesNoAnswer',
														id: 'yesNoAnswer',
													},
												],
											}))}
										>
											Antwort aufdecken
										</div>
									</div>
								)
							case 'answer': return (
								<div
									key={message.id}
									className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
									data-test-id="answer"
								>
									<div
										className={`${state.selectedAnswers.includes(message.answer) ? 'bg-indigo-400/50' : 'bg-indigo-100'} py-2 px-4 shadow rounded-xl cursor-pointer overflow-auto`}
										onClick={async (): Promise<void> => (enableStateChange ? setState(current => {
											const selectedAnswers = current.selectedAnswers.includes(message.answer)
												? difference(current.selectedAnswers, [message.answer])
												: [...current.selectedAnswers, message.answer]
											const feedback = equals(state.answers.map(prop('type')).filter(type => type === 'right'), selectedAnswers.map(prop('type')))
												? 'right'
												: 'wrong'
											return {
												step: questionType === 'multiple' ? current.step : 'check feedback',
												feedback,
												selectedAnswers,
											}
										}) : undefined)
										}
									>
										<ReadOnlyEditor content={message.answer.content} />
									</div>
								</div>
							)
							case 'yesNoAnswer': return (
								<React.Fragment key={message.id}>
									<div
										className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
										data-test-id="answer yesNoAnswer right"
									>
										<div
											className="bg-indigo-100 py-2 px-4 shadow rounded-xl cursor-pointer"
											onClick={() => enableStateChange && setState({
												step: 'check feedback',
												feedback: 'right',
											})}
										>
											Ich habe die Antwort gewusst 💪
										</div>
									</div>
									<div
										className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
										data-test-id="answer yesNoAnswer wrong"
									>
										<div
											className="bg-indigo-100 py-2 px-4 shadow rounded-xl cursor-pointer"
											onClick={() => enableStateChange && setState({
												step: 'check feedback',
												feedback: 'wrong',
											})}
										>
											Ich habe die Antwort nicht gewusst 💔
										</div>
									</div>
								</React.Fragment>
							)
							case 'checkAnswers': return (
								<div
									className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
									data-test-id="answer checkAnswers"
								>
									<div
										className={`${state.selectedAnswers.length > 0 ? 'bg-indigo-100 cursor-pointer' : 'bg-grey-100'} py-2 px-4 shadow rounded-xl`}
										onClick={enableStateChange && state.selectedAnswers.length > 0
											? () => setState({
												step: 'check feedback',
											})
											: noop}
									>
										Beantworten
									</div>
								</div>
							)
							case 'feedback': return (
								<>
									<div className="max-w-90p p-3 flex flex-row" onClick={() => setState({
										step: 'fetch next question',
									})}>
										<Avatar />
										<div className="ml-3 bg-white py-2 px-4 shadow rounded-xl overflow-auto">
											{
												message.feedback === 'right'
													? (
														<>
															Sehr gut! Benötigte Zeit:&nbsp;
															{(message.timespend / 1000).toLocaleString()} Sekunden
															<div>{getSuccessImage(message.feedbackCounter)}</div>
														</>
													)
													: (
														<>
															Schade! Benötigte Zeit:&nbsp;
															{(message.timespend / 1000).toLocaleString()} Sekunden
															<div>{getFailImage(message.feedbackCounter)}</div>
														</>
													)
											}
										</div>
									</div>
									<div className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom" onClick={() => setState({
										step: 'fetch next question',
									})}>
										<div
											className="bg-indigo-100 py-2 px-4 shadow rounded-xl cursor-pointer"
										>
											Weiter
										</div>
									</div>
								</>
							)
							case 'hirnAppMessage': return (
								<div
									key={message.id}
									className="max-w-90p p-3 flex flex-row items-center animate-toBottom"
									data-test-id="hirnAppMessage"
								>
									<Avatar />
									<div className="ml-3 bg-white py-2 px-4 shadow rounded-xl overflow-auto">
										{message.message}
									</div>
								</div>
							)
							case 'userMessage': return (
								<div
									key={message.id}
									className="max-w-90p ml-auto p-3 flex items-center justify-start flex-row-reverse animate-toBottom"
									data-test-id="userMessage"
								>
									<div
										className="bg-indigo-100 py-2 px-4 shadow rounded-xl cursor-pointer overflow-auto"

									>
										{message.message}
									</div>
								</div>
							)
							default: throw new Error(`unknown case for message ${JSON.stringify(message)}`)
						}
					}}
				/>
				<div></div>
			</div>
		</Content>
	)
}

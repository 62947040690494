import {
	outdent,
} from 'outdent'
import {
	pick,
} from 'remeda'
import {
	query,
} from '../../database/GraphQl'
import {
	UserQuestion,
} from './userquestionsDb'

function fixUserQuestion(userQuestion: UserQuestion): UserQuestion {
	const fixed: UserQuestion = {
		...userQuestion,
		offlinestate: 'insync',
	}
	return fixed
}

export async function loadUserQuestions(): Promise<UserQuestion[]> {
	const result = await query<{ userquestions: UserQuestion[] }>({
		query: outdent`
			query LoadUserQuestions {
				userquestions {
					id
					updatedat
					questionid
					state
					difficulty
					level
					sessionlevel
					lastansweredat
				}
			}`,
	})
	return result.userquestions.map(fixUserQuestion)
}

export async function loadUserQuestionById(id: string): Promise<UserQuestion | null> {
	const result = await query<{ userquestions: UserQuestion[] }>({
		query: outdent`
			query LoadUserQuestion($ids: [uuid!]!) {
				userquestions(where: {id: {_in: $ids}}) {
					id
					updatedat
					questionid
					state
					difficulty
					level
					sessionlevel
					lastansweredat
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.userquestions.map(fixUserQuestion)[0] ?? null
}

export async function insertUserQuestionGql(
	userQuestion: UserQuestion,
): Promise<UserQuestion> {
	const object = pick(userQuestion, [
		'id',
		'questionid',
		'state',
		'level',
		'sessionlevel',
		'lastansweredat',
	])

	const result = await query<{ insert_userquestion: UserQuestion }>({
		query: outdent`
			mutation InsertUserQuestion($object: userquestions_insert_input!) {
				insert_userquestion(object: $object) {
					id
					updatedat
					questionid
					state
					level
					sessionlevel
					lastansweredat
				}
			}`,
		variables: {
			object,
		},
	})
	return fixUserQuestion(result.insert_userquestion)
}

export async function updateUserQuestionGql(
	{
		id,
		level,
		sessionlevel,
		lastansweredat,
	}: UserQuestion,
): Promise<UserQuestion> {
	const result = await query<{ update_userquestion: UserQuestion }>({
		query: outdent`
			mutation UpdateUserQuestion(
				$id: uuid!,
				$updatedat: timestamptz!,
				$level: Int!,
				$sessionlevel: Int!,
				$lastansweredat: timestamptz!
			) {
				update_userquestion(pk_columns: {id: $id}, _set: {
					updatedat: $updatedat,
					level: $level,
					sessionlevel: $sessionlevel,
					lastansweredat: $lastansweredat
				}) {
					updatedat
					level
					sessionlevel
					lastansweredat
				}
			}`,
		variables: {
			id,
			updatedat: new Date().toISOString(),
			level,
			sessionlevel,
			lastansweredat,
		},
	})
	return fixUserQuestion(result.update_userquestion)
}

export async function deleteUserQuestionGql(id: string): Promise<void> {
	await query<void>({
		query: outdent`
			mutation DeleteUserQuestion($id: uuid!) {
				delete_userquestion(id: $id) {
					id
				}
			}`,
		variables: {
			id,
		},
	})
}

export async function questionsRequestPackage(
	studyingsessionid: string,
): Promise<Pick<UserQuestion, 'questionid'>[]> {
	const result = await query<{
		questions_request_package: Pick<UserQuestion, 'questionid'>[]
	}>({
		query: outdent`
			query QuestionsRequestPackage($studyingsessionid: uuid!) {
				questions_request_package(args: {
					studyingsessionid: $studyingsessionid
				}) {
					questionid
				}
			}`,
		variables: {
			studyingsessionid,
		},
	})
	return result.questions_request_package
}

import {
	Remove,
	ensureDefined,
	toString,
} from '@hirn.app/shared'
import {
	JSONContent,
} from '@tiptap/react'
import {
	outdent,
} from 'outdent'
import {
	pick,
} from 'remeda'
import {
	query,
} from '../../database/GraphQl'
import {
	Answer,
} from '../answer/answersDb'
import {
	Question,
} from './questionsDb'

interface Response {
	questions: Question[]
}

export async function loadQuestions(): Promise<Question[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadQuestions {
				questions {
					id
					setid
					createdat
					updatedat
					content
				}
			}`,
	})
	return result.questions
}

export async function loadQuestionById(id: string): Promise<Question | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadQuestion($ids: [uuid!]!) {
				questions(where: {id: {_in: $ids}}) {
					id
					setid
					createdat
					updatedat
					content
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.questions[0] ?? null
}

export async function createQuestion(
	setid: string,
	question: JSONContent,
	answers: Pick<Answer, 'content' | 'type'>[],
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation CreateQuestion($setid: uuid!, $content: jsonb!, $answers: answers_arr_rel_insert_input!) {
				insert_question(object: {setid: $setid, content: $content, answers: $answers}) {
					id
				}
			}`,
		variables: {
			setid,
			content: question,
			answers: {
				data: answers,
			},
		},
	})
}

type EditableContent = Pick<Answer,
	| 'id'
	| 'content'
	| 'type'
>

export async function updateQuestionContent({
	questionid,
	question,
	addedAnswers,
	changedAnswers,
	removedAnswers,
}: {
	questionid: string
	question: JSONContent | undefined
	addedAnswers: Remove<EditableContent, 'id'>[]
	changedAnswers: EditableContent[]
	removedAnswers: string[]
}): Promise<void> {
	if (question === undefined
		&& addedAnswers.length === 0
		&& changedAnswers.length === 0
		&& removedAnswers.length === 0) {
		return Promise.resolve()
	}

	const queries = ['mutation UpdateQuestion {']
	if (question) {
		const content = toString({ content: question })
		queries.push(outdent`
			update_question(pk_columns: {id: "${questionid}"}, _set: ${content}) {
				id
			}`)
	}
	if (addedAnswers.length > 0) {
		const content = toString(addedAnswers.map(answer => ({
			questionid,
			content: answer.content,
			type: answer.type,
		})))
		queries.push(outdent`
			insert_answers(objects: ${content}) {
				returning {
					id
				}
			}`)
	}

	for (let i = 0; i < changedAnswers.length; i++) {
		const updateanswer = ensureDefined(changedAnswers[i])
		const content = toString(pick(updateanswer, [
			'content',
			'type',
		]))
		queries.push(outdent`
			update_answer${i}: update_answer(pk_columns: {id: "${updateanswer.id}"}, _set: ${content}) {
				id
			}`)
	}

	if (removedAnswers.length > 0) {
		queries.push(outdent`
			delete_answers(where: {id: {_in: ${JSON.stringify(removedAnswers)}}}) {
				affected_rows
			}`)
	}
	queries.push('}')
	await query<Response>({
		query: queries.join('\n'),
	})
}

export async function updateQuestion(
	id: string,
	content: Record<string, unknown>,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdateQuestion($id: uuid!, $content: jsonb!) {
				update_question(pk_columns: {id: $id}, _set: {content: $content}) {
					id
				}
			}`,
		variables: {
			id,
			content,
		},
	})
}

export async function deleteQuestion(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteQuestion($id: uuid!) {
				delete_question(id: $id) {
					id
				}
			}`,
		variables: {
			id,
		},
	})
}

import React, {
	useCallback,
} from 'react'
import {
	MakeRequired,
	leftJoinObservable,
} from '@hirn.app/shared'
import {
	map,
	of,
	switchMap,
} from 'rxjs'
import {
	prop,
} from 'remeda'
import {
	Navigate,
} from 'react-router-dom'
import {
	Button,
} from '../../components/Button'
import {
	useObserveDb,
} from '../../utils/useObserveDb'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	Content,
} from '../../components/Content'
import {
	TeamLabel,
} from '../team/TeamLabel'
import {
	H1,
} from '../../components/H1'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import {
	getUserIdEnsured,
} from '../../security/keycloak'
import {
	MemberInvitationTable,
} from '../../components/table/MemberInvitationTable'
import {
	inviteTeammember,
} from './teamMembersGql'
import {
	organizationOwnerFilter,
} from '../organizationmemberrole/organizationmemberrolesDb'
import { Team } from '../team/teamsDb'

export function AddTeamMembers(): React.ReactElement {
	const { teamid } = useParamsDefined<'teamid'>()

	const currentUserOrganizationRoles = useObserveDb(
		db => db.collections.organizationmemberroles
			.getRoleNamesByTeamIdAndUserId(teamid, getUserIdEnsured()),
		undefined,
	)

	const team = useObserveDb<Team | undefined>(
		db => db.collections.teams.findOne(teamid)
			.$
			.pipe(map(q => q?.toJSON())),
		undefined,
	)

	const members = useObserveDb(
		db => db.collections.teams.find({ selector: { id: teamid } }).$
			.pipe(switchMap(([membersteam]) => {
				if (!membersteam) {
					return of([])
				}

			const organizationmembers = db.collections.teammembers.find({ selector: { teamid } }).$
				.pipe(switchMap(teammembers => toJson(db.collections.organizationmembers.find({
						selector: {
							organizationid: {
								$eq: membersteam.organizationid,
							},
							userid: {
								$nin: teammembers.map(member => member.userid),
							},
						},
					}).$)))

			const users = toJson(db.collections.users.find().$)
			return leftJoinObservable(
				'user',
				organizationmembers, 'userid',
				users, 'id',
			)
		})), [], [teamid],
	)

	const inviteMember = useCallback(async (userEmail: string) => {
		await inviteTeammember(teamid, userEmail)
	}, [teamid])

	const organizationMembers = members.filter((member): member is MakeRequired<typeof member, 'user'> => member.user !== undefined)

	if (currentUserOrganizationRoles === undefined) {
		return <></>
	}

	const currentUserCanManageMembers = organizationOwnerFilter(
		currentUserOrganizationRoles,
	).length > 0

	if (currentUserCanManageMembers === false) {
		return (
			<Navigate
				to={AppRoutes.teammember.list.url(teamid)}
				replace={true}
			/>
		)
	}

	if (!team) {
		return <></>
	}

	const teamInfo = {
		teamid,
		organizationid: team.organizationid,
	}

	return (
		<Content variant="wide">
			<H1>
				Team:&nbsp;<TeamLabel teamid={teamid} />
			</H1>
			<MemberInvitationTable users={organizationMembers.map(prop('user'))} invitation={inviteMember} teamInfo={teamInfo} />

			<div className="flex justify-center text-right">
				<div className="container">
					<Button
						to={AppRoutes.teammember.list.url(teamid)}
						color="secondary"
						addClassName="mr-2"
					>
						Abbrechen
					</Button>
				</div>
			</div>
		</Content>
	)
}

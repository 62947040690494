import {
	MakeOptional,
	MakeRequired,
} from '@hirn.app/shared'
import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
} from 'rxjs'
import {
	DbIdChanges,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	OfflineCapable,
	OfflineEntity,
	StaticOffline,
	createOfflineCapable,
	getActiveState,
	isUpdateValid,
	offlineCapableSchema,
} from '../../Offline'
import {
	toJsonObject,
} from '../../utils/RxDbUtils'
import {
	deleteUserQuestionGql,
	insertUserQuestionGql,
	loadUserQuestionById,
	loadUserQuestions,
	updateUserQuestionGql,
} from './userquestionsGql'

type UserQuestionsChangeType = DbIdChanges<'userquestions'>

export type ChangeUserQuestions = UserQuestionsChangeType['All']

export interface UserQuestion extends OfflineCapable {
	questionid: string
	state: string // enabled | hidden
	difficulty: number
	level: number
	sessionlevel: number
	lastansweredat: string
}

interface StaticMethods extends StaticOffline<UserQuestion> {
	getById(id: string): Promise<UserQuestion | null>
	updateLevel(fromLevel: number, toLevel: number): Promise<void>
	getActive(): Observable<UserQuestion[]>
}

export type UserQuestionCollection = RxCollection<
	UserQuestion,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(this: UserQuestionCollection, id: string): Promise<UserQuestion | null> {
		const userQuestion = await this.findOne(id).exec()
		return userQuestion ? toJsonObject(userQuestion) : null
	},
	async updateLevel(
		this: UserQuestionCollection,
		fromLevel: number,
		toLevel: number,
	): Promise<void> {
		await this.find({
			selector: {
				level: fromLevel,
			},
		}).update({
			$set: {
				offlinestate: 'updated',
				level: toLevel,
			},
		})
	},
	getActive(this: UserQuestionCollection): Observable<UserQuestion[]> {
		return getActiveState(this)
	},
	async handleOffline({ inserted, updated, deleted }) {
		// TODO: probably we can skip insert and update for deleted entities?
		await Promise.allSettled(inserted.map(async userQuestion => {
			const result = await insertUserQuestionGql(toJsonObject(userQuestion))
			await userQuestion.atomicPatch({
				...result,
				offlinestate: 'insync',
			})
		}))

		await Promise.allSettled(updated.map(async userQuestion => {
			const result = await updateUserQuestionGql(toJsonObject(userQuestion))
			await userQuestion.atomicPatch({
				...result,
				offlinestate: 'insync',
			})
		}))

		await Promise.allSettled(deleted.map(async userQuestion => {
			await deleteUserQuestionGql(userQuestion.id)
			await userQuestion.remove()
		}))
	},
}

const userQuestionsSchema = offlineCapableSchema<RxJsonSchema<UserQuestion>>({
	version: 0,
	type: 'object',
	required: [
		'questionid',
		'state',
		'difficulty',
		'level',
		'sessionlevel',
	],
	properties: {
		questionid: {
			type: 'string',
			maxLength: 36,
		},
		state: {
			type: 'string',
		},
		difficulty: {
			type: 'number',
		},
		level: {
			type: 'number',
		},
		sessionlevel: {
			type: 'number',
		},
		lastansweredat: {
			type: 'string',
			format: 'date-time',
		},
	},
	indexes: ['questionid'],
})

export const userQuestionCollection: Record<'userquestions', RxCollectionCreator<StaticMethods>> = {
	userquestions: {
		schema: userQuestionsSchema,
		statics,
	},
}

export async function pullUserQuestions(): Promise<void> {
	const userQuestions = await loadUserQuestions()
	const db = await databaseCurrent()
	await db.userquestions.bulkInsert(userQuestions)
}

export async function pullUserQuestion(id: string): Promise<void> {
	const userQuestion = await loadUserQuestionById(id)
	if (userQuestion) {
		const db = await databaseCurrent()
		const currentUserQuestion = await db.userquestions.findOne(id).exec()
		if (isUpdateValid(userQuestion, currentUserQuestion)) {
			await db.userquestions.atomicUpsert(userQuestion)
		}
	} else {
		await logError(`pullUserQuestion(${id}) returns ${userQuestion}`)
	}
}

export async function handleInsertUserQuestion(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	const userQuestionUnknown = await db.collections.userquestions.getById(id) === null
	if (userQuestionUnknown) {
		await pullUserQuestion(id)
	}
}

export async function handleInsertUserQuestionsChange(
	change: UserQuestionsChangeType['Insert'],
): Promise<void> {
	await handleInsertUserQuestion(change.payload.id)
}

export async function handleUpdateUserQuestionsChange(
	change: UserQuestionsChangeType['Update'],
): Promise<void> {
	await pullUserQuestion(change.payload.id)
}

export async function handleDeleteUserQuestionsChange(
	change: UserQuestionsChangeType['Delete'],
): Promise<void> {
	const userQuestionid = change.payload.id
	const db = await databaseCurrent()
	const userQuestion = await db.collections.userquestions
		.findOne(userQuestionid).exec()
	await userQuestion?.remove()
}

export async function insertUserQuestion(
	userQuestion: MakeOptional<OfflineEntity<UserQuestion>, 'state' | 'difficulty' | 'level' | 'sessionlevel' | 'lastansweredat'>,
): Promise<UserQuestion> {
	const db = await databaseCurrent()
	const now = new Date().toISOString()
	return (await db.collections.userquestions.insert(createOfflineCapable({
		updatedat: now,
		state: 'enabled',
		difficulty: 30,
		level: 1,
		sessionlevel: 0,
		lastansweredat: now,
		...userQuestion,
	}))).toJSON()
}

export async function updateUserQuestion(
	{ id, ...userQuestion }: MakeRequired<Partial<UserQuestion>, 'id'>,
): Promise<void> {
	const db = await databaseCurrent()
	const question = await db.userquestions.findOne(id).exec()
	await question?.atomicPatch({
		...userQuestion,
		offlinestate: 'updated',
	})
}

export async function deleteUserQuestionByQuestionId(
	questionid: string,
): Promise<void> {
	const db = await databaseCurrent()
	await db.collections.userquestions.find({
		selector: {
			questionid,
		},
	}).remove()
}

import React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { InputError } from './InputError'

interface OptionItemProps {
	label: string
	value: string
}

interface OptionProps {
	register: UseFormRegisterReturn
	label: string
	options: OptionItemProps[]
	error?: string
}

export function Option({
	register,
	label,
	options,
	error,
}: OptionProps): React.ReactElement {
	return (
		<fieldset className="relative z-0 w-full p-px mb-5">
			<legend
				className="absolute text-gray-700 scale-75 -top-3 origin-0"
			>
				{label}
			</legend>
			<div className="block pt-3 pb-2">
				{
					options.map(option => <label key={option.value} className="block">
						<input
							{...register}
							type="radio"
							value={option.value}
							className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black" />{option.label}
					</label>)
				}
			</div>
			<InputError error={error} />
		</fieldset>
	)
}

import React, { useCallback } from 'react'
import { map, switchMap } from 'rxjs/operators'
import { Navigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { useObserveDb } from '../../utils/useObserveDb'
import { AppRoutes } from '../AppRoutes'
import { Content } from '../../components/Content'
import { H1 } from '../../components/H1'
import { useParamsDefined } from '../../utils/useParamsDefined'
import { getUserIdEnsured } from '../../security/keycloak'
import { OrganizationLabel } from '../organization/OrganizationLabel'
import { organizationMemberManagerFilter } from '../organizationmemberrole/organizationmemberrolesDb'
import { toJson } from '../../utils/RxDbUtils'
import { MemberInvitationTable } from '../../components/table/MemberInvitationTable'
import { inviteOrganizationmember } from './organizationMembersGql'

export function AddOrganizationMembers(): React.ReactElement {
	const { organizationid } = useParamsDefined<'organizationid'>()
	const currentUserCanManageMember = useObserveDb(db => db.organizationmembers
		.getWithRoles({
			userids: [getUserIdEnsured()],
			organizationids: [organizationid],
		})
		.pipe(map(members => organizationMemberManagerFilter(members
			.flatMap(({ roles }) => roles)).length > 0,
		)),
		undefined,
		[organizationid, getUserIdEnsured()],
	)

	const inviteMember = useCallback(async (userEmail: string) => {
		await inviteOrganizationmember(organizationid, userEmail)
	}, [organizationid])

	const users = useObserveDb(
		db => db.collections.organizationmembers
			.find({ selector: { organizationid } }).$
			.pipe(switchMap(members => toJson(db.users.find({
					selector: {
						id: {
							$nin: members.map(member => member.userid),
						},
					},
				}).$))),
		[], [organizationid],
	)

	if (currentUserCanManageMember === undefined) {
		return <></>
	}

	if (currentUserCanManageMember === false) {
		return (
			<Navigate
				to={AppRoutes.organization.list.url()}
				replace={true}
			/>
		)
	}

	return (
		<Content variant="wide">
			<H1>
				Organisation:&nbsp;<OrganizationLabel organizationid={organizationid} />
			</H1>
			<MemberInvitationTable
				users={users}
				invitation={inviteMember}
				organizationid={organizationid}
			/>

			<div className="flex justify-center text-right">
				<div className="container">
					<Button
						to={AppRoutes.organizationmember.list.url(organizationid)}
						color="secondary"
						addClassName="mr-2"
					>
						Abbrechen
					</Button>
				</div>
			</div>
		</Content>
	)
}

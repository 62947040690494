import { ensureDefined } from '@hirn.app/shared'
import React, { useEffect, useState } from 'react'

const words = ['in der Schule!', 'im Beruf!', 'im Studium!']

export function Typingwords(): React.ReactElement {
	// https://levelup.gitconnected.com/typing-effect-in-react-56697def0473
	const [index, setIndex] = useState(0)
	const [subIndex, setSubIndex] = useState(0)
	const [reverse, setReverse] = useState(false)

	useEffect(() => {
		if (
			subIndex === ensureDefined(words[index]).length
			&& index !== words.length
			&& !reverse
		) {
			setReverse(true)
			return
		}

		if (subIndex === 0 && reverse) {
			setReverse(false)
			if (index === words.length - 1) {
				setIndex(0)
				return
			}
			setIndex(prev => prev + 1)
			return
		}
		const timeout = setTimeout(() => {
			setSubIndex(prev => prev + (reverse ? -1 : 1))
		}, 100)
		return (): void => clearTimeout(timeout)
	}, [subIndex, index, reverse])

	return <div>{ensureDefined(words[index]).substring(0, subIndex)}|</div>
}

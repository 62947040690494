import React, { useMemo } from 'react'
import { formatInterval, leftJoinAggObservable, leftJoinObservable } from '@hirn.app/shared'
import { Navigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Table, TableHeader, TableItem } from '../../components/table/Table'
import { useObserveDb } from '../../utils/useObserveDb'
import { Content } from '../../components/Content'
import { AppRoutes } from '../AppRoutes'
import {
	StudyingSession,
	deleteStudyingSession,
} from './studyingsessionsDb'
import {
	isRehaMode,
} from '../studying/studying'

function learnSystemText(studyingSession: StudyingSession) {
	if (studyingSession.system === 'leitner') {
		return 'Leitner'
	}
	if (studyingSession.system === 'hirn') {
		if (isRehaMode(studyingSession.data.maxdifficulty)) {
			return 'Hirn (Reha)'
		}
		return 'Hirn'
	}
	return 'Unbekannt'
}

export function StudyingSessionList(): React.ReactElement {
	const studyingSessions = useObserveDb(
		db => {
			const sessionSets = leftJoinObservable(
				'set',
				db.studyingsessionsets.getActive(), 'setid',
				db.sets.find().$, 'id',
			)

			const sessions = db.studyingsessions.getActive()
			return leftJoinAggObservable(
				'sets',
				sessions, 'id',
				sessionSets, 'studyingsessionid',
			)
		},
		undefined,
	)

	/* eslint-disable react/jsx-key */
	const headers = useMemo(() => [
		<TableHeader>Lernsitzung</TableHeader>,
		<TableHeader addClassName="sm:text-center">Lernsystem</TableHeader>,
		<TableHeader addClassName="sm:text-center">Lernsätze</TableHeader>,
		<TableHeader addClassName="sm:text-center">Optionen</TableHeader>,
	], [])

	const rows = useMemo(() => studyingSessions?.map(studyingSession => [
		<TableItem>{formatInterval(studyingSession.createdat, studyingSession.updatedat, {
			precision: 'minutes',
			locale: 'de-DE',
		})}</TableItem>,
		<TableItem addClassName="sm:text-center">{learnSystemText(studyingSession)}</TableItem>,
		<TableItem addClassName="sm:text-center">{studyingSession.sets.map(studyingSet => studyingSet.set?.name).join(', ')}</TableItem>,
		<TableItem addClassName="flex flex-wrap justify-center">
			{
				<>
					<Button color="primary" addClassName="m-1" to={AppRoutes.studyingSession.study.url(studyingSession.id)}>Lernen</Button>
					<Button color="secondary" addClassName="m-1" onClick={() => deleteStudyingSession(studyingSession.id)}>Löschen</Button>
				</>
			}
		</TableItem>,
	]), [studyingSessions])
	/* eslint-enable react/jsx-key */

	if (rows === undefined) {
		return <></>
	}

	if (rows.length === 0) {
		return <Navigate to={AppRoutes.studyingSession.create.url()} />
	}

	return (
		<Content variant="wide">
			<Table headers={headers} rows={rows} />
			<div className="flex justify-center text-right">
				<div className="container">
					<Button
						color="secondary"
						to={AppRoutes.studyingSession.create.url()}
					>
						Lernsitzung anlegen
					</Button>
				</div>
			</div>
		</Content>
	)
}

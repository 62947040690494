import {
	leftJoinObservable,
} from '@hirn.app/shared'
import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import {
	Observable,
	combineLatest,
	switchMap,
} from 'rxjs'
import {
	DbIdChangesImmutable,
	database,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	toJson,
} from '../../utils/RxDbUtils'
import {
	handleInsertOrganization,
} from '../organization/organizationsDb'
import {
	Package,
	handleInsertPackage,
} from '../package/packagesDb'
import {
	loadOrganizationPackageLicenseById,
	loadOrganizationPackageLicenses,
} from './organizationpackagelicensesGql'

type OrganizationMembersChangeType = DbIdChangesImmutable<'organizationpackagelicenses'>

export type ChangeOrganizationPackageLicenses = OrganizationMembersChangeType['All']

export interface OrganizationPackageLicense {
	id: string
	organizationid: string
	packageid: string
	userid: string
}

interface OrganizationPackageLicenseWithPackage extends OrganizationPackageLicense {
	package?: Package
}

interface StaticMethods {
	getById(id: string): Promise<OrganizationPackageLicense | null>
	getByUserIds(userids: string[]): Observable<OrganizationPackageLicense[]>
	getByUserIdsWithPackage(userids: string[]): Observable<OrganizationPackageLicenseWithPackage[]>
}

export type OrganizationPackageLicenseCollection = RxCollection<
	OrganizationPackageLicense, unknown, StaticMethods
>

const statics: StaticMethods = {
	async getById(
		this: OrganizationPackageLicenseCollection,
		id: string,
	): Promise<OrganizationPackageLicense | null> {
		const pakage = await this.findOne(id).exec()
		return pakage?.toJSON() ?? null
	},
	getByUserIds(
		this: OrganizationPackageLicenseCollection,
		userids: string[],
	): Observable<OrganizationPackageLicense[]> {
		const organizationPackageLicenses$ = toJson(this.find({
			selector: {
				userid: {
					$in: userids,
				},
			},
			sort: [{
				packageid: 'asc',
			}],
		}).$)
		return organizationPackageLicenses$
	},
	getByUserIdsWithPackage(
		this: OrganizationPackageLicenseCollection,
		userids: string[],
	): Observable<OrganizationPackageLicenseWithPackage[]> {
		const organizationPackageLicenses$ = this.getByUserIds(userids)
		const packages$ = combineLatest([
			database(),
			organizationPackageLicenses$,
		])
			.pipe(
				switchMap(([db, licenses]) => db.collections.packages
					.getByIds(licenses.map(license => license.packageid))),
			)

		return leftJoinObservable(
			'package',
			organizationPackageLicenses$, 'packageid',
			packages$, 'id',
		)
	},
}

export const organizationPackageLicensesSchema: RxJsonSchema<OrganizationPackageLicense> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'organizationid',
		'packageid',
		'userid',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		organizationid: {
			type: 'string',
		},
		packageid: {
			type: 'string',
			maxLength: 36,
		},
		userid: {
			type: 'string',
			maxLength: 36,
		},
	},
	indexes: ['userid', 'packageid'],
}

export const organizationPackageLicenseCollection: Record<'organizationpackagelicenses', RxCollectionCreator<StaticMethods>> = {
	organizationpackagelicenses: {
		schema: organizationPackageLicensesSchema,
		statics,
	},
}

export async function pullOrganizationPackageLicenses(): Promise<void> {
	const organizationPackageLicenses = await loadOrganizationPackageLicenses()
	const db = await databaseCurrent()
	await db.organizationpackagelicenses
		.bulkInsert(organizationPackageLicenses)
}

export async function pullOrganizationPackageLicense(id: string): Promise<void> {
	const organizationPackageLicense = await loadOrganizationPackageLicenseById(id)
	if (organizationPackageLicense) {
		const db = await databaseCurrent()
		await db.organizationpackagelicenses.atomicUpsert(organizationPackageLicense)
	} else {
		await logError(`pullOrganizationPackageLicense(${id}) returns ${organizationPackageLicense}`)
	}
}

async function handleInsertOrganizationPackageLicense(
	id: string,
): Promise<void> {
	const db = await databaseCurrent()
	let organizationPackageLicense = await db.collections.organizationpackagelicenses.getById(id)
	if (!organizationPackageLicense) {
		await pullOrganizationPackageLicense(id)
		organizationPackageLicense = await db.collections.organizationpackagelicenses.getById(id)
	}
	if (organizationPackageLicense?.packageid) {
		await handleInsertPackage(organizationPackageLicense.packageid)
	}
	if (organizationPackageLicense?.organizationid) {
		await handleInsertOrganization(organizationPackageLicense.organizationid)
	}
}

export async function handleInsertOrganizationPackageLicensesChange(
	change: OrganizationMembersChangeType['Insert'],
): Promise<void> {
	await handleInsertOrganizationPackageLicense(change.payload.id)
}

export async function handleDeleteOrganizationPackageLicensesChange(
	change: OrganizationMembersChangeType['Delete'],
): Promise<void> {
	const organizationPackageLicenseId = change.payload.id
	const db = await databaseCurrent()
	const organizationPackageLicense = await db.collections.organizationpackagelicenses
		.findOne(organizationPackageLicenseId).exec()
	await organizationPackageLicense?.remove()
}

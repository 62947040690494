import { outdent } from 'outdent'
import { query } from '../../database/GraphQl'
import { Set } from './setsDb'

interface Response {
	sets: Set[]
}

export async function loadSets(): Promise<Set[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadSets {
				sets {
					id
					packageid
					name
				}
			}`,
	})
	return result.sets
}

export async function loadSetById(id: string): Promise<Set | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadSet($ids: [uuid!]!) {
				sets(where: {id: {_in: $ids}}) {
					id
					packageid
					name
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.sets[0] ?? null
}

export async function createSet(
	packageid: string,
	name: string,
): Promise<string> {
	const response = await query<{ insert_set: { id: string } }>({
		query: outdent`
			mutation CreateSet(
				$packageid: uuid!,
				$name: String!
			) {
				insert_set(object: {
					packageid: $packageid,
					name: $name
				}) {
					id
				}
			}
			`,
		variables: {
			name,
			packageid,
		},
	})
	return response.insert_set.id
}

export async function updateSet(
	id: string,
	name: string,
): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation UpdateSet($id: uuid!, $name: String!) {
				update_set(pk_columns: {id: $id}, _set: {name: $name}) {
					name
				}
			}`,
		variables: {
			id,
			name,
		},
	})
}

export async function deleteSet(id: string): Promise<void> {
	await query<Response>({
		query: outdent`
			mutation DeleteSet($setid: uuid!) {
				delete_set(id: $setid) {
					id
				}
			}
			`,
		variables: {
			setid: id,
		},
	})
}

import { RxCollection, RxJsonSchema } from 'rxdb'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { leftJoinAggObservable } from '@hirn.app/shared'
import {
 DbIdChanges, database, databaseCurrent,
} from '../../database/Database'
import { RxCollectionCreator } from '../../database/RxCollectionCreator'
import { logError } from '../../monitoring/Monitoring'
import { loadTeamById, loadTeams } from './teamsGql'

import {
 TeamMemberWithRoleNames, handleDeleteTeamMembersByTeam,
} from '../teammember/teamMembersDb'
import { User } from '../user/usersDb'
import { toJson } from '../../utils/RxDbUtils'
import { teamRoleFilter } from '../teammemberrole/teamMemberRolesDb'

export const teamManagerFilter = teamRoleFilter([
	'member:manage',
])

type TeamsChangeType = DbIdChanges<'teams'>

export type ChangeTeams = TeamsChangeType['All']

export interface Team {
	id: string
	organizationid: string
	name: string
}

export interface TeamWithMembers extends Team {
	teammembers: TeamMemberWithRoleNames[]
}

export interface TeamWithOrganizationMembers {
	id: string
	userid: string
	organizationid: string
	roles?: string[]
	organizationmembers?: User[]
}

interface StaticMethods {
	getTeams(this: TeamCollection): Observable<Team[]>
	getById(id: string): Promise<Team | null>
	getWithTeamMembers(): Observable<TeamWithMembers[]>
}

export type TeamCollection = RxCollection<Team, unknown, StaticMethods>

const statics: StaticMethods = {
	getTeams(this: TeamCollection): Observable<Team[]> {
		return toJson(this
			.find().sort({ name: 'asc' }).$)
	},
	async getById(this: TeamCollection, id: string): Promise<Team | null> {
		const team = await this.findOne(id).exec()
		return team?.toJSON() ?? null
	},
	getWithTeamMembers(
		this: TeamCollection,
	): Observable<TeamWithMembers[]> {
		const teamsObserver = this.getTeams()

		const teamMembersObserver = database()
			.pipe(switchMap(db => db.collections.teammembers.getWithRolesName()))
		const teams = leftJoinAggObservable(
			'teammembers',
			teamsObserver, 'id',
			teamMembersObserver, 'teamid',
		)
		return teams
	},
}

export const teamsSchema: RxJsonSchema<Team> = {
	version: 0,
	primaryKey: 'id',
	type: 'object',
	required: [
		'name',
		'organizationid',
	],
	properties: {
		id: {
			type: 'string',
			maxLength: 36,
		},
		name: {
			type: 'string',
			maxLength: 200,
		},
		organizationid: {
			type: 'string',
			maxLength: 36,
		},
	},
	indexes: ['name', 'organizationid'],
}

export const teamCollection: Record<'teams', RxCollectionCreator<StaticMethods>> = {
	teams: {
		schema: teamsSchema,
		statics,
	},
}

export async function pullTeams(): Promise<void> {
	const teams = await loadTeams()
	const db = await databaseCurrent()
	await db.teams.bulkInsert(teams)
}

export async function pullTeam(id: string): Promise<void> {
	const team = await loadTeamById(id)
	if (team) {
		const db = await databaseCurrent()
		await db.teams.atomicUpsert(team)
	} else {
		await logError(`pullTeam(${id}) returns ${team}`)
	}
}

export async function handleInsertTeam(
	id: string,
): Promise<boolean> {
	const db = await databaseCurrent()
	const teamUnknown = await db.collections.teams.getById(id) === null
	if (teamUnknown) {
		await pullTeam(id)
	}
	return teamUnknown
}

export async function handleInsertTeamsChange(
	change: TeamsChangeType['Insert'],
): Promise<void> {
	await handleInsertTeam(change.payload.id)
}

export async function handleUpdateTeamsChange(
	change: TeamsChangeType['Update'],
): Promise<void> {
	await pullTeam(change.payload.id)
}

export async function handleDeleteTeamByUserIdAndTeamId(
	userid: string,
	teamid: string,
): Promise<void> {
	const db = await databaseCurrent()
	const teammember = await db.collections.teammembers.findOne({
		selector: {
			userid,
			teamid,
		},
	}).exec()
	await teammember?.remove()
}

export async function handleDeleteTeamsChange(
	change: TeamsChangeType['Delete'],
): Promise<void> {
	const teamid = change.payload.id
	const db = await databaseCurrent()
	await handleDeleteTeamMembersByTeam(teamid)
	const team = await db.collections.teams
		.findOne(teamid).exec()
	await team?.remove()
}

import { Transition } from '@headlessui/react'
import React, {
	useCallback,
	useRef,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../components/Button'
import {
	AccountIcon,
	BillingIcon,
	ImprintIcon,
	LernSetIcon,
	LerningIcon,
	LogoutIcon,
	MenuClosedIcon,
	MenuLinkClosedIcon,
	MenuOpenedIcon,
	OfflineIcon,
	TeamIcon,
	TermsIcon,
} from '../icon'
import { lineState$ } from '../Offline'
import { useObserve } from '../utils/useObserve'
import { AppRoutes } from './AppRoutes'

interface MenuSubItemProps {
	href: string
	icon: React.ReactElement
	description?: string
}

export function MenuSubItem({
	icon, description, href, children,
}: React.PropsWithChildren<MenuSubItemProps>): React.ReactElement {
	return (
		<Link className="flex flex-row items-start bg-transparent p-2 focus:text-green hover:text-green" to={href}>
			<div className="bg-green bg-opacity-75 text-white rounded-lg p-3 my-auto">
				<span className="flex w-6 h-6">{icon}</span>
			</div>
			<div className="ml-2 my-auto">
				<p className="font-semibold">{children}</p>
				{description && <p className="text-sm">{description}</p>}
			</div>
		</Link>
	)
}

export function MenuWithSubitem({
	text, children,
}: React.PropsWithChildren<{ text: string }>): React.ReactElement {
	const [open, setOpen] = useState(false)
	const [hoverEnabled, setHoverEnabled] = useState(true)
	const [insideMenuItem, setInsideMenuItem] = useState(false)
	const toggleMenu = useCallback(() => {
		// we want catch clicks only on menu item, not bubbled
		if (insideMenuItem) {
			// user clicked on menu item instead of just hovering,
			// therefore we disable hovering
			setHoverEnabled(false)
		}
		setOpen(!open)
	}, [insideMenuItem, open, setOpen, setHoverEnabled])

	const openMenuOnHover = useCallback((event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		if (hoverEnabled) {
			setOpen(true)
		}
	}, [hoverEnabled, setOpen])
	const closeMenuOnHoverOver = useCallback((event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		if (hoverEnabled) {
			setOpen(false)
		}
	}, [hoverEnabled, setOpen])

	const onTouchStart = useCallback((event: React.TouchEvent<HTMLElement>) => {
		// we are on mobile, remove hover
		setHoverEnabled(false)
		event.preventDefault()
	}, [setHoverEnabled])

	const onMouseEnterMainButton = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setInsideMenuItem(true)
		event.preventDefault()
	}, [setInsideMenuItem])
	const onMouseLeaveMainButton = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setInsideMenuItem(false)
		event.preventDefault()
	}, [setInsideMenuItem])
	return (
		<div onClick={toggleMenu} className="relative" onMouseEnter={openMenuOnHover} onTouchStart={hoverEnabled ? onTouchStart : undefined} onMouseLeave={closeMenuOnHoverOver}>
			<button onClick={undefined} onMouseEnter={onMouseEnterMainButton} onMouseLeave={onMouseLeaveMainButton} className="flex flex-row text-gray-700 items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left md:w-auto md:inline md:mt-0 md:ml-4 hover:text-green focus:text-green focus:outline-none focus:shadow-outline whitespace-nowrapn">
				<span className={`hover-${hoverEnabled ? 'enabled' : 'disabled'}`}>{text}</span>
				<MenuLinkClosedIcon className={`${open ? 'rotate-180' : 'rotate-0'} inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 md:-mt-1`} />
			</button>
			{
				<Transition show={open}
					enter="transition ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
					className="absolute right-0 w-full md:max-w-screen-sm md:w-screen pt-2 origin-top-left z-10"
				>
					<div className="px-2 pt-2 pb-4 bg-white rounded-md shadow-lg">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
							{children}
						</div>
					</div>
				</Transition>
			}
		</div>
	)
}

export function Menu(): React.ReactElement {
	const [menuOpen, setMenuOpen] = useState(false)
	const lineState = useObserve(lineState$, 'offline')
	const toggle = useCallback((event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setMenuOpen(!menuOpen)
	}, [menuOpen, setMenuOpen])

	const menuRef = useRef<HTMLDivElement>(null)

	return (
		<div ref={menuRef} className="antialiased">
			<nav ref={menuRef} className="antialiased w-full -top-0 text-gray-700 bg-white z-50 p-2">
				<div className="flex flex-col mx-auto md:items-center md:justify-between md:flex-row">
					<Link to="/" className="hidden md:block text-lg font-semibold tracking-widest text-gray-900 rounded-lg focus:outline-none focus:shadow-outline"><span className="text-green">hirn!</span>app</Link>
					<div className="flex flex-row items-center justify-between md:hidden">
						<span className="ml-auto" />
						{
							lineState === 'offline' && <OfflineIcon className="w-8 h-4" />
						}
						<Link to={AppRoutes.studyingSession.list.url()}>
							<LerningIcon className="w-8 h-4 mr-1" />
						</Link>
						<button className="rounded-lg focus:outline-none focus:shadow-outline w-6 h-6" onClick={toggle}>
							{
								menuOpen
									? <MenuOpenedIcon className="w-6 h-6" />
									: <MenuClosedIcon className="w-6 h-6" />
							}
						</button>
					</div>
					<div className={`${menuOpen ? 'flex shadow-lg' : 'hidden'} flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row items-center`}>
						{
							lineState === 'offline' && <OfflineIcon className="ml-auto w-12 h-4" />
						}
						<Button
							color="secondary"
							startIcon={<LerningIcon />}
							to={AppRoutes.studyingSession.list.url()}>
							Lernen
						</Button>
						<MenuWithSubitem text="Verwaltung">
							<MenuSubItem
								href={AppRoutes.package.list.url()}
								icon={<LernSetIcon />}
								description="Verwalte und erstelle deine Wissenspakete"
							>
								Pakete
							</MenuSubItem>
							<MenuSubItem
								href={AppRoutes.team.list.url()}
								icon={<TeamIcon />}
								description="Lerngruppen verwalten"
							>
								Team
							</MenuSubItem>
							<MenuSubItem
								href={AppRoutes.organization.list.url()}
								description="Konto und Organisationen verwalten"
								icon={<AccountIcon />}
							>
								Konto
							</MenuSubItem>
							<MenuSubItem
								href={AppRoutes.billing.list.url()}
								icon={<BillingIcon />}
								description="Rechnungen einsehen"
							>
								Rechnungen
							</MenuSubItem>
							<MenuSubItem
								href={AppRoutes.common.logout.url()}
								icon={<LogoutIcon />}
								description="Hier kannst du dich abmelden"
							>
								Abmelden
							</MenuSubItem>
						</MenuWithSubitem>
						<MenuWithSubitem text="Über uns">
							<MenuSubItem
								href={AppRoutes.common.terms.url()}
								icon={<TermsIcon />}
							>
								Allgemeine Geschäftsbedingungen
							</MenuSubItem>
							<MenuSubItem
								href={AppRoutes.common.imprint.url()}
								icon={<ImprintIcon />}
							>
								Impressum
							</MenuSubItem>
						</MenuWithSubitem>
					</div>
				</div>
			</nav>
		</div>
	)
}

import React from 'react'
import {
	useNavigate,
} from 'react-router-dom'
import {
	AppRoutes,
} from '../AppRoutes'
import {
	useParamsDefined,
} from '../../utils/useParamsDefined'
import { loadInvitationDetails } from './memberInvitationsGql'
import { useEffectAsync } from '../../utils/useEffectAsync'

export function IncomingInvitationDetails(): React.ReactElement {
	const { invitationid } = useParamsDefined<'invitationid'>()
	const navigate = useNavigate()

	useEffectAsync(`IncomingInvitationDetails.useEffectAsync(${invitationid})`, async () => {
		try {
			await loadInvitationDetails(invitationid)
			navigate(AppRoutes.incominginvitation.list.url())
		} catch (error) {
			navigate(AppRoutes.organization.list.url())
		}
	}, [invitationid])

	return <></>
}

import React, { ButtonHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps {
	type?: ButtonHTMLAttributes<never>['type']
	startIcon?: React.ReactElement
	color: 'primary' | 'secondary'
	onClick?: () => void
	to?: string
	addClassName?: string
	dataTestId?: string
	disabled?: boolean
}

function getButtonStyle(color: ButtonProps['color']) {
	const appearance = color === 'primary' ? 'bg-green hover:bg-green' : 'text-green border-green hover:bg-green-light hover:bg-opacity-25'
	return `${appearance} px-4 py-2 inline-flex bg-opacity-75 focus:outline-none text-sm font-semibold rounded-md min-w-32 border`
}

export function Button({
	type = 'button',
	children,
	onClick,
	startIcon,
	color,
	to,
	addClassName = '',
	dataTestId,
	disabled,
}: React.PropsWithChildren<ButtonProps>): React.ReactElement {
	const className = `${getButtonStyle(color)} ${addClassName}`
	if (to) {
		return (
			<Link
				to={to}
				className={className}
				{...(dataTestId ? { 'data-test-id': dataTestId } : {})}
			>
				{startIcon && <span className="fill-current w-4 h-4 mr-2">{startIcon}</span>}
				{children}
			</Link>
		)
	}
	return (
		<button
			type={type}
			onClick={onClick}
			disabled={disabled}
			className={className}
			{...(dataTestId ? { 'data-test-id': dataTestId } : {})}
		>
			{startIcon && <span className="fill-current w-4 h-4 mr-2">{startIcon}</span>}
			{children}
		</button>
	)
}

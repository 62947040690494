import {
	RxCollection,
	RxJsonSchema,
} from 'rxdb'
import { Observable } from 'rxjs'
import {
	DbIdChanges,
	databaseCurrent,
} from '../../database/Database'
import {
	RxCollectionCreator,
} from '../../database/RxCollectionCreator'
import {
	logError,
} from '../../monitoring/Monitoring'
import {
	OfflineCapable,
	StaticOffline,
	createOfflineCapable,
	getActiveState,
	offlineCapableSchema,
} from '../../Offline'
import {
	toJsonObject,
} from '../../utils/RxDbUtils'
import {
	getLeitnerSchedule,
} from '../studying/studying'
import {
	deleteStudyingSessionSetByStudyingSessionId,
} from '../studyingsessionset/studyingsessionsetsDb'
import {
	deleteStudyingSessionGql,
	insertStudyingSessionGql,
	loadStudyingSessionById,
	loadStudyingSessions,
	updateStudyingSession,
} from './studyingsessionsGql'

type StudyingSessionsChangeType = DbIdChanges<'studyingsessions'>

export type ChangeStudyingSessions = StudyingSessionsChangeType['All']

export interface LeitnerData {
	sessionnumber: number
	sessionlevel: number
	currentquestionid: string | null
}

export interface HirnData {
	maxdifficulty: number
	questionspackage: string[]
}

export type StudyingSessionParams = {
	setids: string[]
	system: 'hirn'
	maxdifficulty: number
} | {
	setids: string[]
	system: 'leitner'
}

function getStudyingSessionData(
	studyingSessionParams: StudyingSessionParams,
) {
	if (studyingSessionParams.system === 'leitner') {
		return {
			sessionnumber: 0,
			sessionlevel: getLeitnerSchedule(0)[0],
			currentquestionid: null,
		}
	}
	return {
		maxdifficulty: studyingSessionParams.maxdifficulty,
		questionspackage: [],
	}
}

export interface StudyingSessionLeitner extends OfflineCapable {
	createdat: string
	system: 'leitner' // define as string to handle it explicit?
	data: LeitnerData
}

export interface StudyingSessionHirn extends OfflineCapable {
	createdat: string
	system: 'hirn' // define as string to handle it explicit?
	data: HirnData
}

export function isStudyingSessionLeitner(
	studyingSession?: StudyingSession,
): studyingSession is StudyingSessionLeitner {
	return studyingSession?.system === 'leitner'
}

export function isStudyingSessionHirn(
	studyingSession?: StudyingSession,
): studyingSession is StudyingSessionHirn {
	return studyingSession?.system === 'hirn'
}

export type StudyingSession =
	| StudyingSessionLeitner
	| StudyingSessionHirn

interface StaticMethods extends StaticOffline<StudyingSession> {
	getById(id: string): Promise<StudyingSession | null>
	getActive(): Observable<StudyingSession[]>
}

export type StudyingSessionCollection = RxCollection<
	StudyingSession,
	unknown,
	StaticMethods
>

const statics: StaticMethods = {
	async getById(this: StudyingSessionCollection, id: string): Promise<StudyingSession | null> {
		const studyingSession = await this.findOne(id).exec()
		return studyingSession ? toJsonObject(studyingSession) : null
	},
	getActive(this: StudyingSessionCollection): Observable<StudyingSession[]> {
		return getActiveState(this)
	},
	async handleOffline({ inserted, updated, deleted }) {
		// TODO: probably we can skip insert and update for deleted entities?
		await Promise.allSettled(inserted.map(async studyingSession => {
			const result = await insertStudyingSessionGql(toJsonObject(studyingSession))
			await studyingSession.atomicPatch({
				...result,
				offlinestate: 'insync',
			})
		}))

		await Promise.allSettled(updated.map(async studyingSession => {
			const result = await updateStudyingSession(toJsonObject(studyingSession))
			await studyingSession.atomicPatch({
				...result,
				offlinestate: 'insync',
			})
		}))

		await Promise.allSettled(deleted.map(async studyingSession => {
			await deleteStudyingSessionGql(studyingSession.id)
			await studyingSession.remove()
		}))
	},
}

const studyingSessionsSchema = offlineCapableSchema<RxJsonSchema<StudyingSession>>({
	version: 0,
	type: 'object',
	required: [
		'createdat',
		'system',
		'data',
	],
	properties: {
		createdat: {
			type: 'string',
			format: 'date-time',
		},
		system: {
			type: 'string',
		},
		data: {
			type: 'object',
		},
	},
})

export const studyingSessionCollection: Record<'studyingsessions', RxCollectionCreator<StaticMethods>> = {
	studyingsessions: {
		schema: studyingSessionsSchema,
		statics,
	},
}

export async function pullStudyingSessions(): Promise<void> {
	const studyingSessions = await loadStudyingSessions()
	const db = await databaseCurrent()
	await db.studyingsessions.bulkInsert(studyingSessions)
}

export async function pullStudyingSession(
	id: string,
	eventCreatedAt: string,
): Promise<void> {
	const db = await databaseCurrent()
	const persistentStudyingSession = await db.studyingsessions.getById(id)
	// avoid unnecessary request if object is already up-to-date
	if (persistentStudyingSession
		&& persistentStudyingSession.updatedat >= eventCreatedAt) {
		return
	}
	const studyingSession = await loadStudyingSessionById(id)
	if (studyingSession) {
		await db.studyingsessions.atomicUpsert(studyingSession)
	} else {
		await logError(`pullStudyingSession(${id}) returns ${studyingSession}`)
	}
}

export async function handleInsertStudyingSessionsChange(
	change: StudyingSessionsChangeType['Insert'],
): Promise<void> {
	await pullStudyingSession(
		change.payload.id,
		change.createdat,
	)
}

export async function handleUpdateStudyingSessionsChange(
	change: StudyingSessionsChangeType['Update'],
): Promise<void> {
	await pullStudyingSession(
		change.payload.id,
		change.createdat,
	)
}

export async function handleDeleteStudyingSessionsChange(
	change: StudyingSessionsChangeType['Delete'],
): Promise<void> {
	const studyingSessionid = change.payload.id
	const db = await databaseCurrent()
	const studyingSession = await db.collections.studyingsessions
		.findOne(studyingSessionid).exec()
	await studyingSession?.remove()
	await deleteStudyingSessionSetByStudyingSessionId(studyingSessionid)
}

export async function insertStudyingSession(
	studyingSessionParams: StudyingSessionParams,
): Promise<StudyingSession> {
	const db = await databaseCurrent()
	const now = new Date().toISOString()
	// @ts-expect-error probably, union types aren't exclusive
	return (await db.collections.studyingsessions.insert(createOfflineCapable({
		system: studyingSessionParams.system,
		createdat: now,
		updatedat: now,
		data: getStudyingSessionData(studyingSessionParams),
	}))).toJSON()
}

export async function deleteStudyingSession(id: string): Promise<void> {
	const db = await databaseCurrent()
	const studyingSession = await db.collections.studyingsessions.findOne(id).exec()
	if (studyingSession?.offlinestate !== 'deleted') {
		await studyingSession?.atomicPatch({
			offlinestate: 'deleted',
		})
	}
}

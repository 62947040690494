interface FormatIntervalOptions {
	locale: 'en-US' | 'de-DE'
	precision?: 'minutes' | 'seconds'
}

type DateOrString = Date | string

export function formatInterval(
	from: DateOrString,
	to: DateOrString,
	options: FormatIntervalOptions,
): string {
	try {
		const fromDate = typeof from === 'string' ? new Date(from) : from
		const toDate = typeof to === 'string' ? new Date(to) : to
		const dateFormatter = Intl.DateTimeFormat(options.locale, {
			dateStyle: 'short',
		})
		const timeFormatter = Intl.DateTimeFormat(options.locale, {
			timeStyle: options.precision === 'minutes' ? 'short' : 'medium',
		})
		const fromDateFormatted = dateFormatter.format(fromDate)
		const fromTimeFormatted = timeFormatter.format(fromDate)
		const toDateFormatted = dateFormatter.format(toDate)
		const toTimeFormatted = timeFormatter.format(toDate)
		if (fromDateFormatted === toDateFormatted) {
			// it makes sense only for the same date
			if (fromTimeFormatted === toTimeFormatted) {
				return `${fromDateFormatted}, ${fromTimeFormatted}`
			}
			return `${fromDateFormatted}, ${fromTimeFormatted} - ${toTimeFormatted}`
		}
		return `${fromDateFormatted}, ${fromTimeFormatted} - ${toDateFormatted}, ${toTimeFormatted}`
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(`formatInterval`, typeof from, from, typeof to, to)
		throw e
	}
}

export function wait(ms: number): Promise<void> {
	// eslint-disable-next-line no-promise-executor-return
	return new Promise<void>(resolve => setTimeout(resolve, ms))
}

import {
	outdent,
} from 'outdent'
import {
	query,
} from '../../database/GraphQl'
import {
	Answer,
} from './answersDb'

interface Response {
	answers: Answer[]
}

export async function loadAnswers(): Promise<Answer[]> {
	const result = await query<Response>({
		query: outdent`
			query LoadAnswers {
				answers {
					id
					questionid
					updatedat
					content
					type
				}
			}`,
	})
	return result.answers
}

export async function loadAnswerById(id: string): Promise<Answer | null> {
	const result = await query<Response>({
		query: outdent`
			query LoadAnswer($ids: [uuid!]!) {
				answers(where: {id: {_in: $ids}}) {
					id
					questionid
					updatedat
					content
					type
				}
			}`,
		variables: {
			ids: [id],
		},
	})
	return result.answers[0] ?? null
}

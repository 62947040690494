import {
	DependencyList,
	useState,
} from 'react'
import {
	useEffectAsync,
} from './useEffectAsync'

type EffectCallback<R> = () => Promise<R>

export function usePromiseResult<R>(
	errorTitle: string,
	effect: EffectCallback<R>,
	initialState: R | (() => R),
	deps?: DependencyList,
): R {
	const [state, setState] = useState(initialState)
	useEffectAsync(
		errorTitle,
		() => effect().then(setState),
		deps,
	)
	return state
}

import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Content } from '../../components/Content'
import { Frame } from '../../components/Frame'
import { H1 } from '../../components/H1'
import { Input } from '../../components/inputs/Input'
import { useDb } from '../../database/DatabaseProvider'
import { ValidationErrors, handleFormValidation } from '../../database/validation'
import { DeleteIcon } from '../../icon'
import { logError } from '../../monitoring/Monitoring'
import { useParamsDefined } from '../../utils/useParamsDefined'
import { AppRoutes } from '../AppRoutes'
import { deleteOrganization, updateOrganization } from './organizationsGql'

export function EditOrganization(): React.ReactElement {
	const { id } = useParamsDefined<'id'>()

	const navigate = useNavigate()
	const db = useDb()

	const formConfig = {
		defaultValues: {
			name: '',
		},
	}

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm(formConfig)

	useEffect(() => {
		async function getOrganization(): Promise<void> {
			const org = await db.collections.organizations.findOne({ selector: { id } }).exec()
			if (org) {
				reset(org.toJSON())
			} else {
				await logError(`organization ${id} was not found`)
			}
		}
		getOrganization().catch(logError)
	}, [id, reset, db.collections.organizations])

	async function onSubmit(data: typeof formConfig['defaultValues']): Promise<void> {
		const [success] = await handleFormValidation(
			() => updateOrganization(id, data.name),
			Object.keys(formConfig.defaultValues),
			setError,
		)
		if (success) {
			navigate(AppRoutes.organization.list.url())
		}
	}

	const deleteOrg = useCallback(async () => {
		try {
			await deleteOrganization(id)
			navigate(AppRoutes.organization.list.url(), { replace: true })
		} catch (e) {
			if (e instanceof ValidationErrors) {
				const handled = e.errors.find(error => {
					if (error.code === 'foreignKeyConstraint') {
						setError('name', {
							message: error.translatedMessage,
						})
						return true
					}
					return false
				})
				if (handled) {
					return
				}
			}
			throw e
		}
	}, [id, navigate, setError])

	return (
		<Content variant="narrow">
			<Frame>
				<H1>Organisation bearbeiten</H1>
				<form onSubmit={handleSubmit(onSubmit)}>

					<Input
						{...register('name')}
						error={errors.name?.message}
						label="Name"
					/>

					<Button
						onClick={deleteOrg}
						color="secondary"
						addClassName="float-left"
						dataTestId="delete-organization"
					>
						<DeleteIcon className="w-5 h-5" />
					</Button>
					<div className="w-full text-right">
						<Button
							to={AppRoutes.organization.list.url()}
							color="secondary"
						>
							Abbrechen
						</Button>

						<Button
							type="submit"
							color="primary"
							addClassName="ml-2"
						>
							Speichern
						</Button>
					</div>
				</form>
			</Frame>
		</Content>
	)
}

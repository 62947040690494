import React from 'react'

type ContentProps = React.PropsWithChildren<{
	addClassName?: string
	variant: 'full' | 'wide' | 'narrow'
}>

const variants = {
	full: '',
	wide: 'mx-1 sm:mx-auto sm:w-11/12',
	narrow: 'mx-auto max-w-md',
}

export function Content({
	addClassName = '',
	variant,
	children,
}: ContentProps): React.ReactElement {
	const className = [
		addClassName,
		variants[variant],
	].filter(Boolean).join(' ')
	return (
		<div className={className}>
			{children}
		</div>
	)
}
